export const paths = {
	HOME: '/',
	DASHBOARD: '/',
	DISTRIBUTORS: '/distributors',
	PRODUCTS: '/products',
	INVENTORY: '/inventory',
	INVENTORY_PRODUCTS: '/inventory-products',
	REPORTS: '/reports',
	USERS: '/users',
	PERMISSIONS: '/permissions',
	COMPANIES: '/companies',
	SALES_ORDERS: '/sales-orders',
	DELIVERY_PLANS: '/delivery-plans',
	FLEET: '/fleet',
	VENDORS: '/vendors',
	PURCHASE_ORDERS: '/purchase-orders',
	LOCATIONS: '/locations',
	SETTINGS: '/settings',
	WAREHOUSES: '/warehouses',
	GENERATE_LABELS: '/generate-labels'
}

export const roles = {
	OWNER: 'OWNER',
	USER: 'USER',
	DRIVER: 'DRIVER',
	HELPER: 'HELPER',
	DISTRIBUTOR: 'DISTRIBUTOR',
	SALES_MANAGER: 'SALES_MANAGER'
}

export const claimsRoles = {
	ADMIN: 'admin',
	OWNER: 'owner',
	USER: 'user',
	DRIVER: 'driver',
	HELPER: 'helper',
	SALES_MANAGER: 'sales_manager'
}

export const locationTypes = {
	WAREHOUSE: 'WAREHOUSE',
	FACTORY: 'FACTORY',
	GENERAL: 'GENERAL',
	BILLING: 'BILLING'
}

export const intervalTypes = {
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
	CUSTOM: 'custom'
}
