import { HYDRATE } from 'next-redux-wrapper'
import {
	PURCHASE_ORDER_REQUESTED_STATUS,
	PURCHASE_ORDER_APPROVED_STATUS,
	PURCHASE_ORDER_RECEIVED_STATUS
} from '../../pages/purchase-orders'
import {
	ADD_PURCHASE_ORDERS_SUCCESS,
	FETCH_PURCHASE_ORDERS_SUCCESS,
	FETCH_PURCHASE_ORDER_DETAILS_SUCCESS,
	UPDATE_PURCHASE_ORDER_SUCCESS
} from './action-types'

const initialState = {
	purchaseOrderDetails: {},
	requestedPurchaseOrders: [],
	approvedPurchaseOrders: [],
	receivedPurchaseOrders: [],
	totalCount: 0,
	page: 0
}

const purchaseOrdersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PURCHASE_ORDERS_SUCCESS: {
			switch (payload.status) {
				case PURCHASE_ORDER_REQUESTED_STATUS:
					return {
						...state,
						requestedPurchaseOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case PURCHASE_ORDER_APPROVED_STATUS:
					return {
						...state,
						approvedPurchaseOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case PURCHASE_ORDER_RECEIVED_STATUS:
					return {
						...state,
						receivedPurchaseOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				default:
					return {
						...state,
						requestedPurchaseOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
			}
		}
		case ADD_PURCHASE_ORDERS_SUCCESS: {
			if (payload.status === PURCHASE_ORDER_REQUESTED_STATUS) {
				const requestedPurchaseOrders = [payload, ...state.requestedPurchaseOrders]
				return {
					...state,
					requestedPurchaseOrders
				}
			} else if (payload.status === PURCHASE_ORDER_APPROVED_STATUS) {
				const approvedPurchaseOrders = [payload, ...state.approvedPurchaseOrders]
				return {
					...state,
					approvedPurchaseOrders
				}
			} else {
				return state
			}
		}
		case FETCH_PURCHASE_ORDER_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.purchaseOrderDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_PURCHASE_ORDER_SUCCESS: {
			const newState = { ...state }
			const purchaseOrder = newState.purchaseOrderDetails[payload.id]
			if (purchaseOrder && payload) {
				const newPurchaseOrder = Object.assign(purchaseOrder, payload)
				newState.purchaseOrderDetails[payload.id] = newPurchaseOrder
			}
			return newState
		}
		default:
			return state
	}
}

export default purchaseOrdersReducer
