import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_DISTRIBUTOR_SUCCESS,
	FETCH_DISTRIBUTORS_SUCCESS,
	FETCH_DISTRIBUTOR_DETAILS_SUCCESS,
	FETCH_DISTRIBUTOR_METRICS_SUCCESS,
	UPDATE_DISTRIBUTOR_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	distributors: [],
	distributorDetails: {},
	metrics: {
		new: 0,
		total: 0,
		active: 0
	}
}

const distributorsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_DISTRIBUTORS_SUCCESS: {
			return {
				...state,
				distributors: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_DISTRIBUTOR_SUCCESS: {
			const distributors = [payload, ...state.distributors]
			return {
				...state,
				distributors
			}
		}
		case UPDATE_DISTRIBUTOR_SUCCESS: {
			const newState = { ...state }
			const distributor = newState.distributorDetails[payload.id]
			if (distributor) {
				const updatedDistributor = Object.assign(distributor, payload)
				newState.distributorDetails[payload.id] = updatedDistributor
			}
			return newState
		}
		case FETCH_DISTRIBUTOR_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.distributorDetails[payload.id] = payload.data
			return newState
		}
		case FETCH_DISTRIBUTOR_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		default:
			return state
	}
}

export default distributorsReducer
