import styles from './delivery-plans.module.css'
import Page from '../../components/page'
import 'firebase/firestore'
import { Layout, Collapse, Empty, Button, Skeleton, Row, Tooltip, Popover, Tag, Spin, Dropdown, Menu, Avatar, notification, Checkbox, Modal } from 'antd'
import Map from '../../components/map'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import router, { useRouter } from 'next/router'
import { fetchDeliveryPlans, updateDeliveryPlanLoadState, fetchDeliveryPlanDetails, updateDeliveryPlanRoutes, fetchDeliveryPlanItemDetails, updateVehicleAssignmentStatus } from '../../store/delivery-plans/actions'
import { fetchSalesOrderDetails } from '../../store/sales-orders/actions'
import moment from 'moment'
import Table from '../../components/table'
import Link from 'next/link'
import { convertEnumToString, getDistanceFromLatLonInMeters, getFormattedCurrency, removeURLParameter } from '../../utils'
import { CarOutlined, CloseOutlined, MoreOutlined, UserOutlined, ReloadOutlined, EditTwoTone, LoadingOutlined, SyncOutlined, PlusOutlined } from '@ant-design/icons'
import ColorHash from 'color-hash'
import CreateDeliveryPlanModal from '../../components/create-delivery-plan-modal'
import EditDeliveryPlanModal from '../../components/edit-delivery-plan-modal'
import AssignDriverModal from '../../components/assign-driver-modal'
import AssignHelperModal from '../../components/assign-helper-modal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useSearchParams from '../../hooks/useSearchParams'
import { DeliveryPlans } from '../../services/api/delivery-plans'
import Splitter from '../../components/splitter'
import ExpandButton from '../../components/expand-button'
import { getShortId } from '../../utils/sales-orders'
import { Locations, Vehicles } from '../../services/api/firebase'
import { Locations as LocationsApi } from '../../services/api/locations'
import AddRoutePointsModal from '../../components/add-route-points-modal'
import { usePrevious } from '../../hooks/usePrevious'
import { paths } from '../../utils/constants'
import AssignVehicleModal from '../../components/assign-vehicle-modal'
import { getVehicleStatusLabel, getVehicleTypeLabel } from '../fleet'
import { VehicleMarker } from '../../components/fleet-map-view'
import './delivery-plans.less'
import StartLocationModal from '../../components/start-location-modal'

const { Content, Sider } = Layout
const { Panel } = Collapse

export const DELIVERY_PLAN_PROCESSING_STATUS = 'processing'
export const DELIVERY_PLAN_PENDING_STATUS = 'pending'
export const DELIVERY_PLAN_APPROVED_STATUS = 'approved'
export const DELIVERY_PLAN_EN_ROUTE_STATUS = 'en_route'
export const DELIVERY_PLAN_SCHEDULED_STATUS = 'scheduled'
export const DELIVERY_PLAN_DELIVERED_STATUS = 'delivered'
export const DELIVERY_PLAN_FAILED_STATUS = 'failed'
export const VEHICLE_REQUEST_APPROVED_STATUS = 'APPROVED'
export const VEHICLE_REQUEST_DENIED_STATUS = 'DENIED'
export const VEHICLE_REQUEST_PENDING_STATUS = 'PENDING'

export const VIEW_MODE = {
	LIST: 'list',
	MAP: 'map'
}

export const DELIVERY_PLAN_STATUS_COLORS = {
	[DELIVERY_PLAN_PROCESSING_STATUS]: '#fa8c16',
	[DELIVERY_PLAN_PENDING_STATUS]: '#1890ff',
	[DELIVERY_PLAN_APPROVED_STATUS]: '#52c41a',
	[DELIVERY_PLAN_EN_ROUTE_STATUS]: '#13c2c2',
	[DELIVERY_PLAN_SCHEDULED_STATUS]: '#1890ff',
	[DELIVERY_PLAN_DELIVERED_STATUS]: '#278EA5',
	[DELIVERY_PLAN_FAILED_STATUS]: '#f5222d'
}

const tabs = [
	{
		title: 'Pending Approval',
		status: DELIVERY_PLAN_PENDING_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_PENDING_STATUS}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Approved',
		status: DELIVERY_PLAN_APPROVED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_APPROVED_STATUS}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Scheduled',
		status: DELIVERY_PLAN_SCHEDULED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_SCHEDULED_STATUS}&view=${VIEW_MODE.LIST}`
	},
	{
		title: 'Delivered',
		status: DELIVERY_PLAN_DELIVERED_STATUS,
		path: `/delivery-plans?status=${DELIVERY_PLAN_DELIVERED_STATUS}&view=${VIEW_MODE.LIST}`
	}
]

const deliveryPlanColumns = [
	{
		title: 'Date',
		key: 'deliveryDate',
		render: deliveryPlan => {
			return moment(deliveryPlan.deliveryDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Delivery Plan #',
		key: 'deliveryPlanID',
		render: deliveryPlan => {
			const { loadingDeliveryPlans } = useSelector(state => state.deliveryPlansReducer)
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Link href={`/delivery-plans/${deliveryPlan.id}`}>
						<a className={styles.link}>{`DP-${deliveryPlan.shortId}`}</a>
					</Link>
					{
						loadingDeliveryPlans[deliveryPlan.id] ?
							<Tooltip title='Generating delivery plan...'>
								<Spin
									style={{ marginLeft: 12 }}
									indicator={<LoadingOutlined />}
								/>
							</Tooltip> : null
					}
				</div>
			)
		}
	},
	{
		title: 'Order Count',
		key: 'salesOrderCount',
		render: deliveryPlan => {
			return deliveryPlan._count.salesOrders
		}
	},
	{
		title: 'Status',
		key: 'status',
		render: deliveryPlan => {
			return <span className={styles.status}>{convertEnumToString(deliveryPlan.status)}</span>
		}
	}
]

const getSalesOrderNumber = (salesOrder) => {
	return getShortId(salesOrder)
}

const colorHash = new ColorHash()

// Object used to hold references to unsubscribe functions.
const cleanUp = {}

const DeliveryPlansPage = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		deliveryPlans: allDeliveryPlans,
		pendingDeliveryPlans,
		approvedDeliveryPlans,
		enRouteDeliveryPlans,
		scheduledDeliveryPlans,
		deliveredDeliveryPlans,
		loadingDeliveryPlans,
		deliveryPlanDetails
	} = useSelector(state => state.deliveryPlansReducer)
	const { companyDetails, permissions } = useSelector(state => state.authReducer)
	const { status, view, createDeliveryPlan, createDeliveryPlanDate, expandedId } = router.query
	const [currentStatus, setCurrentStatus] = useState(DELIVERY_PLAN_PENDING_STATUS)
	const [isLoading, setIsLoading] = useState(false)
	const [expandedDeliveryPlanId, setExpandedDeliveryPlanId] = useState(null)
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()
	const [deliveryPlanItemPolylines, setDeliveryPlanItemPolylines] = useState({})
	const [deliveryPlanItemPolylineBounds, setDeliveryPlanItemPolylineBounds] = useState({})
	const [center, setCenter] = useState()
	const [selectedDeliveryPlanItemId, setSelectedDeliveryPlanItemId] = useState()
	const [hoveredPointId, setHoveredPointId] = useState()
	const [isCreateDeliveryPlanModalVisible, setIsCreateDeliveryPlanModalVisible] = useState(false)
	const [isAddRoutePointsModalVisible, setIsAddRoutePointsModalVisible] = useState(false)
	const [isAssignVehicleModalVisible, setIsAssignVehicleModalVisible] = useState(false)
	const [isAssignDriverModalVisible, setIsAssignDriverModalVisible] = useState(false)
	const [isAssignHelperModalVisible, setIsAssignHelperModalVisible] = useState(false)
	const [selectedDeliveryPlanId, setSelectedDeliveryPlanId] = useState(null)
	const [editableDeliveryPlan, setEditableDeliveryPlan] = useState(null)
	const [isUpdatingRoutes, setIsUpdatingRoutes] = useState(false)
	const { searchParams } = useSearchParams()
	const [isSplitViewEnabled, setIsSplitViewEnabled] = useState(false)
	const [salesOrderDialogs, setSalesOrderDialogs] = useState({})
	const [salesOrderDialogZIndex, setSalesOrderDialogZIndex] = useState(0)
	const [lastClickedSalesOrderDialog, setLastClickedSalesOrderDialog] = useState()
	const [routeDialog, setRouteDialog] = useState()
	const [expandableDeliveryPlan, setExpandableDeliveryPlan] = useState(null)
	const [addedSalesOrders, setAddedSalesOrders] = useState([])
	const [loadingDeliveryPlanId, setLoadingDeliveryPlanId] = useState(null)
	const [hoveredRouteId, setHoveredRouteId] = useState(null)
	const [userLocations, setUserLocations] = useState({})
	const [userLocationsPolylines, setUserLocationsPolylines] = useState({})
	const [deliveryPlanDate, setDeliveryPlanDate] = useState(null)
	const [showUnassigned, setShowUnassigned] = useState(false)
	const [assignedVehicleIds, setAssignedVehicleIds] = useState([])
	const [vehiclesMap, setVehiclesMap] = useState({})
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [isAssignedVehiclesChecked, setIsAssignedVehiclesChecked] = useState(true)
	const [isUnassignedVehiclesChecked, setIsUnassignedVehiclesChecked] = useState(true)
	const [clickedMarkers, setClickedMarkers] = useState({})
	const [isPickUpLocationModalVisible, setIsPickUpLocationModalVisible] = useState(false)
	const [disableVehicleStatusActionButtons, setDisableVehicleStatusActionButtons] = useState([])
	const expandedViewRef = useRef()
	const isListMode = view === VIEW_MODE.LIST

	useEffect(() => {
		let unsubscribeVehicles
		if (companyDetails) {
			if (companyDetails.companyId) {
				async () => {
					unsubscribeVehicles = Vehicles.listenToVehicles(companyDetails.companyId,
						(createdVehicle) => {
							setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [createdVehicle.id]: createdVehicle }))
						},
						(updatedVehicle) => {
							setVehiclesMap(previousVehiclesMap => ({ ...previousVehiclesMap, [updatedVehicle.id]: updatedVehicle }))
						}
					)
				}
			}
		}
		return () => {
			if (unsubscribeVehicles) {
				unsubscribeVehicles()
			}
		}
	}, [companyDetails])

	useEffect(() => {
		if (expandedDeliveryPlanId) {
			const deliveryPlan = allDeliveryPlans.find(dp => dp.id === expandedDeliveryPlanId)
			if (deliveryPlan) {
				const vehicleIds = []
				deliveryPlan.deliveryPlanItems?.forEach((item) => {
					if (item.vehicleId) {
						vehicleIds.push(item.vehicleId)
					}
				})
				setAssignedVehicleIds(vehicleIds)
				setIsAssignedVehiclesChecked(false)
				setIsUnassignedVehiclesChecked(false)
			}
		} else {
			setAssignedVehicleIds([])
			setIsAssignedVehiclesChecked(true)
			setIsUnassignedVehiclesChecked(true)
		}
	}, [expandedDeliveryPlanId])

	useEffect(() => {
		return () => {
			Object.keys(cleanUp).forEach(cleanUpFunctionName => {
				cleanUp[cleanUpFunctionName]()
			})
		}
	}, [])

	useEffect(() => {
		if (!permissions.viewDeliveryPlans) {
			return
		}
		let deliveryPlanStatus
		switch (status) {
			case DELIVERY_PLAN_PENDING_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_PENDING_STATUS
				break
			case DELIVERY_PLAN_APPROVED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_APPROVED_STATUS
				break
			case DELIVERY_PLAN_EN_ROUTE_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_EN_ROUTE_STATUS
				break
			case DELIVERY_PLAN_SCHEDULED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_SCHEDULED_STATUS
				break
			case DELIVERY_PLAN_DELIVERED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_DELIVERED_STATUS
				break
			case DELIVERY_PLAN_FAILED_STATUS:
				deliveryPlanStatus = DELIVERY_PLAN_FAILED_STATUS
				break
			default:
				deliveryPlanStatus = undefined
				break
		}
		setCurrentStatus(deliveryPlanStatus)
		getDeliveryPlans(deliveryPlanStatus)
	}, [status, searchParams])

	useEffect(() => {
		setIsCreateDeliveryPlanModalVisible(createDeliveryPlan === 'true')
	}, [createDeliveryPlan])

	useEffect(() => {
		if (createDeliveryPlanDate) {
			const dateParts = createDeliveryPlanDate.split('-')
			const date = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2])
			setDeliveryPlanDate(date)
			setShowUnassigned(true)
		}
	}, [createDeliveryPlanDate])

	useEffect(() => {
		if (expandableDeliveryPlan) {
			dispatch(fetchDeliveryPlanDetails(expandableDeliveryPlan.id))
		}
	}, [expandableDeliveryPlan])

	useEffect(() => {
		const userIds = Object.keys(userLocationsPolylines)
		const newUserLocations = {}
		for (const userId of userIds) {
			const polyline = userLocationsPolylines[userId]
			if (polyline) {
				const path = polyline.getPath()
				if (path.getLength() > 0) {
					newUserLocations[userId] = path.getAt(path.getLength() - 1)
				}
			}
		}
		setUserLocations(newUserLocations)
	}, [userLocationsPolylines])

	const previousLoadingDeliveryPlan = usePrevious(loadingDeliveryPlans[expandedDeliveryPlanId])
	useEffect(() => {
		/**
		 * In the edge case where a delivery plan is expanded and the delivery plan is updated,
		 * we need to re-draw the routes.
		 */
		if (previousLoadingDeliveryPlan === true && loadingDeliveryPlans[expandedDeliveryPlanId] === false) {
			drawRoutes(expandedDeliveryPlanId)
		}
	}, [loadingDeliveryPlans[expandedDeliveryPlanId]])

	const previousLoadingDeliveryPlanId = usePrevious(loadingDeliveryPlanId)
	useEffect(() => {
		if (loadingDeliveryPlanId && previousLoadingDeliveryPlanId !== loadingDeliveryPlanId) {
			getDeliveryPlanDetails(loadingDeliveryPlanId)
			setLoadingDeliveryPlanId(null)
		}
	}, [loadingDeliveryPlanId])

	useEffect(() => {
		expandDeliveryPlanItem(expandedId)
	}, [expandedId])

	const previousMaps = usePrevious(maps)
	useEffect(() => {
		if (!previousMaps && maps) {
			if (expandedId) {
				expandDeliveryPlanItem(expandedId)
			}
		}
	}, [maps])

	const getDeliveryPlanDetails = async (deliveryPlanId) => {
		await dispatch(fetchDeliveryPlanDetails(deliveryPlanId))
	}

	const getDeliveryPlans = async (deliveryPlanStatus) => {
		setIsLoading(true)
		await dispatch(fetchDeliveryPlans(deliveryPlanStatus, 0, searchParams))
		setIsLoading(false)
	}

	const filterVehicle = (vehicle) => {
		if (!vehicle.tracker || !vehicle.tracker.position) {
			return false
		}
		if (isAssignedVehiclesChecked && isUnassignedVehiclesChecked) {
			return true
		}
		if (isAssignedVehiclesChecked) {
			return !!vehicle.currentTripId
		}
		if (isUnassignedVehiclesChecked) {
			return !vehicle.currentTripId
		}
		if (assignedVehicleIds.includes(vehicle.id)) {
			return true
		}
		return false
	}
	const getSalesOrders = (deliveryPlanId) => {
		const deliveryPlan = deliveryPlanDetails[deliveryPlanId]
		const salesOrders = {}
		if (deliveryPlan) {
			const deliveryPlanSalesOrders = [...deliveryPlan.salesOrders, ...addedSalesOrders]
			deliveryPlanSalesOrders.forEach(salesOrder => {
				salesOrders[salesOrder.id] = salesOrder
			})
		}
		return salesOrders
	}

	const setListView = () => {
		router.push(tabs[1].path)
	}

	const setMapView = () => {
		router.push('/delivery-plans')
	}

	const onMapsLoaded = (map, maps) => {
		setMap(map)
		setMaps(maps)
	}

	const showCreateDeliveryPlanModal = (visible) => {
		setIsCreateDeliveryPlanModalVisible(visible)
		let route = `/delivery-plans?createDeliveryPlan=${visible}`
		if (isListMode) {
			if (currentStatus) {
				route = `${route}&view=list&status=${currentStatus}`
			} else {
				route = `${route}&view=list`
			}
		}
		router.push(route)
	}

	const onExpandDeliveryItem = (keys) => {
		const expandedKeys = keys.filter(key => key !== expandedDeliveryPlanId)
		const path = router.asPath
		if (expandedKeys.length > 0) {
			// Add key
			if (path.includes('expandedId=')) {
				const updatedPath = removeURLParameter(path, 'expandedId')
				if (updatedPath === paths.DELIVERY_PLANS) {
					router.push(`${paths.DELIVERY_PLANS}?expandedId=${expandedKeys[0]}`, undefined, { shallow: true })
				} else {
					router.push(`${updatedPath}&expandedId=${expandedKeys[0]}`, undefined, { shallow: true })
				}
			} else {
				if (path === paths.DELIVERY_PLANS) {
					router.push(`${paths.DELIVERY_PLANS}?expandedId=${expandedKeys[0]}`, undefined, { shallow: true })
				} else {
					router.push(`${path}&expandedId=${expandedKeys[0]}`, undefined, { shallow: true })
				}
			}
		} else {
			// Remove key
			const updatedPath = removeURLParameter(path, 'expandedId')
			router.push(updatedPath, undefined, { shallow: true })
		}
	}

	const expandDeliveryPlanItem = (id) => {
		drawRoutes(id)
		setExpandedDeliveryPlanId(id)
		setLoadingDeliveryPlanId(id)
		setUserLocations({})
		setSalesOrderDialogs({})
		setRouteDialog(null)
		if (editableDeliveryPlan) {
			setEditableDeliveryPlan(null)
		}
		if (id) {
			setTimeout(() => {
				if (expandedViewRef && expandedViewRef.current) {
					expandedViewRef.current.scrollIntoView({ block: 'center' })
				}
			}, 250)
		}
	}

	const drawRoutes = (expandedId, deliveryPlan) => {
		if (!maps) {
			return
		}
		const polylines = Object.values(deliveryPlanItemPolylines)
		polylines.forEach(polyline => {
			polyline.setMap(null)
		})
		const userPolylines = Object.values(userLocationsPolylines)
		userPolylines.forEach(polyline => {
			polyline.setMap(null)
		})
		const bounds = new maps.LatLngBounds()
		const newDeliveryPlanPolylines = {}
		const newDeliveryPlanPolylineBounds = {}
		const deliveryPlans = deliveryPlan ? [deliveryPlan] : allDeliveryPlans
		const driverIds = []
		deliveryPlans
			.filter(deliveryPlan => expandedId === deliveryPlan.id)
			.forEach(deliveryPlan => {
				deliveryPlan.deliveryPlanItems?.forEach(async deliveryPlanItem => {
					const path = []
					const polylineBounds = new maps.LatLngBounds()
					deliveryPlanItem.route.map(point => {
						const latLng = {
							lat: point.lat,
							lng: point.lon
						}
						path.push(latLng)
						bounds.extend(latLng)
						polylineBounds.extend(latLng)
					})
					const polylineOptions = {
						geodesic: true,
						strokeOpacity: 1.0,
						strokeWeight: 2
					}
					if (deliveryPlanItem.directions && deliveryPlanItem.directions.routes && deliveryPlanItem.directions.routes.length > 0) {
						const routes = deliveryPlanItem.directions.routes[0]
						polylineOptions.path = maps.geometry.encoding.decodePath(routes.overview_polyline.points)
					} else {
						polylineOptions.path = path
					}
					if (deliveryPlanItem.driver) {
						polylineOptions.strokeColor = colorHash.hex(deliveryPlanItem.driver.id)
					} else {
						polylineOptions.strokeColor = '#4B5766'
					}
					const polyline = new maps.Polyline(polylineOptions)
					maps.event.addListener(polyline, 'mouseover', () => {
						polyline.setOptions({ strokeWeight: 5 })
					})
					maps.event.addListener(polyline, 'mouseout', () => {
						polyline.setOptions({ strokeWeight: 3 })
					})
					maps.event.addListener(polyline, 'click', () => {
						setSelectedDeliveryPlanItemId(deliveryPlanItem.id)
					})
					polyline.setMap(map)
					newDeliveryPlanPolylines[deliveryPlanItem.id] = polyline
					newDeliveryPlanPolylineBounds[deliveryPlanItem.id] = polylineBounds

					if (deliveryPlanItem.driverId) {
						driverIds.push(deliveryPlanItem.driverId)
					}
				})
			})
		listenToUserLocation(driverIds)
		if (Object.values(newDeliveryPlanPolylines).length > 0) {
			map.fitBounds(bounds)
		}
		setDeliveryPlanItemPolylines(newDeliveryPlanPolylines)
		setDeliveryPlanItemPolylineBounds(newDeliveryPlanPolylineBounds)
	}

	const listenToUserLocation = async userIds => {
		const newUserLocationsPolylines = {}
		for (const userId of userIds) {
			const locations = await Locations.fetchUserLocationsOnce(userId)
			const polylineOptions = {
				geodesic: true,
				strokeOpacity: 0,
				strokeWeight: 2,
				strokeColor: colorHash.hex(userId),
				icons: [{
					icon: {
						path: 'M 0,-1 0,1',
						strokeOpacity: 1,
						scale: 2
					},
					offset: '0',
					repeat: '10px'
				}],
				path: locations.map(location => new maps.LatLng(location.latitude, location.longitude))
			}
			const polyline = new maps.Polyline(polylineOptions)
			polyline.setMap(map)
			newUserLocationsPolylines[userId] = polyline
			if (cleanUp[`${userId}-location`]) {
				cleanUp[`${userId}-location`]()
			}
			cleanUp[`${userId}-location`] = Locations.listenToUserLocation(userId, data => {
				const path = polyline.getPath()
				path.push(new maps.LatLng(data.latitude, data.longitude))
				polyline.setPath(path)
				newUserLocationsPolylines[userId] = polyline
				setUserLocationsPolylines(newUserLocationsPolylines)
			})
		}
		setUserLocationsPolylines(newUserLocationsPolylines)
	}

	const onDriverAssigned = () => {
		drawRoutes(expandedDeliveryPlanId)
	}

	const onDeliveryPlanItemHovered = (deliveryPlanItemId, hovered) => {
		const polyline = deliveryPlanItemPolylines[deliveryPlanItemId]
		if (polyline) {
			if (hovered) {
				polyline.setOptions({ strokeWeight: 5 })
				const polylineBounds = deliveryPlanItemPolylineBounds[deliveryPlanItemId]
				if (polylineBounds) {
					map.fitBounds(polylineBounds)
				}
			} else {
				polyline.setOptions({ strokeWeight: 3 })
			}
		}
	}

	const onPageChanged = async (page) => {
		setIsLoading(true)
		await dispatch(fetchDeliveryPlans(currentStatus, page - 1, searchParams))
		setIsLoading(false)
	}

	const onRegenerateDeliveryPlan = async deliveryPlanId => {
		try {
			await DeliveryPlans.regenerateDeliveryPlan(deliveryPlanId)
			dispatch(updateDeliveryPlanLoadState(deliveryPlanId, true))
		} catch (e) {
			notification.error({
				message: 'Unable to regenerate delivery plan.',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const onRefreshDeliveryPlan = async deliveryPlanId => {
		try {
			await dispatch(fetchDeliveryPlanDetails(deliveryPlanId))
		} catch (e) {
			notification.error({
				message: 'Unable to refresh delivery plan.',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const getDeliveryPlanGroupings = (deliveryPlans = []) => {
		const groupings = deliveryPlans.reduce((acc, deliveryPlan) => {
			const date = moment(deliveryPlan.deliveryDate)
			const week = `${date.startOf('isoWeek').isoWeek()}`
			const yearWeekKey = `${date.startOf('isoWeek').year()}-${week.length > 1 ? week : `0${week}`}`
			if (!acc[yearWeekKey]) {
				acc[yearWeekKey] = []
			}
			acc[yearWeekKey].push(deliveryPlan)
			return acc
		}, {})
		const dateKeys = Object.keys(groupings)
		dateKeys.sort((a, b) => b.localeCompare(a))
		const deliveryPlanWeeklyGroupings = dateKeys.map(key => {
			return {
				dateKey: key,
				deliveryPlans: groupings[key]
			}
		})
		return deliveryPlanWeeklyGroupings
	}

	const getWeekHeading = (dateKey) => {
		const currentDate = new Date()
		const currentYear = moment(currentDate).year()
		const currentWeek = moment(currentDate).isoWeek()
		const [year, week] = dateKey.split('-').map(val => +val)
		const date = moment().year(year).isoWeek(week)
		if (currentYear === year && currentWeek === week) {
			return 'This Week'
		} else if (currentYear === year && currentWeek + 1 === week) {
			return 'Next Week'
		} else if (currentYear === year && currentWeek - 1 === week) {
			return 'Last Week'
		} else {
			return `${date.startOf('isoWeek').format('MMM Do')} - ${date.endOf('isoWeek').format('MMM Do')}`
		}
	}

	const getListStyle = (isDraggingOver) => {
		return isDraggingOver ? { background: '#278EA522' } : {}
	}

	const onDragEnd = ({ source, destination, draggableId }) => {
		if (!destination || source.droppableId === destination.droppableId) {
			return
		}
		const sourceItemIndex = editableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === source.droppableId)
		const destinationItemIndex = editableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === destination.droppableId)
		const sourceItem = editableDeliveryPlan.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlan.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			if (sourceItem.startLocationId !== destinationItem.startLocationId) {
				Modal.confirm({
					title: 'Update Pick Up Location',
					content: 'Pick up locations will be updated for selected orders, are you sure you want to continue?',
					okText: 'Yes',
					cancelText: 'No',
					closable: true,
					onOk: () => handleRouteUpdate({
						sourceItemIndex,
						destinationItemIndex,
						draggableId
					})
				})
			} else {
				handleRouteUpdate({
					sourceItemIndex,
					destinationItemIndex,
					draggableId
				})
			}
		}
	}

	const handleRouteUpdate = ({ sourceItemIndex, destinationItemIndex, draggableId }) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const sourceItem = editableDeliveryPlan.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlan.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			const point = sourceItem.route.find(point => point.id === draggableId)
			sourceItem.route = sourceItem.route.filter(point => point.id !== draggableId)
			destinationItem.route.push(point)
			newEditableDeliveryPlan.deliveryPlanItems[sourceItemIndex] = sourceItem
			newEditableDeliveryPlan.deliveryPlanItems[destinationItemIndex] = destinationItem
		}
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const onRemoveRoutePoint = (deliveryPlanItem, point) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === deliveryPlanItem.id)
		const newDeliveryPlanItem = { ...deliveryPlanItem }
		newDeliveryPlanItem.route = newDeliveryPlanItem.route.filter(p => p.id !== point.id)
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const addRoutePoints = (salesOrders) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === selectedDeliveryPlanItemId)
		const newDeliveryPlanItem = { ...newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] }
		const route = newDeliveryPlanItem.route
		const firstPoint = route[0]
		const startLat = +firstPoint.lat
		const startLon = +firstPoint.lon
		const newRoute = []
		const newSalesOrders = []
		salesOrders.forEach(salesOrder => {
			const lat = +salesOrder.location.latitude
			const lon = +salesOrder.location.longitude
			const point = {
				distance: getDistanceFromLatLonInMeters(startLat, startLon, lat, lon),
				id: salesOrder.id,
				lat,
				lon
			}
			newRoute.push(point)
			const newSalesOrder = { ...salesOrder }
			newSalesOrder.pickUpLocationId = firstPoint.locationId
			newSalesOrder.pickUpLocation = {
				id: firstPoint.locationId,
				address: firstPoint.address,
				latitude: +firstPoint.lat,
				longitude: +firstPoint.lon
			}
			newSalesOrders.push(newSalesOrder)
		})
		newDeliveryPlanItem.route = [...route, ...newRoute]
		newEditableDeliveryPlan.salesOrders = [...newEditableDeliveryPlan.salesOrders, ...newSalesOrders]
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlan(newEditableDeliveryPlan)
		setAddedSalesOrders(newSalesOrders)
	}

	const onAddNewRoute = (location) => {
		const newDeliveryPlanItem = {
			id: `new_${editableDeliveryPlan.deliveryPlanItems.length}_${location.id}`,
			route: [
				{
					id: `vehicle_${editableDeliveryPlan.deliveryPlanItems.length}_0-start`,
					address: location.address,
					locationId: location.id,
					lat: +location.latitude,
					lon: +location.longitude,
					distance: 0
				}
			]
		}
		const newEditableDeliveryPlan = { ...editableDeliveryPlan }
		newEditableDeliveryPlan.deliveryPlanItems.push(newDeliveryPlanItem)
		setEditableDeliveryPlan(newEditableDeliveryPlan)
	}

	const onEdit = (e, deliveryPlan) => {
		e.stopPropagation()
		const editableDeliveryPlan = JSON.parse(JSON.stringify(deliveryPlan))
		setEditableDeliveryPlan(editableDeliveryPlan)
		setIsSplitViewEnabled(true)
	}

	const onCancelEdit = (e) => {
		e.stopPropagation()
		setEditableDeliveryPlan(null)
		setAddedSalesOrders([])
	}

	const onExpandedViewClick = (deliveryPlan) => {
		if (expandableDeliveryPlan) {
			setExpandableDeliveryPlan(null)
			onExpandDeliveryItem([])
		} else {
			setExpandableDeliveryPlan(deliveryPlan)
			if (!expandedDeliveryPlanId) {
				onExpandDeliveryItem([deliveryPlan.id])
			}
		}
	}

	const isExpandedView = () => !!expandableDeliveryPlan

	const onSaveRoute = async (e) => {
		e.stopPropagation()
		try {
			if (editableDeliveryPlan) {
				const deliveryPlanItems = editableDeliveryPlan.deliveryPlanItems
				const routes = {}
				deliveryPlanItems.forEach(deliveryPlanItem => {
					routes[deliveryPlanItem.id] = deliveryPlanItem.route
				})
				setIsUpdatingRoutes(true)
				const payload = {
					routes,
					salesOrderIds: addedSalesOrders.map(so => so.id)
				}
				const updatedDeliveryPlan = await dispatch(updateDeliveryPlanRoutes(editableDeliveryPlan.id, payload))
				if (isExpandedView()) {
					setExpandableDeliveryPlan(updatedDeliveryPlan)
				}
				drawRoutes(editableDeliveryPlan.id, updatedDeliveryPlan)
				setEditableDeliveryPlan(null)
			}
		} catch (err) {
			notification.error({
				message: 'Unable to reroute.',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingRoutes(false)
		}
	}

	const confirmVehicleAssignment = async (status, deliveryPlanItem) => {
		const vehicleId = deliveryPlanItem.vehicle ? deliveryPlanItem.vehicle.id : null

		try {
			if (deliveryPlanItem) {
				setIsUpdatingRoutes(true)
				const payload = {
					status,
					vehicleId
				}
				const requestId = deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].id
				await dispatch(updateVehicleAssignmentStatus(requestId, payload))
				notification.success({
					message: 'Vehicle request status has updated',
					description: 'Vehicle request status has updated successfully!',
					placement: 'bottomLeft'
				})
			}
			setDisableVehicleStatusActionButtons([...disableVehicleStatusActionButtons, deliveryPlanItem.id])
		} catch (err) {
			notification.error({
				message: 'Unable to update vehicle status.',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingRoutes(false)
		}
	}

	const openSalesOrderDialog = (point, deliveryPlan) => {
		const newSalesOrderDialogs = { ...salesOrderDialogs }
		newSalesOrderDialogs[point.id] = {
			point,
			deliveryPlan
		}
		setSalesOrderDialogs(newSalesOrderDialogs)
		onClickSalesOrderDialog(point.id)
	}

	const onClickSalesOrderDialog = (id) => {
		if (lastClickedSalesOrderDialog !== id) {
			setLastClickedSalesOrderDialog(id)
			setSalesOrderDialogZIndex(salesOrderDialogZIndex + 1)
		}
	}

	const getDeliveryPlanTableColumns = () => {
		return currentStatus === DELIVERY_PLAN_PENDING_STATUS ?
			[
				...deliveryPlanColumns,
				{
					title: 'Action',
					key: 'action',
					render: deliveryPlan => {
						return (
							<span
								onClick={() => setSelectedDeliveryPlanId(deliveryPlan.id)}
								className={styles.actionIcon}
							>
								<EditTwoTone twoToneColor='#278EA5' />
							</span>
						)
					}
				}
			]
			:
			deliveryPlanColumns
	}

	const openRouteDialog = (mainIndex, point, deliveryPlan, deliveryPlanItem) => {
		setRouteDialog({
			mainIndex,
			point,
			deliveryPlan,
			deliveryPlanItem
		})
	}

	const closeSalesOrderDialog = (id) => {
		const newSalesOrderDialogs = { ...salesOrderDialogs }
		delete newSalesOrderDialogs[id]
		setSalesOrderDialogs(newSalesOrderDialogs)
		setClickedMarkers({ ...clickedMarkers, [id]: false })
	}

	const getSortedRoutePointsByDistance = (points) => {
		return points.sort((point1, point2) => {
			return point1.distance - point2.distance
		})
	}

	let deliveryPlans = pendingDeliveryPlans
	switch (status) {
		case DELIVERY_PLAN_PENDING_STATUS:
			deliveryPlans = pendingDeliveryPlans
			break
		case DELIVERY_PLAN_APPROVED_STATUS:
			deliveryPlans = approvedDeliveryPlans
			break
		case DELIVERY_PLAN_EN_ROUTE_STATUS:
			deliveryPlans = enRouteDeliveryPlans
			break
		case DELIVERY_PLAN_SCHEDULED_STATUS:
			deliveryPlans = scheduledDeliveryPlans
			break
		case DELIVERY_PLAN_DELIVERED_STATUS:
			deliveryPlans = deliveredDeliveryPlans
			break
		default:
			deliveryPlans = allDeliveryPlans
			break
	}

	const renderTruckRecommendations = (deliveryPlan) => {
		const usableTruckWeights = deliveryPlan.usableTruckWeights ? deliveryPlan.usableTruckWeights : []
		const occurrences = usableTruckWeights.reduce((acc, curr) => {
			return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
		}, {})

		return (
			Object.keys(occurrences).map(truckType => {
				return (
					<Tag key={truckType} style={{ marginBottom: 4 }}>
						{occurrences[truckType]}
						<img src='/img/close.svg' style={{ margin: '0 4px' }} />
						{deliveryPlan.useWeight ? `${truckType} Tonne Truck` : `${truckType} CBM Truck`}
					</Tag>
				)
			})
		)
	}

	const renderVehicleAssignmentCard = (deliveryPlanItem) => {
		if (deliveryPlanItem.vehicle &&
			deliveryPlanItem.requests &&
			deliveryPlanItem.requests.length &&
			deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].status === VEHICLE_REQUEST_PENDING_STATUS
		) {
			return (
				<div style={{ marginLeft: 26, display: 'flex', flexDirection: 'column' }}>
					<div>
						<p style={{ fontColor: '#000000', fontSize: 13, marginBottom: 3 }}>Vehicle Report</p>
						<p style={{ fontColor: '#828282', fontSize: 11 }}>{deliveryPlanItem.requests && deliveryPlanItem.requests[deliveryPlanItem.requests.length - 1].remark}</p>
					</div>
					<div>
						<p style={{ fontColor: '#000000', fontSize: 13, marginBottom: 3 }}>Requested Vehicle</p>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
							<p style={{ fontColor: '#828282', fontSize: 11 }}>{deliveryPlanItem.vehicle?.plateNumber}</p>
							<div style={{ display: 'flex', marginTop: -7 }}>
								<Button
									style={{ marginRight: 5, borderRadius: 10 }}
									disabled={disableVehicleStatusActionButtons.includes(deliveryPlanItem.id)}
									shape='squire'
									size='middle'
									onClick={() => confirmVehicleAssignment(VEHICLE_REQUEST_DENIED_STATUS, deliveryPlanItem)}
									danger
								>
									Decline
								</Button>
								<Button
									style={{ marginRight: 5, borderRadius: 10 }}
									disabled={disableVehicleStatusActionButtons.includes(deliveryPlanItem.id)}
									shape='squire'
									size='middle'
									onClick={() => confirmVehicleAssignment(VEHICLE_REQUEST_APPROVED_STATUS, deliveryPlanItem)}
								>
										Accept
								</Button>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	const renderDeliveryPlanItem = (deliveryPlan, deliveryPlanItem, mainIndex) => {
		const salesOrders = getSalesOrders(deliveryPlan.id)
		const sortedRoute = getSortedRoutePointsByDistance(deliveryPlanItem.route.slice(1))
		return (
			<div>
				<div
					onClick={() => {
						onDeliveryPlanItemHovered(deliveryPlanItem.id, true)
						openRouteDialog(mainIndex, deliveryPlanItem.route[0], deliveryPlan, deliveryPlanItem)
					}}
					onMouseLeave={() => {
						onDeliveryPlanItemHovered(deliveryPlanItem.id, false)
						setRouteDialog(null)
					}}
					key={deliveryPlanItem.id}
					className={mainIndex % 2 === 0 ? styles.routeContainerAlt : styles.routeContainer}
				>
					{
						deliveryPlanItem.route[0] ?
							<div
								onMouseEnter={() => {
									setCenter({ lat: deliveryPlanItem.route[0].lat, lng: deliveryPlanItem.route[0].lon })
									setHoveredPointId(deliveryPlanItem.route[0].id)
								}}
								onMouseLeave={() => setHoveredPointId(null)}
								onClick={() => {
									openRouteDialog(mainIndex, deliveryPlanItem.route[0], deliveryPlan, deliveryPlanItem)
								}}
								className={styles.dot}
							>
								{mainIndex + 1}
							</div> : null
					}
					<div
						className={styles.routeDotContainer}
					>
						{
							sortedRoute.map((point, index) => {
								return (
									<div
										key={point.id}
										index={index}
									>
										<div
											className={styles.dotItem}
											key={index}
											onClick={() => {
												openSalesOrderDialog(point, deliveryPlan)
											}}
										>
											<div
												onMouseEnter={() => {
													setCenter({ lat: point.lat, lng: point.lon })
													setHoveredPointId(point.id)
												}}
												onMouseLeave={() => setHoveredPointId(null)}
												className={
													point.id.includes('location') ? styles.pickUpDot :
														index % 2 === 0 ? styles.routeDotAlt : styles.routeDot
												}
											/>
											{
												isExpandedView() && salesOrders[point.id] &&
												<div className={styles.salesOrderNumber}>{getSalesOrderNumber(salesOrders[point.id])}</div>
											}
										</div>
									</div>
								)
							})
						}
					</div>
					<div style={{ marginLeft: 'auto', display: 'flex' }}>
						{
							isExpandedView() || isSplitViewEnabled ?
								<>
									<div className={styles.travelInfoContainer}>
										<span>{`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</span>
										<span>{`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</span>
									</div>
								</> : null
						}
						<Assignees deliveryPlanItem={deliveryPlanItem} />
						<Dropdown
							overlay={(
								<Menu>
									<Menu.Item
										onClick={() => {
											setIsAssignDriverModalVisible(true)
											setSelectedDeliveryPlanItemId(deliveryPlanItem.id)
										}}
										key='1'
									>
									Assign Driver
									</Menu.Item>
									<Menu.Item
										onClick={() => {
											setIsAssignHelperModalVisible(true)
											setSelectedDeliveryPlanItemId(deliveryPlanItem.id)
										}}
										key='2'
									>
									Assign Helper
									</Menu.Item>
									<Menu.Item
										onClick={() => {
											setIsAssignVehicleModalVisible(true)
											setSelectedDeliveryPlanItemId(deliveryPlanItem.id)
										}}
										key='3'
									>
									Assign Vehicle
									</Menu.Item>
								</Menu>
							)} trigger={['click']}
						>
							<MoreOutlined style={{ marginLeft: 12, alignItems: 'center', display: 'flex' }} />
						</Dropdown>
					</div>
				</div>
				{renderVehicleAssignmentCard(deliveryPlanItem)}
			</div>
		)
	}

	const renderEditableDeliveryPlanItem = (deliveryPlan, deliveryPlanItem, mainIndex) => {
		const salesOrders = getSalesOrders(deliveryPlan.id)
		const sortedRoute = getSortedRoutePointsByDistance(deliveryPlanItem.route.slice(1))
		return (
			<div
				onMouseEnter={() => {
					onDeliveryPlanItemHovered(deliveryPlanItem.id, true)
					openRouteDialog(mainIndex, deliveryPlanItem.route[0], deliveryPlan, deliveryPlanItem)
					setHoveredRouteId(deliveryPlanItem.id)
				}}
				onMouseLeave={() => {
					onDeliveryPlanItemHovered(deliveryPlanItem.id, false)
					setRouteDialog(null)
					setHoveredRouteId(null)
				}}
				key={deliveryPlanItem.id}
				className={mainIndex % 2 === 0 ? styles.routeContainerAlt : styles.routeContainer}
			>
				{
					deliveryPlanItem.route[0] ?
						<div
							onMouseEnter={() => {
								setCenter({ lat: deliveryPlanItem.route[0].lat, lng: deliveryPlanItem.route[0].lon })
								setHoveredPointId(deliveryPlanItem.route[0].id)
							}}
							onMouseLeave={() => setHoveredPointId(null)}
							onClick={() => {
								openRouteDialog(mainIndex, deliveryPlanItem.route[0], deliveryPlan, deliveryPlanItem)
							}}
							className={styles.dot}
						>
							{mainIndex + 1}
						</div> : null
				}
				<Droppable droppableId={deliveryPlanItem.id} direction='horizontal'>
					{
						(provided, snapshot) => {
							return (
								<div
									ref={provided.innerRef}
									className={styles.routeDotContainer}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{
										sortedRoute.map((point, index) => {
											return (
												<Draggable
													isDragDisabled={!editableDeliveryPlan}
													key={point.id}
													draggableId={point.id}
													index={index}
												>
													{
														(provided) => {
															return (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className={styles.dotItem}
																	key={index}
																	onClick={() => {
																		openSalesOrderDialog(point, deliveryPlan)
																	}}
																>
																	<Dropdown
																		overlay={
																			<Menu>
																				<Menu.Item
																					style={{ color: 'red' }}
																					onClick={() => onRemoveRoutePoint(deliveryPlanItem, point)}
																					key='remove'
																				>
																					Remove
																				</Menu.Item>
																			</Menu>
																		}
																		trigger={['contextMenu']}
																	>
																		<div
																			style={{
																				display: 'flex',
																				alignItems: 'center',
																				flexDirection: 'column'
																			}}
																		>
																			<div
																				onMouseEnter={() => {
																					setCenter({ lat: point.lat, lng: point.lon })
																					setHoveredPointId(point.id)
																				}}
																				onMouseLeave={() => setHoveredPointId(null)}
																				className={
																					point.id.includes('location') ? styles.pickUpDot :
																						index % 2 === 0 ? styles.routeDotAlt : styles.routeDot
																				}
																			/>
																			{
																				isExpandedView() && salesOrders[point.id] &&
																					<div className={styles.salesOrderNumber}>{getSalesOrderNumber(salesOrders[point.id])}</div>
																			}
																		</div>
																	</Dropdown>
																</div>
															)
														}
													}
												</Draggable>
											)
										})
									}
									{provided.placeholder}
								</div>
							)
						}

					}
				</Droppable>
				{
					hoveredRouteId === deliveryPlanItem.id ?
						<div className={styles.addRoutePoints}>
							<PlusOutlined
								onClick={() => {
									setIsAddRoutePointsModalVisible(true)
									setSelectedDeliveryPlanItemId(deliveryPlanItem.id)
								}}
								className={styles.addRoutePointsButton}
							/>
						</div> : null
				}
				<div style={{ marginLeft: 'auto', display: 'flex' }}>
					{
						isExpandedView() || isSplitViewEnabled ?
							<>
								<div className={styles.travelInfoContainer}>
									<span>{`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</span>
									<span>{`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</span>
								</div>
							</> : null
					}
				</div>
			</div>
		)
	}

	const renderDeliveryPlanAccordionHeader = (deliveryPlan) => {
		return (
			<div className={styles.routeHeader} ref={deliveryPlan.id === expandedDeliveryPlanId ? expandedViewRef : null}>
				<div style={{ display: 'flex' }}>
					{
						isExpandedView() ?
							null
							:
							<img
								className={expandedDeliveryPlanId === deliveryPlan.id ? styles.chevronExpanded : styles.chevron}
								src='/img/chevron.svg'
							/>
					}
					<div className={styles.id}>
						{`DP-${deliveryPlan.shortId}`}
					</div>
					<Link
						onClick={e => e.stopPropagation()}
						href={`/delivery-plans/${deliveryPlan.id}`}
					>
						<img
							onClick={e => e.stopPropagation()}
							src='/img/external-link.svg'
							style={{ alignSelf: 'center', marginLeft: 12, width: 12 }}
						/>
					</Link>
					{
						expandedDeliveryPlanId === deliveryPlan.id && deliveryPlan.status.toLowerCase() === DELIVERY_PLAN_PENDING_STATUS &&
						<>
							{
								!editableDeliveryPlan ?
									<img
										onClick={e => onEdit(e, deliveryPlan)}
										className={styles.editIcon}
										src='/img/edit.svg'
									/> :
									<div
										style={{ display: 'flex', marginLeft: 12, alignItems: 'center' }}
									>
										<Button
											style={{ marginLeft: '10px' }}
											size='small'
											type='primary'
											icon={<PlusOutlined />}
											onClick={e => {
												e.stopPropagation()
												setIsPickUpLocationModalVisible(true)
											}}
											ghost
										>
											New Route
										</Button>
										<Button
											style={{ marginLeft: '10px' }}
											size='small'
											type='primary'
											loading={isUpdatingRoutes}
											onClick={onSaveRoute}
										>
											Save
										</Button>
										<Button
											style={{ marginLeft: '10px' }}
											size='small'
											type='default'
											onClick={onCancelEdit}
											danger
										>
											Cancel
										</Button>
									</div>
							}
						</>
					}
				</div>
				<div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
					{
						loadingDeliveryPlans[deliveryPlan.id] ?
							<Tooltip
								title='Generating delivery plan...'
							>
								<Spin
									style={{ marginRight: 12 }}
									indicator={<LoadingOutlined />}
								/>
							</Tooltip> : null
					}
					<ExpandButton
						isExpanded={isExpandedView()}
						onClick={(e) => {
							e.stopPropagation()
							onExpandedViewClick(deliveryPlan)
						}}
						style={{ marginRight: 16 }}
					/>
					<div className={styles.date}>
						{moment(deliveryPlan.deliveryDate).format('MMMM DD, YYYY')}
					</div>
					<Dropdown
						overlay={(
							<Menu>
								<Menu.Item
									onClick={(e) => {
										e.domEvent.stopPropagation()
										onRefreshDeliveryPlan(deliveryPlan.id)
									}}
									key='1'
								>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<ReloadOutlined
											style={{ marginRight: 12 }}
										/>
										Refresh
									</div>
								</Menu.Item>
								{
									+deliveryPlan._count.salesOrders > 1 && !loadingDeliveryPlans[deliveryPlan.id] ?
										<Menu.Item
											onClick={(e) => {
												e.domEvent.stopPropagation()
												onRegenerateDeliveryPlan(deliveryPlan.id)
											}}
											key='2'
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<SyncOutlined
													style={{ marginRight: 12 }}
												/>
												Regenerate Delivery Plan
											</div>
										</Menu.Item> : null
								}
							</Menu>
						)} trigger={['click']}
					>
						<MoreOutlined
							onClick={e => e.stopPropagation()}
							style={{ marginLeft: 12, alignItems: 'center', display: 'flex' }}
						/>
					</Dropdown>
				</div>
			</div>
		)
	}

	const renderDeliveryPlanDetailPanel = (deliveryPlan) => {
		const deliveryPlanItems = editableDeliveryPlan ? editableDeliveryPlan.deliveryPlanItems : deliveryPlan.deliveryPlanItems
		return (
			<Panel
				collapsible={true}
				header={renderDeliveryPlanAccordionHeader(deliveryPlan)}
				showArrow={false}
				key={deliveryPlan.id}
				className={isExpandedView() ? 'expanded-custom-panel' : 'custom-panel'}
				style={isExpandedView() ? { borderBottom: '0px' } : {}}
			>
				{
					loadingDeliveryPlanId &&
					<div className={styles.requestProgress}>
						<div className={styles.bar} />
					</div>
				}
				{
					!isExpandedView() && deliveryPlan.usableTruckWeights && deliveryPlan.usableTruckWeights.length > 0 &&
					<div className={styles.truckRecommendationsContainer}>
						<h1 className={styles.heading}>Truck Recommendations</h1>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							{renderTruckRecommendations(deliveryPlan)}
						</div>
					</div>
				}
				{
					deliveryPlanItems && deliveryPlanItems.length >= 1 ?
						deliveryPlanItems.map((deliveryPlanItem, index) => editableDeliveryPlan ? renderEditableDeliveryPlanItem(editableDeliveryPlan, deliveryPlanItem, index) : renderDeliveryPlanItem(deliveryPlan, deliveryPlanItem, index)) :
						<Empty
							description={
								+deliveryPlan._count.salesOrders <= 1 ?
									'No route data as the delivery plan only contains one order. Try adding more orders for this delivery date.' :
									'Unable to generate a valid delivery plan. Please try again later.'
							}
						/>
				}
			</Panel>
		)
	}

	const renderMapContent = () => {
		return (
			<Content className={styles.mapContainer}>
				<Map
					onMapsLoaded={onMapsLoaded}
					containerStyle={{
						width: '100%',
						height: isExpandedView() ? 'calc(60vh - 64px)' : 'calc(100vh - 64px)',
						borderRadius: '0'
					}}
					center={center}
				>
					{
						deliveryPlans
							.filter(deliveryPlan => expandedDeliveryPlanId === deliveryPlan.id)
							.map(deliveryPlan => {
								return deliveryPlan.deliveryPlanItems?.map((deliveryPlanItem, mainIndex) => {
									return deliveryPlanItem.route.map((point, index) => {
										return (
											<LocationMarker
												index={index}
												hoveredPointId={hoveredPointId}
												id={point.id}
												key={point.id}
												lat={point.lat}
												lng={point.lon}
												onMouseLeave={() => {
													if (!clickedMarkers[point.id]) {
														closeSalesOrderDialog(point.id)
													}
													if (!clickedMarkers['start']) {
														setRouteDialog(null)
													}
												}}
												onMouseEnter={() => {
													if (point.id.includes('start')) {
														openRouteDialog(mainIndex, point, deliveryPlan, deliveryPlanItem)
													} else {
														openSalesOrderDialog(point, deliveryPlan)
													}
												}}
												onMarkerClick={() => {
													if (point.id.includes('start')) {
														openRouteDialog(mainIndex, point, deliveryPlan, deliveryPlanItem)
														setClickedMarkers({ ...clickedMarkers, 'start': true })
													} else {
														openSalesOrderDialog(point, deliveryPlan)
														setClickedMarkers({ ...clickedMarkers, [point.id]: true })
													}
													setCenter({ lat: point.lat, lng: point.lon })
												}}
											/>
										)
									})
								})
							})
					}
					{
						Object.keys(userLocations).map(uid => {
							const location = userLocations[uid]
							return (
								location &&
								<UserMarker
									userId={uid}
									key={uid}
									lat={location.lat()}
									lng={location.lng()}
								/>
							)
						})
					}
					{
						Object.keys(salesOrderDialogs).map(id => {
							const dialogInfo = salesOrderDialogs[id]
							return (
								<SalesOrderDialog
									key={id}
									id={id}
									deliveryPlan={dialogInfo.deliveryPlan}
									lat={dialogInfo.point.lat}
									lng={dialogInfo.point.lon}
									onClose={() => closeSalesOrderDialog(id)}
									zIndex={salesOrderDialogZIndex}
									lastClickedSalesOrderDialog={lastClickedSalesOrderDialog}
									onClick={() => onClickSalesOrderDialog(id)}
								/>
							)
						})
					}
					{
						routeDialog && routeDialog.point ?
							<RouteDialog
								id={routeDialog.point.id}
								mainIndex={routeDialog.mainIndex}
								deliveryPlan={routeDialog.deliveryPlan}
								deliveryPlanItem={routeDialog.deliveryPlanItem}
								lat={routeDialog.point.lat}
								lng={routeDialog.point.lon}
								onClose={() => setRouteDialog(null)}
							/> : null
					}
					{
						Object.values(vehiclesMap).filter(filterVehicle).map(vehicle => {
							const position = vehicle.tracker.position
							return (
								<VehicleMarker
									vehicle={vehicle}
									key={vehicle.id}
									lat={position.latitude}
									lng={position.longitude}
								/>
							)
						})
					}

				</Map>
				<div className={styles.buttonContainer}>
					<div
						onClick={setMapView}
						style={{ marginRight: '16px' }}
						className={!isListMode ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/map.svg' width={20} height={20} />
					</div>
					<div
						onClick={() => {
							setIsSplitViewEnabled(isEnabled => !isEnabled)
						}}
						style={{ marginRight: '20px' }}
						className={isSplitViewEnabled ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/split.svg' />
					</div>
					<div
						onClick={() => {
							setListView()
							setExpandedDeliveryPlanId(null)
						}}
						style={{ marginRight: '20px' }}
						className={isListMode ? styles.filterButtonSelected : styles.filterButton}
					>
						<img src='/img/list.svg' />
					</div>
					<div className={styles.fleetPreference}>
						<Link
							href={'/settings'}
						>
							<span className={styles.fleetPreferenceLinkContent}>
								<img src='/img/truck.svg' width={20} height={20} />
								<span>Fleet Preference</span>
							</span>
						</Link>
					</div>
					<div style={{ marginRight: '32px' }} className={styles.createPlanButton}>
						<Button
							type='primary'
							shape='round'
							size='large'
							disabled={!permissions.addDeliveryPlans}
							onClick={() => showCreateDeliveryPlanModal(true)}
						>
							Create Delivery Plan
						</Button>
					</div>
				</div>
				<div className={styles.filterControl}>
					<Popover
						content={
							<div className={styles.filterOptions}>
								<div className={styles.filterOption}>
									<Checkbox
										className='assigned-vehicles'
										checked={isAssignedVehiclesChecked}
										onChange={(e) => setIsAssignedVehiclesChecked(e.target.checked) }
									>
										Assigned Vehicles
									</Checkbox>
								</div>
								<div className={styles.filterOption}>
									<Checkbox
										className='unassigned-vehicles'
										checked={isUnassignedVehiclesChecked}
										onChange={(e) => setIsUnassignedVehiclesChecked(e.target.checked)}
									>
										Unassigned Vehicles
									</Checkbox>
								</div>
							</div>
						}
						trigger='click'
						visible={isFilterControlVisible}
						onVisibleChange={setIsFilterControlVisible}
					>
						<img
							className={styles.filterControlIcon}
							src='/img/control.svg'
							alt='...'
						/>
					</Popover>
				</div>
			</Content>
		)
	}

	const deliveryPlanWeeklyGroupings = useMemo(() => getDeliveryPlanGroupings(deliveryPlans), [deliveryPlans])
	return (
		<Page fullPage>
			{
				isListMode ?
					<Layout className={styles.layout}>
						<Content className={styles.mapContainer} style={{ padding: '0 50px' }}>
							<div className={styles.contentHeader}>
								<div className={styles.tabContainer}>
									{
										tabs.map(tab => {
											return (
												<div
													onClick={() => router.push(tab.path)}
													className={currentStatus === tab.status ? styles.tabSelected : styles.tab} key={tab.path}
												>
													<div>{tab.title}</div>
													<div className={currentStatus === tab.status ? styles.indicator : styles.indicatorGone} />
												</div>
											)
										})
									}
								</div>
								<div style={{ display: 'flex', marginLeft: 'auto', marginTop: '10px' }}>
									<div
										onClick={setMapView}
										style={{ marginRight: '16px' }}
										className={!isListMode ? styles.filterButtonSelected : styles.filterButton}
									>
										<img src='/img/map.svg' width={20} height={20} />
									</div>
									<div
										onClick={() => {
											setListView()
											setExpandedDeliveryPlanId(null)
										}}
										style={{ marginRight: '24px' }}
										className={isListMode ? styles.filterButtonSelected : styles.filterButton}
									>
										<img src='/img/list.svg' />
									</div>
									<div className={styles.fleetPreference}>
										<Link
											href={'/settings'}
										>
											<span className={styles.fleetPreferenceLinkContent}>
												<img src='/img/truck.svg' width={20} height={20} />
												<span>Fleet Preference</span>
											</span>
										</Link>
									</div>
									<div className={styles.createPlanButton}>
										<Button
											type='primary'
											shape='round'
											size='large'
											onClick={() => showCreateDeliveryPlanModal(true)}
										>
											Create Delivery Plan
										</Button>
									</div>
								</div>
							</div>
							{
								isLoading || deliveryPlans.length > 0 ?
									<Table
										refresh={() => onPageChanged(page)}
										style={{ marginTop: '24px' }}
										loading={isLoading && deliveryPlans.length === 0}
										columns={getDeliveryPlanTableColumns()}
										dataSource={deliveryPlans}
										pagination={{
											pageSize: 50,
											total: totalCount,
											position: ['topLeft'],
											onChange: onPageChanged,
											showSizeChanger: false,
											current: page + 1
										}}
										scroll={{ y: 'calc(100vh - 340px)' }}
										rowKey='id'
									/> :
									<Empty
										description='Try adding more sales data to automatically generate delivery plans or you can manually create one.'
										image='/img/delivery-plans-empty.svg'
										imageStyle={{ height: '450px', marginBottom: '24px' }}
										style={{ marginTop: '128px' }}
									/>
							}
						</Content>
					</Layout> :
					<Layout
						className={styles.layout}
						style={{ flexDirection: isExpandedView() ? 'column' : 'row' }}
					>
						<Splitter disabled={!isSplitViewEnabled}>
							<Sider
								className={styles.sideMenu}
								style={{ display: isExpandedView() ? 'none' : 'block' }}
							>
								<DragDropContext
									onDragEnd={onDragEnd}
								>
									{
										deliveryPlanWeeklyGroupings.length > 0 ?
											deliveryPlanWeeklyGroupings.map(grouping => {
												return (
													<div key={grouping.dateKey} style={{ display: 'flex', flexDirection: 'column' }}>
														<div className={styles.weekHeading}>{getWeekHeading(grouping.dateKey)}</div>
														<Collapse
															activeKey={expandedDeliveryPlanId ? [expandedDeliveryPlanId] : []}
															className={styles.deliveryPlansContainer}
															bordered={false}
															onChange={onExpandDeliveryItem}
														>
															{
																grouping.deliveryPlans.map(renderDeliveryPlanDetailPanel)
															}
														</Collapse>
													</div>
												)
											}) :
											isLoading && deliveryPlanWeeklyGroupings.length === 0 ?
												<Spin
													size='large'
													style={{ width: '100%', marginTop: '128px' }}
												/> :
												<Empty
													description='No Delivery Plans'
													style={{ marginTop: '128px' }}
												/>
									}
								</DragDropContext>
							</Sider>
							{
								renderMapContent()
							}
						</Splitter>
						{
							isExpandedView() &&
								<div className={styles.expandedDeliveryPlanContainer}>
									<DragDropContext
										onDragEnd={onDragEnd}
									>
										<Collapse
											activeKey={[expandableDeliveryPlan.id]}
											bordered={false}
											className={styles.deliveryPlansContainer}
											style={{ height: '100%' }}
										>
											{
												renderDeliveryPlanDetailPanel(expandableDeliveryPlan)
											}
										</Collapse>
									</DragDropContext>
								</div>
						}
					</Layout>
			}
			{
				isAssignVehicleModalVisible ?
					<AssignVehicleModal
						deliveryPlanItemId={selectedDeliveryPlanItemId}
						visible={isAssignVehicleModalVisible}
						onCancel={() => {
							setIsAssignVehicleModalVisible(false)
							setSelectedDeliveryPlanItemId(null)
						}}
					/> : null
			}
			{
				isAssignDriverModalVisible ?
					<AssignDriverModal
						onDriverAssigned={onDriverAssigned}
						deliveryPlanItemId={selectedDeliveryPlanItemId}
						visible={isAssignDriverModalVisible}
						onCancel={() => {
							setIsAssignDriverModalVisible(false)
							setSelectedDeliveryPlanItemId(null)
						}}
					/> : null
			}
			{
				isAssignHelperModalVisible ?
					<AssignHelperModal
						deliveryPlanItemId={selectedDeliveryPlanItemId}
						visible={isAssignHelperModalVisible}
						onCancel={() => {
							setIsAssignHelperModalVisible(false)
							setSelectedDeliveryPlanItemId(null)
						}}
					/> : null
			}
			{
				isUpdatingRoutes ?
					<div className={styles.reroutingScreen}>
						<div className={styles.reroutingSpinContainer}>
							<img src={'/img/tracking.svg'} />
							<h2 className={styles.rerouting}>
								Rerouting
								<span>.</span>
								<span>.</span>
								<span>.</span>
							</h2>
						</div>
					</div> : null
			}
			{
				isAddRoutePointsModalVisible ?
					<AddRoutePointsModal
						onCancel={() => setIsAddRoutePointsModalVisible(false)}
						deliveryPlanId={editableDeliveryPlan.id}
						visible={isAddRoutePointsModalVisible}
						onAddClicked={addRoutePoints}
					/> : null
			}
			{
				isCreateDeliveryPlanModalVisible ?
					<CreateDeliveryPlanModal
						onCancel={() => {
							showCreateDeliveryPlanModal(false)
							setDeliveryPlanDate(null)
							setShowUnassigned(false)
						}}
						shouldShowUnassigned={showUnassigned}
						date={deliveryPlanDate}
						visible={isCreateDeliveryPlanModalVisible}
					/> : null
			}
			{
				selectedDeliveryPlanId ?
					<EditDeliveryPlanModal
						onCancel={() => setSelectedDeliveryPlanId(null)}
						visible={!!selectedDeliveryPlanId}
						deliveryPlanId={selectedDeliveryPlanId}
					/> : null
			}
			{
				isPickUpLocationModalVisible ?
					<StartLocationModal
						onCancel={() => setIsPickUpLocationModalVisible(false)}
						visible={isPickUpLocationModalVisible}
						onUseLocation={location => {
							setIsPickUpLocationModalVisible(false)
							onAddNewRoute(location)
						}}
					/> : null
			}
		</Page>
	)
}

export default DeliveryPlansPage

const UserMarker = ({ userId }) => {
	return (
		<div className={styles.userMarkerContainer}>
			<Avatar
				className={styles.userMarker}
				size={28}
				icon={<UserOutlined />}
				style={{ background: colorHash.hex(userId) }}
			/>
		</div>
	)
}

const SalesOrderDialog = ({
	id,
	deliveryPlan,
	onClose,
	zIndex,
	lastClickedSalesOrderDialog,
	onClick
}) => {
	const dispatch = useDispatch()
	const { salesOrderDetails } = useSelector(state => state.salesOrdersReducer)
	const [currentZIndex, setCurrentZIndex] = useState(zIndex)
	const [location, setLocation] = useState()

	useEffect(() => {
		if (!id.includes('start') && !id.includes('end') && !id.includes('location')) {
			dispatch(fetchSalesOrderDetails(id))
		} else if (id.includes('location')) {
			const locationId = id.split('_')[1]
			getLocation(locationId)
		}
	}, [])

	useEffect(() => {
		if (id === lastClickedSalesOrderDialog) {
			setCurrentZIndex(zIndex)
		}
	}, [lastClickedSalesOrderDialog])

	const getLocation = async (locationId) => {
		const response = await LocationsApi.single(locationId)
		if (response.data) {
			setLocation(response.data)
		}
	}

	const salesOrderDetail = salesOrderDetails[id]
	return (
		<div className={styles.markerDialog} onClick={onClick} style={{ zIndex: currentZIndex }}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Button
					onClick={e => {
						e.stopPropagation()
						onClose()
					}}
					size='small'
					shape='circle' icon={<CloseOutlined style={{ fontSize: 10 }} />}
				/>
				<div className={styles.dialogTitle}>{`DP-${deliveryPlan.shortId}`}</div>
			</div>
			{
				salesOrderDetail ?
					<div className={styles.dialogInfoContainer}>
						<h1 className={styles.heading}>{getSalesOrderNumber(salesOrderDetail)}</h1>
						{
							salesOrderDetail.distributor &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Dealer Name:</span>
								<span className={styles.value}>{salesOrderDetail.distributor.name}</span>
							</Row>
						}
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Order Amount:</span>
							<span className={styles.value}>{getFormattedCurrency(salesOrderDetail.totalAmount)}</span>
						</Row>
						{
							!salesOrderDetail.isStockTransfer &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Payment:</span>
								<span className={styles.value}>{convertEnumToString(salesOrderDetail.paymentMethod)}</span>
							</Row>
						}
						<Button
							style={{ marginTop: 12, marginLeft: 'auto' }}
							type='primary'
							onClick={() => router.push(`${paths.SALES_ORDERS}/${salesOrderDetail.id}`)}
						>
							View Details
						</Button>
					</div> :
					id.includes('location') && location ?
						<div className={styles.dialogInfoContainer}>
							<h1 className={styles.heading}>Pick Up Location</h1>
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>Address:</span>
								<span className={styles.value}>{location.address}</span>
							</Row>
						</div> :
						<Skeleton active />
			}
		</div>
	)
}

const RouteDialog = ({
	id,
	mainIndex,
	deliveryPlan,
	deliveryPlanItem,
	onClose
}) => {
	const dispatch = useDispatch()
	const { deliveryPlanItemDetails } = useSelector(state => state.deliveryPlansReducer)
	const [lastSalesOrder, setLastSalesOrder] = useState()

	useEffect(() => {
		if (id.includes('start') && !deliveryPlanItem.id.includes('new')) {
			dispatch(fetchDeliveryPlanItemDetails(deliveryPlanItem.id))
		}
	}, [deliveryPlanItem])

	const deliveryPlanItemDetail = deliveryPlanItemDetails[deliveryPlanItem.id]
	useEffect(() => {
		if (deliveryPlanItemDetail) {
			const salesOrders = deliveryPlanItemDetail.salesOrders
			setLastSalesOrder(salesOrders[salesOrders.length - 1])
		}
	}, [deliveryPlanItemDetail])

	return (
		<div className={styles.routeMarkerDialog}>
			<div style={{ marginBottom: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
				<h1 className={styles.heading} style={{ margin: 0, fontWeight: 'bold', color: '#278EA5', fontSize: 16 }}>{`DP-${deliveryPlan.shortId} #${mainIndex + 1}`}</h1>
				<Button
					style={{ marginLeft: 'auto' }}
					onClick={e => {
						e.stopPropagation()
						onClose()
					}}
					size='small'
					shape='circle' icon={<CloseOutlined style={{ fontSize: 10 }} />}
				/>
			</div>
			{
				deliveryPlanItemDetail ?
					<div>
						<h1 className={styles.heading}>Route Details</h1>
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Starting Point:</span>
							{deliveryPlanItemDetail.startLocation && <span className={styles.value}>{deliveryPlanItemDetail.startLocation.address}</span>}
						</Row>
						{
							lastSalesOrder &&
							<Row style={{ margin: '3px 0' }}>
								<span className={styles.valueTitle}>End Point:</span>
								{lastSalesOrder.location && <span className={styles.value}>{lastSalesOrder.location.address}</span>}
							</Row>
						}
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Distance:</span>
							<span className={styles.value}>{`${(+deliveryPlanItem.travelDistance / 1000).toFixed(2)} km`}</span>
						</Row>
						<Row style={{ margin: '3px 0' }}>
							<span className={styles.valueTitle}>Duration:</span>
							<span className={styles.value}>{`${moment.duration(+deliveryPlanItem.travelTime * 1000).humanize()}`}</span>
						</Row>
					</div> : <Skeleton active />
			}
		</div>
	)
}

export const LocationMarker = ({
	id,
	index,
	hoveredPointId,
	onMarkerClick,
	onMouseLeave,
	onMouseEnter
}) => {
	const [isHovered, setIsHovered] = useState(false)

	useEffect(() => {
		if (hoveredPointId === id) {
			setIsHovered(true)
		} else {
			setIsHovered(false)
		}
	}, [hoveredPointId])

	return (
		<div
			style={{
				backgroundColor: id.includes('location') ? '#1890ff' :
					index % 2 === 0 ? '#278EA5' : '#A0AABD'
			}}
			onClick={onMarkerClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={
				isHovered ?
					id.includes('location') ? styles.pickUpMarkerHovered : styles.distributorMarkerHovered :
					id.includes('location') ? styles.pickUpMarker : styles.distributorMarker
			}
		>
			{
				id.includes('start') && index === 0 ?
					<div className={styles.startDot}>
						Start
					</div> : null
			}
		</div>
	)
}

LocationMarker.defaultProps = {
	onMouseLeave: () => {},
	onMouseEnter: () => {},
	onMarkerClick: () => {}
}

export const Assignees = ({ deliveryPlanItem }) => {
	const [isDriverDetailVisible, setIsDriverDetailVisible] = useState(false)
	const [isHelperDetailVisible, setIsHelperDetailVisible] = useState(false)
	const [isVehicleDetailVisible, setIsVehicleDetailVisible] = useState(false)

	const renderAssigneeDetail = (assignee) => {
		return (
			<>
				<div className={styles.assigneeDetail}>
					<Avatar
						size={64}
						icon={<UserOutlined />}
						style={{ background: colorHash.hex(assignee.id) }}
					/>
					<div className={styles.assigneeInfo}>
						<h3 style={{ whiteSpace: 'nowrap' }}>{assignee.name}</h3>
					</div>
				</div>
				<div className={styles.tripDetail}>
					<div>Estimate Trip Distance: {`${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km`}</div>
					<div>Working Hours: {`${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</div>
				</div>
			</>
		)
	}

	const renderVehicleDetail = (vehicle) => {
		return (
			<>
				<div className={styles.assigneeDetail}>
					<Avatar
						size={64}
						icon={<CarOutlined />}
						style={{ background: colorHash.hex(vehicle.id) }}
					/>
					<div className={styles.assigneeInfo}>
						<h3 style={{ whiteSpace: 'nowrap' }}>{vehicle.plateNumber}</h3>
					</div>
				</div>
				<div className={styles.tripDetail}>
					<div>Status: {getVehicleStatusLabel(vehicle.status)}</div>
					<div>Brand: {vehicle.brand}</div>
					<div>Type: {getVehicleTypeLabel(vehicle.type)}</div>
				</div>
			</>
		)
	}

	return (
		<Avatar.Group>
			{
				deliveryPlanItem.vehicle ?
					<Tooltip title={`Assigned to vehicle: ${deliveryPlanItem.vehicle?.plateNumber}`}>
						<Popover
							content={renderVehicleDetail(deliveryPlanItem.vehicle)}
							trigger='click'
							visible={isVehicleDetailVisible}
							onVisibleChange={(isVisible) => setIsVehicleDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsVehicleDetailVisible(true)}
								icon={<CarOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.vehicle.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
			{
				deliveryPlanItem.driver ?
					<Tooltip title={`Assigned to driver: ${deliveryPlanItem.driver.name}`}>
						<Popover
							content={renderAssigneeDetail(deliveryPlanItem.driver)}
							trigger='click'
							visible={isDriverDetailVisible}
							onVisibleChange={(isVisible) => setIsDriverDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsDriverDetailVisible(true)}
								icon={<UserOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.driver.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
			{
				deliveryPlanItem.helper ?
					<Tooltip title={`Assigned to helper: ${deliveryPlanItem.helper.name}`}>
						<Popover
							content={renderAssigneeDetail(deliveryPlanItem.helper)}
							trigger='click'
							visible={isHelperDetailVisible}
							onVisibleChange={(isVisible) => setIsHelperDetailVisible(isVisible)}
						>
							<Avatar
								onClick={() => setIsHelperDetailVisible(true)}
								icon={<UserOutlined />}
								style={{ background: colorHash.hex(deliveryPlanItem.helper.id) }}
							/>
						</Popover>
					</Tooltip> : null
			}
		</Avatar.Group>
	)
}
