import { get, patch, post } from './index'

export const PurchaseOrders = {
	index: ({
		page = 0,
		status,
		searchTerm,
		fromDate,
		toDate,
		statuses,
		accessToken
	} = {}) =>
		get('/purchase-orders', {
			headers: {
				authorization: accessToken
			},
			params: {
				page,
				status,
				searchTerm,
				fromDate,
				toDate,
				statuses
			}
		}),
	create: (params) =>
		post('/purchase-orders', params),
	single: (id, {
		accessToken
	} = {}) =>
		get(`/purchase-orders/${id}`, {
			headers: {
				authorization: accessToken
			}
		}),
	update: (id, data) =>
		patch(`/purchase-orders/${id}`, data)
}
