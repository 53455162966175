export const FETCH_DELIVERY_PLANS_SUCCESS = 'fetch_delivery_plans_success'
export const FETCH_DELIVERY_PLAN_DETAILS_SUCCESS = 'fetch_delivery_plan_details_success'
export const FETCH_DELIVERY_PLAN_ITEM_DETAILS_SUCCESS = 'fetch_delivery_plan_item_details_success'
export const ASSIGN_DRIVER_SUCCESS = 'assign_driver_success'
export const ASSIGN_HELPER_SUCCESS = 'assign_helper_success'
export const ASSIGN_VEHICLE_SUCCESS = 'assign_vehicle_success'
export const UPDATE_VEHICLE_TYPE_SUCCESS = 'update_vehicle_type'
export const UPDATE_DELIVERY_PLAN_SUCCESS = 'update_delivery_plan_success'
export const CREATE_DELIVERY_PLAN_SUCCESS = 'create_delivery_plan_success'
export const UPDATE_DELIVERY_PLAN_SALES_ORDERS_SUCCESS = 'update_delivery_plan_sales_orders_success'
export const UPDATE_DELIVERY_PLAN_LOAD_STATE = 'update_delivery_plan_load_state'
export const UPDATE_DELIVERY_PLAN_ROUTES = 'update_delivery_plan_routes'
export const UPDATE_DELIVERY_PLAN_ITEM_STATUS_SUCCESS = 'update_delivery_plan_item_status_success'
export const UPDATE_VEHICLE_ASSIGN_SUCCESS = 'update_vehicle_assign_status_success'
