import { HYDRATE } from 'next-redux-wrapper'
import {
	FETCH_VEHICLES_SUCCESS,
	FETCH_VEHICLE_TYPES_SUCCESS,
	CREATE_VEHICLE_SUCCESS,
	FETCH_VEHICLE_DETAIL_SUCCESS,
	FETCH_VEHICLE_TRIPS_SUCCESS,
	FETCH_MORE_VEHICLE_TRIPS_SUCCESS,
	FETCH_VEHICLE_METRICS_SUCCESS,
	FETCH_TRACKER_TYPES_SUCCESS
} from './action-types'

const initialState = {
	vehicles: [],
	vehicleDetails: {},
	vehicleTrips: [],
	vehicleTypes: [],
	trackerTypes: [],
	totalCount: 0,
	page: 0,
	metrics: {
		new: 0,
		total: 0,
		active: 0
	}
}

const vehiclesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_VEHICLES_SUCCESS: {
			return {
				...state,
				vehicles: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case CREATE_VEHICLE_SUCCESS: {
			return {
				...state,
				vehicles: [payload, ...state.vehicles]
			}
		}
		case FETCH_VEHICLE_TYPES_SUCCESS: {
			return {
				...state,
				vehicleTypes: payload.data
			}
		}
		case FETCH_TRACKER_TYPES_SUCCESS: {
			return {
				...state,
				trackerTypes: payload.data
			}
		}
		case FETCH_VEHICLE_DETAIL_SUCCESS: {
			const newState = { ...state }
			newState.vehicleDetails[payload.id] = payload.data
			return newState
		}
		case FETCH_VEHICLE_TRIPS_SUCCESS: {
			return {
				...state,
				vehicleTrips: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_MORE_VEHICLE_TRIPS_SUCCESS: {
			return {
				...state,
				vehicleTrips: [...state.vehicleTrips, ...payload.data.results],
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_VEHICLE_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		default:
			return state
	}
}

export default vehiclesReducer
