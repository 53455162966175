import { HYDRATE } from 'next-redux-wrapper'
import { FETCH_COMPANIES_SUCCESS, FETCH_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_COMPANY_SUCCESS, UPDATE_PREFERRED_VEHICLES_SUCCESS } from './action-types'

const initialState = {
	companies: [],
	totalCount: 0,
	page: 0,
	companyDetails: {}
}

const companiesReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_COMPANIES_SUCCESS: {
			return {
				...state,
				companies: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_COMPANY_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_PREFERRED_VEHICLES_SUCCESS:
		case UPDATE_COMPANY_DETAILS_SUCCESS: {
			const newState = { ...state }
			const company = newState.companyDetails[payload.id]
			if (company) {
				company.companyDetails = payload.data
			}
			return newState
		}
		case UPDATE_COMPANY_SUCCESS: {
			const newState = { ...state }
			const company = newState.companyDetails[payload.id]
			if (company) {
				newState.companyDetails[payload.id] = Object.assign(company, payload.data)
			}
			return newState
		}
		default:
			return state
	}
}

export default companiesReducer
