import { get, patch } from './index'

export const Companies = {
	index: ({
		page = 0,
		searchTerm
	} = {}) =>
		get('/companies', {
			params: {
				page,
				searchTerm
			}
		}),
	single: (id) =>
		get(`/companies/${id}`),
	update: (id, params) =>
		patch(`/companies/${id}`, params),
	updateDetails: (id, params) =>
		patch(`/companies/${id}/details`, params),
	updatePreferredVehicles: (id, params) =>
		patch(`/companies/${id}/details/vehicle-types`, params),
	updateApiKey: (id) =>
		patch(`/companies/${id}/details/api-key`)
}
