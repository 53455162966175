import { HYDRATE } from 'next-redux-wrapper'
import {
	FETCH_DELIVERY_PLANS_SUCCESS,
	FETCH_DELIVERY_PLAN_DETAILS_SUCCESS,
	ASSIGN_DRIVER_SUCCESS,
	UPDATE_DELIVERY_PLAN_SUCCESS,
	CREATE_DELIVERY_PLAN_SUCCESS,
	UPDATE_DELIVERY_PLAN_SALES_ORDERS_SUCCESS,
	ASSIGN_HELPER_SUCCESS,
	UPDATE_VEHICLE_TYPE_SUCCESS,
	UPDATE_DELIVERY_PLAN_LOAD_STATE,
	UPDATE_DELIVERY_PLAN_ROUTES,
	ASSIGN_VEHICLE_SUCCESS,
	FETCH_DELIVERY_PLAN_ITEM_DETAILS_SUCCESS,
	UPDATE_DELIVERY_PLAN_ITEM_STATUS_SUCCESS
} from './action-types'
import {
	DELIVERY_PLAN_PROCESSING_STATUS,
	DELIVERY_PLAN_PENDING_STATUS,
	DELIVERY_PLAN_APPROVED_STATUS,
	DELIVERY_PLAN_EN_ROUTE_STATUS,
	DELIVERY_PLAN_SCHEDULED_STATUS,
	DELIVERY_PLAN_DELIVERED_STATUS
} from '../../pages/delivery-plans'

const initialState = {
	deliveryPlanDetails: {},
	deliveryPlanItemDetails: {},
	deliveryPlans: [],
	processingDeliveryPlans: [],
	pendingDeliveryPlans: [],
	approvedDeliveryPlans: [],
	enRouteDeliveryPlans: [],
	scheduledDeliveryPlans: [],
	deliveredDeliveryPlans: [],
	totalCount: 0,
	page: 0,
	loadingDeliveryPlans: {}
}

const deliveryPlansReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_DELIVERY_PLANS_SUCCESS: {
			switch (payload.status) {
				case DELIVERY_PLAN_PROCESSING_STATUS:
					return {
						...state,
						processingDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case DELIVERY_PLAN_PENDING_STATUS:
					return {
						...state,
						pendingDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case DELIVERY_PLAN_APPROVED_STATUS:
					return {
						...state,
						approvedDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case DELIVERY_PLAN_SCHEDULED_STATUS:
					return {
						...state,
						scheduledDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case DELIVERY_PLAN_EN_ROUTE_STATUS:
					return {
						...state,
						enRouteDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case DELIVERY_PLAN_DELIVERED_STATUS:
					return {
						...state,
						deliveredDeliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				default:
					return {
						...state,
						deliveryPlans: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
			}
		}
		case FETCH_DELIVERY_PLAN_DETAILS_SUCCESS: {
			const newState = { ...state }
			const deliveryPlanDetail = payload.data
			newState.deliveryPlanDetails[deliveryPlanDetail.id] = deliveryPlanDetail
			newState.deliveryPlans = newState.deliveryPlans.map(deliveryPlan =>
				deliveryPlan.id === deliveryPlanDetail.id ?
					deliveryPlanDetail : deliveryPlan
			)
			switch (deliveryPlanDetail.status.toLowerCase()) {
				case DELIVERY_PLAN_PENDING_STATUS:
					newState.pendingDeliveryPlans = newState.pendingDeliveryPlans.map(deliveryPlan =>
						deliveryPlan.id === deliveryPlanDetail.id ?
							deliveryPlanDetail : deliveryPlan
					)
					break
				case DELIVERY_PLAN_APPROVED_STATUS:
					newState.approvedDeliveryPlans = newState.approvedDeliveryPlans.map(deliveryPlan =>
						deliveryPlan.id === deliveryPlanDetail.id ?
							deliveryPlanDetail : deliveryPlan
					)
					break
				case DELIVERY_PLAN_SCHEDULED_STATUS:
					newState.scheduledDeliveryPlans = newState.scheduledDeliveryPlans.map(deliveryPlan =>
						deliveryPlan.id === deliveryPlanDetail.id ?
							deliveryPlanDetail : deliveryPlan
					)
					break
				case DELIVERY_PLAN_EN_ROUTE_STATUS:
					newState.enRouteDeliveryPlans = newState.enRouteDeliveryPlans.map(deliveryPlan =>
						deliveryPlan.id === deliveryPlanDetail.id ?
							deliveryPlanDetail : deliveryPlan
					)
					break
				case DELIVERY_PLAN_DELIVERED_STATUS:
					newState.deliveredDeliveryPlans = newState.deliveredDeliveryPlans.map(deliveryPlan =>
						deliveryPlan.id === deliveryPlanDetail.id ?
							deliveryPlanDetail : deliveryPlan
					)
					break
				default:
					break
			}
			return newState
		}
		case FETCH_DELIVERY_PLAN_ITEM_DETAILS_SUCCESS: {
			const newState = { ...state }
			const deliveryPlanItemDetail = payload.data
			newState.deliveryPlanItemDetails[deliveryPlanItemDetail.id] = deliveryPlanItemDetail
			return newState
		}
		case UPDATE_VEHICLE_TYPE_SUCCESS:
		case ASSIGN_VEHICLE_SUCCESS:
		case ASSIGN_HELPER_SUCCESS:
		case UPDATE_DELIVERY_PLAN_ITEM_STATUS_SUCCESS:
		case ASSIGN_DRIVER_SUCCESS: {
			const newState = { ...state }
			if (newState.deliveryPlanDetails[payload.deliveryPlanId]) {
				const deliveryPlanItems = newState.deliveryPlanDetails[payload.deliveryPlanId].deliveryPlanItems
				if (deliveryPlanItems) {
					const newDeliveryPlanItems = deliveryPlanItems.map(deliveryPlanItem => deliveryPlanItem.id === payload.id ? payload : deliveryPlanItem)
					newState.deliveryPlanDetails[payload.deliveryPlanId].deliveryPlanItems = newDeliveryPlanItems
				}
			}
			let deliveryPlans = []
			switch (payload.deliveryPlan && payload.deliveryPlan.status.toLowerCase()) {
				case DELIVERY_PLAN_PROCESSING_STATUS:
					deliveryPlans = newState.processingDeliveryPlans
					break
				case DELIVERY_PLAN_PENDING_STATUS:
					deliveryPlans = newState.pendingDeliveryPlans
					break
				case DELIVERY_PLAN_APPROVED_STATUS:
					deliveryPlans = newState.approvedDeliveryPlans
					break
				case DELIVERY_PLAN_EN_ROUTE_STATUS:
					deliveryPlans = newState.enRouteDeliveryPlans
					break
				case DELIVERY_PLAN_SCHEDULED_STATUS:
					deliveryPlans = newState.scheduledDeliveryPlans
					break
				case DELIVERY_PLAN_DELIVERED_STATUS:
					deliveryPlans = newState.deliveredDeliveryPlans
					break
				default:
					deliveryPlans = []
					break
			}
			const updateDeliveryPlanItems = (deliveryPlans, payload) => {
				const deliveryPlan = deliveryPlans.find(deliveryPlan => deliveryPlan.id === payload.deliveryPlanId)
				if (deliveryPlan) {
					const deliveryPlanItems = deliveryPlan.deliveryPlanItems
					const newDeliveryPlanItems = deliveryPlanItems.map(deliveryPlanItem => deliveryPlanItem.id === payload.id ? payload : deliveryPlanItem)
					deliveryPlan.deliveryPlanItems = newDeliveryPlanItems
				}
			}
			updateDeliveryPlanItems(deliveryPlans, payload)
			updateDeliveryPlanItems(newState.deliveryPlans, payload)
			return newState
		}
		case UPDATE_DELIVERY_PLAN_SUCCESS: {
			const newState = { ...state }
			const deliveryPlan = newState.deliveryPlanDetails[payload.id]
			if (deliveryPlan && payload) {
				const newDeliveryPlan = Object.assign(deliveryPlan, payload)
				newState.deliveryPlanDetails[payload.id] = newDeliveryPlan
			}
			return newState
		}
		case UPDATE_DELIVERY_PLAN_SALES_ORDERS_SUCCESS: {
			const newState = { ...state }
			const newDeliveryPlans = newState.deliveryPlans.map(deliveryPlan => {
				if (deliveryPlan.id === payload.id) {
					const newDeliveryPlan = { ...payload, _count: { salesOrders: payload.salesOrders.length } }
					return newDeliveryPlan
				} else {
					return deliveryPlan
				}
			})
			newState.deliveryPlans = newDeliveryPlans
			return newState
		}
		case CREATE_DELIVERY_PLAN_SUCCESS: {
			const newState = { ...state }
			const index = newState.deliveryPlans.findIndex(deliveryPlan => deliveryPlan.id === payload.id)
			const newDeliveryPlan = { ...payload, _count: { salesOrders: payload.salesOrders.length } }
			if (index > -1) {
				newState.deliveryPlans[index] = newDeliveryPlan
			} else {
				newState.deliveryPlans = [newDeliveryPlan, ...newState.deliveryPlans]
			}
			return newState
		}
		case UPDATE_DELIVERY_PLAN_ROUTES: {
			const newState = { ...state }
			if (newState.deliveryPlanDetails[payload.id]) {
				newState.deliveryPlanDetails[payload.id] = payload
			}
			const newDeliveryPlans = newState.deliveryPlans.map(deliveryPlan => {
				if (deliveryPlan.id === payload.id) {
					return payload
				} else {
					return deliveryPlan
				}
			})
			newState.deliveryPlans = newDeliveryPlans

			return newState
		}
		case UPDATE_DELIVERY_PLAN_LOAD_STATE: {
			const newState = { ...state }
			newState.loadingDeliveryPlans[payload.id] = payload.loading
			return newState
		}
		default:
			return state
	}
}

export default deliveryPlansReducer
