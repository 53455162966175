
import '../styles/globals.css'
require('../styles/antd.less')
import initAuth from '../config/initAuth'
import initMaps from '../config/initMaps'
import initFirebase from '../config/initFirebase'
import { wrapper } from '../store'
import { useEffect, useState } from 'react'
import 'firebase/firestore'
import firebase from 'firebase/app'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPermissions, fetchUserProfile, setAuthUser, setCompanyId, setPlan, setRole } from '../store/auth/actions'
import { setUnreadCount, addNotification, setCount, updateNotification } from '../store/notifications/actions'
import { Companies, DeliveryPlans, Notifications } from '../services/api/firebase'
import { notification, message, Spin, Modal } from 'antd'
import axios from 'axios'
import FullPageLoader from '../components/full-page-loader'
import { LoadingOutlined } from '@ant-design/icons'
import { getNotificationArgs } from '../components/notifications-tray'
import { fetchDeliveryPlanDetails, fetchDeliveryPlans, updateDeliveryPlanLoadState } from '../store/delivery-plans/actions'
import useInterval from '../hooks/useInterval'
import { DELIVERY_PLAN_PENDING_STATUS } from './delivery-plans'
import { claimsRoles } from '../utils/constants'
import { Users } from '../services/api/users'

initAuth()
initMaps()
initFirebase()

const App = ({ Component, pageProps }) => {
	const dispatch = useDispatch()
	const { role, userProfile, authUser } = useSelector(state => state.authReducer)
	const { unreadNotificationsMap } = useSelector(state => state.notificationsReducer)
	const [showRefreshPrompt, setShowRefreshPrompt] = useState(false)

	useEffect(() => {
		fetchAuthUser()
	}, [])

	useEffect(() => {
		if (showRefreshPrompt) {
			message.info({
				content: <Message onClick={() => window.location.reload()} message={'A new version of the application has been deployed. Please refresh the application by clicking here.'} />,
				duration: 0
			})
		}
	}, [showRefreshPrompt])

	useEffect(() => {
		let unsubscribe
		let unsubscribeCompany
		if (authUser) {
			(async () => {
				const tokenResult = await authUser.getIdTokenResult()
				const companyId = tokenResult.claims.cid
				const plan = tokenResult.claims.plan
				unsubscribe = getDeliveryPlans(companyId)
				unsubscribeCompany = getCompany(companyId, plan)
			})()
		}
		return () => {
			if (unsubscribe) {
				const { unsubscribeDeliveryPlans, unsubscribeLoadingDeliveryPlans } = unsubscribe
				if (unsubscribeDeliveryPlans) {
					unsubscribeDeliveryPlans()
				}
				if (unsubscribeLoadingDeliveryPlans) {
					unsubscribeLoadingDeliveryPlans()
				}
			}
			if (unsubscribeCompany) {
				unsubscribeCompany()
			}
		}
	}, [authUser])

	useEffect(() => {
		let unsubscribe
		if (authUser) {
			const uid = authUser.uid
			unsubscribe = getUserNotifications(uid)
		}
		return () => {
			if (unsubscribe) {
				const { unsubscribeNotificationCount, unsubscribeNotifications } = unsubscribe
				if (unsubscribeNotificationCount) {
					unsubscribeNotificationCount()
				}
				if (unsubscribeNotifications) {
					unsubscribeNotifications()
				}
			}
		}
	}, [authUser])

	useInterval(async () => {
		try {
			const { buildId } = (await axios.get('/api/build-id')).data
			if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID) {
				// A new version of the application has been deployed. Prompt user to refresh.
				if (!showRefreshPrompt) {
					setShowRefreshPrompt(true)
				}
			}
		} catch (e) {
			console.error(e)
		}
	}, 300000)


	const isSSO = authUser => {
		return authUser.providerData.some(provider => provider.providerId === 'saml.bat-sso')
	}

	const fetchAuthUser = () => {
		firebase.auth().onIdTokenChanged(async authUser => {
			if (authUser) {
				if (isSSO(authUser)) {
					try {
						await Users.sso()
					} catch (e) {
						notification.error({
							message: 'Unable To Sign In Using SSO',
							description: e.message,
							placement: 'bottomLeft'
						})
						firebase.auth().signOut()
					}
				}
				const tokenResult = await authUser.getIdTokenResult()
				if (tokenResult.claims) {
					dispatch(setRole(tokenResult.claims.role))
					const plan = tokenResult.claims.plan
					const companyId = tokenResult.claims.cid
					dispatch(setPlan(plan))
					dispatch(setCompanyId(companyId))
					if (plan === 'expired') {
						Modal.error({
							title: 'Your Plan has Expired',
							content: 'Please check your subscription in order to continue using Nuport.'
						})
					}
				}
				dispatch(setAuthUser(authUser))
				dispatch(fetchUserProfile())
				dispatch(fetchPermissions())
			}
		})
	}

	const getUserNotifications = uid => {
		const unsubscribeNotificationCount = Notifications.listenToNotificationCount(uid, data => {
			dispatch(setUnreadCount(data.unreadCount ? data.unreadCount : 0))
			dispatch(setCount(data.count ? data.count : 0))
		})
		const unsubscribeNotifications = Notifications.listenToUnreadNotifications(
			uid,
			notification => {
				if (!unreadNotificationsMap[notification.id]) {
					showNotification(notification)
					dispatch(addNotification(notification))
				}
			},
			notification => {
				showNotification(notification)
				dispatch(updateNotification(notification))
			})
		return { unsubscribeNotificationCount, unsubscribeNotifications }
	}

	const getCompany = (cid, plan) => {
		if (!cid) return
		return Companies.listenToCompany(cid, company => {
			const companyDetails = company.companyDetails
			if (companyDetails && plan) {
				if (companyDetails.subscriptionPlan?.toLowerCase() !== plan) {
					dispatch(setPlan(companyDetails.subscriptionPlan.toLowerCase()))
				}
			}
		})
	}

	const getDeliveryPlans = cid => {
		if (!cid) return
		const unsubscribeDeliveryPlans = DeliveryPlans.listenToDeliveryPlans(cid, () => {
			dispatch(fetchDeliveryPlans(undefined, 0))
			dispatch(fetchDeliveryPlans(DELIVERY_PLAN_PENDING_STATUS, 0))
		})
		const unsubscribeLoadingDeliveryPlans = DeliveryPlans.listenToLoadingDeliveryPlans(
			cid,
			createdDeliveryPlan => {
				showDeliveryPlanLoadingNotification(createdDeliveryPlan)
				dispatch(updateDeliveryPlanLoadState(createdDeliveryPlan.id, true))
			},
			updatedDeliveryPlan => {
				showDeliveryPlanLoadingNotification(updatedDeliveryPlan)
				dispatch(updateDeliveryPlanLoadState(updatedDeliveryPlan.id, true))
			},
			removedDeliveryPlan => {
				notification.close(removedDeliveryPlan.id)
				dispatch(updateDeliveryPlanLoadState(removedDeliveryPlan.id, false))
				dispatch(fetchDeliveryPlanDetails(removedDeliveryPlan.id))
			}
		)
		return { unsubscribeDeliveryPlans, unsubscribeLoadingDeliveryPlans }
	}

	const showDeliveryPlanLoadingNotification = (deliveryPlan) => {
		notification.open({
			key: deliveryPlan.id,
			message: 'Processing Delivery Plan',
			description:
			<div style={{ display: 'flex' }}>
				<Spin
					style={{ marginRight: 12 }}
					indicator={<LoadingOutlined />}
				/>
				<p style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}><span style={{ color: '#278EA5' }}>DP-{deliveryPlan.shortId} </span>&nbsp;{' is currently being updated.'}</p>
			</div>,
			placement: 'bottomLeft',
			duration: 0
		})
	}

	const showNotification = (notificationData) => {
		notification.open(getNotificationArgs(notificationData))
	}

	return (
		role && role !== claimsRoles.ADMIN && !userProfile ?
			<FullPageLoader /> :
			<Component {...pageProps} />
	)
}

const Message = ({ message, onClick }) => {
	return (
		<div
			onClick={onClick}
			style={{ cursor: 'pointer', padding: '0 12px' }}
		>
			{message}
		</div>
	)
}

export default wrapper.withRedux(App)
