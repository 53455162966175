import { get, post, patch } from './index'

export const Vehicles = {
	index: ({
		page = 0,
		toDate,
		fromDate,
		searchTerm,
		statuses,
		unassigned,
		type
	} = {}) => {
		const config = {
			params: {
				page,
				toDate,
				fromDate,
				searchTerm,
				statuses,
				unassigned,
				type
			}
		}
		return get('/vehicles', config)
	},
	fetchVehicleTypes: () => {
		return get('/vehicles/types')
	},
	fetchTrackerTypes: () => {
		return get('/vehicles/tracker-types')
	},
	create: (data) => {
		return post('/vehicles', data)
	},
	update: (id, data) => {
		return patch(`/vehicles/${id}`, data)
	},
	single: (id) => get(`/vehicles/${id}`),
	getMetrics: () => get('/vehicles/metrics'),
	fetchVehicleTrips: (
		id,
		{
			page = 0,
			status,
			searchTerm,
			fromDate,
			toDate
		}
	) => {
		const config = {
			params: {
				page,
				status,
				searchTerm,
				fromDate,
				toDate
			}
		}
		return get(`/vehicles/${id}/trips`, config)
	}
}
