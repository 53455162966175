import { useState } from 'react'
import { Button, Divider, Modal, Space, Radio } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './userFilterModal.module.css'
import { paths } from '../../utils/constants'

const UserFilterModal = ({ visible, onCancel }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [disabled, setIsDisabled] = useState(undefined)
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const router = useRouter()

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || disabled !== undefined
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm,
			disabled,
			fromDate,
			toDate
		}
		setSearchParams(searchParams)
		router.push(`${paths.USERS}?view=list`)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setIsDisabled(false)
		clearSearchParams()
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Distributor Name'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<Divider />
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setIsDisabled(e.target.value)}
						value={disabled}
					>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={false}>Enable</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={true}>Disable</Radio>
						</div>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}> Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
			</div>
		</Modal>
	)
}

export default UserFilterModal
