import { Input } from 'antd'
import React from 'react'
import styles from './SearchInput.module.css'

const SearchInput = (props) => {
	return (
		<Input
			className={styles.searchInput}
			{...props}
			prefix={<SearchIcon />}
		/>
	)
}

const SearchIcon = () => {
	return (
		<img
			className={styles.searchIcon}
			src='/img/search.svg'
			alt='SearchIcon'
		/>
	)
}

export default SearchInput
