import { useState, useEffect } from 'react'
import { Button, Divider, Modal, Radio, Space } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import useSearchParams from '../../hooks/useSearchParams'
import {
	tabs
} from '../../pages/sales-orders'
import styles from './SalesOrderFilterModal.module.css'
import { paths } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import { convertEnumToString } from '../../utils'

export const OrderType = {
	SALES_ORDER: 'sales_order',
	STOCK_TRANSFER_ORDER: 'stock_transfer_order'
}

const SalesOrderFilterModal = ({ visible, onCancel }) => {
	const router = useRouter()
	const { companyDetails } = useSelector(state => state.authReducer)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [isStockTransfer, setIsStockTransfer] = useState(undefined)
	const [searchTerm, setSearchTerm] = useState('')
	const [isLoadingPickupLocations, setIsLoadingPickupLocations] = useState(false)
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [pickUpLocation, setPickUpLocation] = useState(null)
	const [status, setStatus] = useState(null)
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const orderStatusFlow = companyDetails?.orderStatusFlow

	useEffect(() => {
		if (visible) {
			fetchPickUpLocations()
		}
	}, [visible])

	const fetchPickUpLocations = async () => {
		setIsLoadingPickupLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setPickUpLocations(response.data.results)
		setIsLoadingPickupLocations(false)
	}

	const searchPickUpLocations = (value) => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || isStockTransfer !== undefined || pickUpLocation !== null
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}

		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (isStockTransfer !== undefined) {
			searchParams.isStockTransfer = isStockTransfer
		}
		if (pickUpLocation) {
			searchParams.pickUpLocationIds = [pickUpLocation.value]
		}
		setSearchParams(searchParams)
		if (status) {
			router.push(`${paths.SALES_ORDERS}?status=${status}`)
		} else {
			router.push(paths.SALES_ORDERS)
		}
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setIsStockTransfer(undefined)
		setStatus(null)
		setPickUpLocation(null)
		clearSearchParams()
	}

	const modifiedTabs = orderStatusFlow ? tabs.filter(tab => orderStatusFlow.statuses.includes(tab.status.toUpperCase())) : tabs

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
						disabled={!isFilterEnabled()}
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Order Number, Distributor Name'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setIsStockTransfer(e.target.value)}
						value={isStockTransfer}
					>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={false}>Sales Order</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={true}>Stock Transfer Order</Radio>
						</div>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex', flexWrap: 'wrap' }}
						onChange={e => setStatus(e.target.value)}
						value={status}
					>
						{
							modifiedTabs.map((tab) => {
								return (
									<Radio style={{ marginBottom: 8 }} key={tab.status} value={tab.status}>{tab.title}</Radio>
								)
							})
						}
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Order Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<Divider />
				<div className={styles.section}>
					<CustomSearchableSelect
						title={<span>Pick Up Location</span>}
						defaultOptions={pickUpLocations}
						isLoading={isLoadingPickupLocations}
						customLabel={(data) => data.internalId ? data.internalId : data.label ? data.label : convertEnumToString(data.type.toLowerCase())}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Select Loading Location'
						onChange={setPickUpLocation}
						onClear={() => setPickUpLocation(null)}
						value={pickUpLocation}
						unclipOptions={true}
						showSearch={true}
						searchTask={value => searchPickUpLocations(value)}
						popupContainer={() => document.querySelector(`.${styles.section}`)}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default SalesOrderFilterModal
