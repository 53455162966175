export const FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS = 'fetch_dashboard_general_analytics_statistics_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS = 'fetch_dashboard_general_order_rate_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS = 'fetch_dashboard_general_order_cycle_time_success'
export const FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS = 'fetch_dashboard_general_order_lead_time_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS = 'fetch_dashboard_sales_analytics_orders_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS = 'fetch_dashboard_sales_analytics_distributor_growth_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_division_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_item_sales_success'
export const FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS = 'fetch_dashboard_sales_analytics_distributor_sales_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_order_assigned_and_completed_ratio_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_total_and_collected_amount_ratio_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_delivery_partner_by_location_success'
export const FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS = 'fetch_dashboard_delivery_partner_analytics_distance_traveled_by_delivery_partner_success'
export const SCAN_ADHERENCE_SUMMARY_SUCCESS = 'SCAN_ADHERENCE_SUMMARY_SUCCESS'
export const SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS = 'SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS'
export const SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS = 'SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS'
