import { Button, notification, Modal, Collapse } from 'antd'
import { useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch } from 'react-redux'
import { Users } from '../../services/api/users'
import { assignDriverToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import { PlusOutlined } from '@ant-design/icons'
import CustomPhoneInput from '../phone-input'

const { Panel } = Collapse

const AssignDriverModal = ({ visible = false, onCancel, deliveryPlanItemId, onDriverAssigned }) => {
	const dispatch = useDispatch()
	const [isLoadingDrivers, setIsLoadingDrivers] = useState(false)
	const [isCreatingNewDriver, setIsCreatingNewDriver] = useState(false)
	const [isAssigningDriver, setIsAssigningDriver] = useState(false)
	const [driver, setDriver] = useState()
	const [drivers, setDrivers] = useState([])
	const [driverName, setDriverName] = useState('')
	const [driverPhone, setDriverPhone] = useState('+880')

	useEffect(() => {
		fetchDrivers()
	}, [])

	const fetchDrivers = async () => {
		setIsLoadingDrivers(true)
		const response = await Users.index({ role: 'driver' })
		setDrivers(response.data.results)
		setIsLoadingDrivers(false)
	}

	const searchUsers = value => {
		return Users.index({ searchTerm: value, role: 'driver' })
	}

	const createDriver = async () => {
		setIsCreatingNewDriver(true)
		try {
			const response = await Users.create({
				name: driverName,
				phone: driverPhone
			})
			const createdDriver = response.data
			setDrivers([createdDriver, ...drivers])
			setDriver({
				key: createdDriver.id,
				label: createdDriver.name,
				value: createdDriver.id
			})
			clearForm()
			notification.success({
				message: 'New Driver Created',
				description: 'New Driver successfully created!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Create New Driver',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreatingNewDriver(false)
		}
	}

	const assignDriver = async () => {
		setIsAssigningDriver(true)
		try {
			await dispatch(assignDriverToDeliveryPlanItem(deliveryPlanItemId, {
				driverId: driver.value
			}))
			if (onDriverAssigned) {
				onDriverAssigned(deliveryPlanItemId, driver.value)
			}
			onCancel()
			notification.success({
				message: 'Driver Assigned to Delivery Plan',
				description: 'Driver has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Driver to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningDriver(false)
		}
	}

	const clearForm = () => {
		setDriverName('')
		setDriverPhone('+880')
	}

	const isDriverInputValid = () => {
		if (!driverName) {
			return false
		}

		if (!driverPhone) {
			return false
		} else {
			if (!isValidPhoneNumber(driverPhone)) {
				return false
			}
		}
		return true
	}

	return (
		<Modal
			title='Assign Driver'
			visible={visible}
			onCancel={onCancel}
			onOk={assignDriver}
			okText='Assign Driver'
			okButtonProps={{
				disabled: !driver
			}}
			confirmLoading={isAssigningDriver}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<CustomSearchableSelect
					searchTask={searchUsers}
					defaultOptions={drivers}
					isLoading={isLoadingDrivers}
					labelIndex='name'
					valueIndex='id'
					placeholder='Select Driver'
					onChange={option => setDriver(option)}
					onClear={() => setDriver(null)}
					value={driver}
				/>
				<Collapse style={{ marginTop: 24 }}>
					<Panel header='Create New Driver'>
						<Input
							onChange={e => {
								let text = e.target.value.toLowerCase()
								text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
								setDriverName(text)
							}}
							value={driverName}
							title='Driver Name'
						/>
						<div style={{ marginTop: 24 }} />
						<CustomPhoneInput
							title='Phone Number'
							value={driverPhone}
							onChange={setDriverPhone}
						/>
						<Button
							onClick={createDriver}
							loading={isCreatingNewDriver}
							disabled={!isDriverInputValid()}
							type='primary'
							style={{ alignSelf: 'flex-start', marginTop: 24 }}
							icon={<PlusOutlined />}
						>
							Create
						</Button>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

export default AssignDriverModal
