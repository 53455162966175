import { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Divider, Modal, Row, Space } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import Select from '../../components/custom-select'
import { useDispatch, useSelector } from 'react-redux'
import useSearchParams from '../../hooks/useSearchParams'
import { fetchVehicleTypes } from '../../store/vehicles/actions'
import {
	VEHICLE_ACTIVE_STATUS,
	VEHICLE_INACTIVE_STATUS,
	VEHICLE_IN_MAINTENANCE_STATUS
} from '../../pages/fleet'
import styles from './VehicleFilterModal.module.css'
import { paths } from '../../utils/constants'

const VehicleFilterModal = ({ visible, onCancel }) => {
	const dispatch = useDispatch()
	const { vehicleTypes } = useSelector(state => state.vehiclesReducer)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [statuses, setStatuses] = useState([])
	const [type, setType] = useState()
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const router = useRouter()

	useEffect(() => {
		dispatch(fetchVehicleTypes())
	}, [])

	const statusOptions = [
		{ label: 'Active', value: VEHICLE_ACTIVE_STATUS },
		{ label: 'Inactive', value: VEHICLE_INACTIVE_STATUS },
		{ label: 'In Maintenance', value: VEHICLE_IN_MAINTENANCE_STATUS }
	]

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || type || statuses.length > 0
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}

		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (type) {
			searchParams.type = type
		}
		if (statuses.length > 0) {
			searchParams.statuses = statuses
		}

		setSearchParams(searchParams)
		router.push(`${paths.FLEET}?view=list`)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setStatuses([])
		setType()
		clearSearchParams()
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Vehicle ID, Brand, Model'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<Checkbox.Group value={[...statuses]} style={{ width: '100%' }} onChange={setStatuses}>
						<Row style={{ rowGap: '10px' }}>
							{
								statusOptions.map(statusOption => {
									return (
										<Col key={statusOption.value} span={8}>
											<Checkbox value={statusOption.value}>{statusOption.label}</Checkbox>
										</Col>
									)
								})
							}
						</Row>
					</Checkbox.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Registration Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className='section'>
					<h3 className={styles.label}>Type</h3>
					<Select
						style={{ width: '100%' }}
						onChange={option => setType(option)}
						value={type}
						placeholder='Select Vehicle Type'
						options={vehicleTypes}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default VehicleFilterModal
