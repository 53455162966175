import React, { useEffect, useState } from 'react'
import { isNotEmpty } from '../../utils'
import styles from './FullPageLoader.module.css'

const FullPageLoader = () => {
	const [logoUrl, setLogoUrl] = useState()

	useEffect(() => {
		const logoUrl = localStorage.getItem('logoUrl')
		setLogoUrl(logoUrl)
	}, [])

	return (
		<div className={styles.container}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
				{
					isNotEmpty(logoUrl) ?
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<img className={styles.customLogo} src={logoUrl} alt='Logo' />
							<div style={{ marginTop: 24, marginBottom: 8, color: 'black' }}>Powered By</div>
							<img className={styles.logo} style={{ width: 120 }} src='/img/logo.svg' alt='Nuport Logo' />
						</div> :
						<img className={styles.logo} src='/img/logo.svg' alt='Nuport Logo' />
				}
				<div style={{ width: 200 }}>
					<div className={styles.requestProgress}>
						<div className={styles.bar} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default FullPageLoader
