import { HYDRATE } from 'next-redux-wrapper'
import { SET_SEARCH_PARAMS } from './action-types'

const initialState = {
	searchParams: {}
}

const commonReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case SET_SEARCH_PARAMS: {
			return { ...state, searchParams: { ...payload } }
		}
		default:
			return state
	}
}

export default commonReducer
