import { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import CustomCascader from '../../components/custom-cascader'
import styles from './ProductFilterModal.module.css'
import { useSelector } from 'react-redux'

const ProductFilterModal = ({ visible, onCancel }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const { categories } = useSelector(state => state.productsReducer)
	const [categoryOptions, setCategoryOptions] = useState()
	const [selectedCategories, setSelectedCategories] = useState([])

	useEffect(() => {
		createCategoryOptions()
	}, [categories])

	const onApply = async () => {
		const [category, subCategory1, subCategory2, subCategory3, subCategory4, subCategory5] = selectedCategories
		const searchParams = {
			searchTerm,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setSelectedCategories([])
		clearSearchParams()
	}

	const createCategoryOptions = () => {
		const options = []
		const createOptions = (categories, options) => {
			Object.keys(categories).forEach(categoryKey => {
				const option = {
					value: categoryKey,
					label: categories[categoryKey].label,
					children: []
				}
				options.push(option)
				if (categories[categoryKey].subCategories) {
					createOptions(categories[categoryKey].subCategories, option.children)
				}
			})
		}
		createOptions(categories, options)
		setCategoryOptions(options)
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={false}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Name or SKU'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<h3 className={styles.label}>Categories</h3>
					<CustomCascader
						allowClear={false}
						placeholder='Select a category and/or sub-categories...'
						changeOnSelect={true}
						options={categoryOptions}
						value={selectedCategories}
						onChange={setSelectedCategories}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ProductFilterModal
