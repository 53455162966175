import { PurchaseOrders } from '../../services/api/purchase-orders'
import { ADD_PURCHASE_ORDERS_SUCCESS, FETCH_PURCHASE_ORDERS_SUCCESS, FETCH_PURCHASE_ORDER_DETAILS_SUCCESS, UPDATE_PURCHASE_ORDER_SUCCESS } from './action-types'

export function fetchPurchaseOrders (
	{
		status,
		page,
		searchTerm,
		fromDate,
		toDate
	} = {}
) {
	return async (dispatch) => {
		const response = await PurchaseOrders.index({
			status,
			page,
			searchTerm,
			fromDate,
			toDate
		})
		dispatch({ type: FETCH_PURCHASE_ORDERS_SUCCESS, payload: { data: response.data, status } })
	}
}

export function addPurchaseOrder (params) {
	return async (dispatch) => {
		const response = await PurchaseOrders.create(params)
		dispatch({ type: ADD_PURCHASE_ORDERS_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchPurchaseOrderDetails (id) {
	return async (dispatch) => {
		const response = await PurchaseOrders.single(id)
		dispatch({ type: FETCH_PURCHASE_ORDER_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updatePurchaseOrder (id, params) {
	return async (dispatch) => {
		const response = await PurchaseOrders.update(id, params)
		dispatch({ type: UPDATE_PURCHASE_ORDER_SUCCESS, payload: response.data })
	}
}
