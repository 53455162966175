import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'

const UploadFleetModal = ({ visible, onCancel, onComplete }) => {
	return (
		<UploadCsvModal
			title='Upload Fleet'
			type='fleet'
			fields={[
				{ label: 'ID', key: 'id' },
				{ label: 'Model', key: 'model' },
				{ label: 'Brand', key: 'brand' },
				{ label: 'Type', key: 'type' },
				{ label: 'Year', key: 'year' },
				{ label: 'Plate Number', key: 'plateNumber' },
				{ label: 'Tracker\'s Manufacturer', key: 'trackerManufacturer' },
				{ label: 'Tracker\'s Model', key: 'trackerModel' },
				{ label: 'Tracker\'s IMEI', key: 'trackerImei' },
				{ label: 'Tracker\'s SIM Card Number', key: 'trackerSimCardNumber' },
				{ label: 'Internal ID', key: 'internalId' }
			]}
			firstStepPrompt='Download the CSV file containing all the data related to your fleet.'
			secondStepPrompt='Edit the CSV file by adding new rows and editing existing rows. Rows without a value in the ID column will be created while rows with an ID will update the vehicle.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadVehiclesCsv}
			uploadTask={Upload.uploadVehiclesCsv}
		/>
	)
}

export default UploadFleetModal
