import { createWrapper } from 'next-redux-wrapper'
import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { distributorsReducer } from './distributors'
import { productsReducer } from './products'
import { salesOrdersReducer } from './sales-orders'
import { purchaseOrdersReducer } from './purchase-orders'
import { notificationsReducer } from './notifications'
import { deliveryPlansReducer } from './delivery-plans'
import { usersReducer } from './users'
import { companiesReducer } from './companies'
import { dashboardReducer } from './dashboard'
import { authReducer } from './auth'
import { commonReducer } from './common'
import { reportsReducer } from './report'
import { vehiclesReducer } from './vehicles'
import { geofencesReducer } from './geofences'
import { vendorsReducer } from './vendors'
import { locationsReducer } from './locations'
import { permissionsReducer } from './permissions'
import { SIGN_OUT } from './auth/action-types'

const appReducer = combineReducers({
	distributorsReducer,
	productsReducer,
	salesOrdersReducer,
	purchaseOrdersReducer,
	notificationsReducer,
	deliveryPlansReducer,
	usersReducer,
	companiesReducer,
	dashboardReducer,
	authReducer,
	commonReducer,
	vehiclesReducer,
	reportsReducer,
	geofencesReducer,
	vendorsReducer,
	locationsReducer,
	permissionsReducer
})

const rootReducer = (state, action) => {
	if (action.type === SIGN_OUT) {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

const makeStore = (preloadState) => {
	return createStore(
		rootReducer,
		preloadState,
		applyMiddleware(thunkMiddleware)
	)
}

export const wrapper = createWrapper(makeStore)
