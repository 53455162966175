import { Form, Input, Modal, Select, notification } from 'antd'
import { useEffect, useState } from 'react'
import PlacesAutocomplete from '../places-autocomplete'
import Map from '../map'
import styles from './LocationModal.module.css'
import { Locations } from '../../services/api/locations'
import { convertEnumToString } from '../../utils'

export const LocationTypes = {
	GENERAL:'GENERAL',
	LOADING: 'LOADING',
	UNLOADING: 'UNLOADING',
	BILLING: 'BILLING',
	SHIPPING: 'SHIPPING',
	HEADQUARTERS: 'HEADQUARTERS',
	WAREHOUSE: 'WAREHOUSE',
	PICKUP: 'PICKUP',
	FACTORY: 'FACTORY',
	VIRTUAL_WAREHOUSE: 'VIRTUAL_WAREHOUSE'
}

export const WarehouseDescriptions = {
	CENTRAL_WAREHOUSE: 'central_warehouse',
	FINISHED_GOODS_WAREHOUSE: 'finished_goods_warehouse',
	RETURN_WAREHOUSE: 'return_warehouse',
	DELIVERY_PIPELINE: 'delivery_pipeline'
}

const LocationModal = ({
	create = true,
	disableLabel = false,
	visible,
	onCancel,
	initialLocation,
	showInitialLocations = true,
	country,
	onLocationAdded,
	onLocationUpdated,
	editableLocation,
	selectableTypes = [],
	title,
	okText
}) => {
	const [location, setLocation] = useState(initialLocation)
	const [locationOptions, setLocationOptions] = useState([])
	const [center, setCenter] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [locationType, setLocationType] = useState(LocationTypes.GENERAL)
	const [locationDescription, setLocationDescription] = useState()
	const [label, setLabel] = useState('')
	const [internalId, setInternalId] = useState('')
	const [isWritingLocation, setIsWritingLocation] = useState(false)

	useEffect(() => {
		if (visible) {
			initialize()
		}
	}, [visible])

	const initialize = async () => {
		if (editableLocation) {
			setLocationType(editableLocation.type)
			setLabel(editableLocation.label)
			setInternalId(editableLocation.internalId)
			setLocationDescription(editableLocation.description)
			setCenter({ lat: +editableLocation.latitude, lng: +editableLocation.longitude })
			setLocation({ value: editableLocation.id, label: editableLocation.address })
		} else {
			if (showInitialLocations) {
				setIsLoadingLocations(true)
				const response = await Locations.index({ types: selectableTypes })
				const results = response.data.results
				const locationOptions = results.map(result => {
					return {
						label:
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{result.address}
							{
								result.label && <div style={{ fontWeight: 'bold' }}>{result.label}</div>
							}
							<div style={{ fontWeight: 'bold', fontSize: 11 }}>{convertEnumToString(result.type)}</div>
						</div>,
						value: result.id,
						location: result
					}
				})
				setLocationOptions(locationOptions)
				setIsLoadingLocations(false)
			}
			if (selectableTypes.length > 0) {
				setLocationType(selectableTypes[0])
			}
		}
	}

	const onLocationSelected = location => {
		if (editableLocation) {
			setLocation({ value: location.placeId, label: location.address })
		} else {
			setLocation(location)
		}
		setCenter({ lat: location.lat, lng: location.lng })
	}

	const onOk = async () => {
		if (editableLocation) {
			await onUpdate()
		} else {
			await onCreateOrSelect()
		}
	}

	const onUpdate = async () => {
		const data = {
			label: label,
			description: locationDescription,
			internalId: internalId,
			address: location.label
		}
		try {
			setIsWritingLocation(true)
			const response = await Locations.update(editableLocation.id, data)
			const updatedLocation = response.data
			notification.success({
				message: 'Location Updated',
				description: <div>Location with address <b>{label ? label : editableLocation.address}</b> has been updated.</div>,
				placement: 'bottomLeft'
			})
			if (onLocationUpdated) {
				onLocationUpdated(updatedLocation)
			}
			if (onCancel) {
				onCancel()
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Update Location',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsWritingLocation(false)
		}
	}

	const onCreateOrSelect = async () => {
		const data = {
			address: location.address,
			latitude: location.lat,
			longitude: location.lng,
			label: label,
			internalId: internalId,
			type: locationType,
			description: locationDescription,
			id: location.id
		}
		if (create && !location.id) {
			try {
				setIsWritingLocation(true)
				const response = await Locations.create(data)
				const addedLocation = response.data
				setLocationOptions([
					{
						label: addedLocation.address,
						value: addedLocation.id,
						location: addedLocation
					},
					...locationOptions
				])
				notification.success({
					message: 'New Location Added',
					description: <div>Location with address <b>{response.data.address}</b> has been added.</div>,
					placement: 'bottomLeft'
				})
				if (onLocationAdded) {
					onLocationAdded(addedLocation)
				}
				if (onCancel) {
					onCancel()
				}
			} catch (e) {
				notification.error({
					message: 'Unable to Add New Location',
					description: e.message,
					placement: 'bottomLeft'
				})
			} finally {
				setIsWritingLocation(false)
			}
		} else {
			// Do not create location. Instead, pass on the data for further processing.
			if (onLocationAdded) {
				onLocationAdded(data)
			}
			if (onCancel) {
				onCancel()
			}
		}
	}

	const getLocationTitle = () => {
		if (editableLocation) {
			return 'Edit Location'
		} else {
			if (selectableTypes.length === 1 && selectableTypes[0] !== LocationTypes.GENERAL) {
				const selectableType = selectableTypes[0]
				return `Add New ${convertEnumToString(selectableType.toLowerCase())} Location`
			} else {
				return 'Add New Location'
			}
		}
	}

	const getLocation = () => {
		if (editableLocation) {
			return location ? location : null
		} else {
			return location ? { value: location.placeId, label: location.address } : null
		}
	}

	const isValid = () => {
		if (locationType === LocationTypes.WAREHOUSE) {
			if (!label || label.trim().length === 0) {
				return false
			}
		}
		if (!editableLocation) {
			if (!location) {
				return false
			}
		}
		return true
	}

	return (
		<Modal
			title={title ? title : getLocationTitle()}
			visible={visible}
			onCancel={onCancel}
			onOk={onOk}
			okButtonProps={{
				disabled: !isValid(),
				loading: isWritingLocation
			}}
			okText={okText ? okText : editableLocation ? 'Update' : 'Add'}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<PlacesAutocomplete
					style={{ width: '100%' }}
					country={country}
					onClear={() => setLocation(null)}
					placeholder='Address'
					value={getLocation()}
					initialOptions={locationOptions}
					loading={isLoadingLocations}
					disabled={isLoadingLocations}
					onLocationSelected={onLocationSelected}
				/>
				<Form layout='vertical'>
					{
						disableLabel ?
							<div style={{ marginTop: 12 }} /> :
							<Form.Item
								style={{ marginTop: 12 }}
								label={locationType === LocationTypes.WAREHOUSE ?
									<div style={{ fontSize: '12px' }}>Label</div> :
									<div style={{ fontSize: '12px' }}>Label <i>(Optional)</i></div>}
							>
								<Input
									placeholder='Label'
									value={label}
									onChange={e => setLabel(e.target.value)}
								/>
							</Form.Item>
					}
					<Form.Item
						style={{ marginTop: 12 }}
						label={<div style={{ fontSize: '12px' }}>ID <i>(Optional)</i></div>}
					>
						<Input
							placeholder='ID'
							value={internalId}
							onChange={e => setInternalId(e.target.value)}
						/>
					</Form.Item>
					{
						selectableTypes.length > 0 &&
						<Form.Item
							label={<div style={{ fontSize: '12px' }}>Type</div>}
						>
							<Select
								options={selectableTypes.map(type => ({
									label: type,
									value: type
								}))}
								placeholder='Select Type'
								defaultValue={selectableTypes[0]}
								onChange={setLocationType}
								value={locationType}
							/>
						</Form.Item>
					}
					{
						selectableTypes.length === 0 &&
						<Form.Item
							label={<div style={{ fontSize: '12px' }}>Type</div>}
						>
							<Select
								options={Object.keys(LocationTypes).map(type => ({
									label: convertEnumToString(type),
									value: type
								}))}
								placeholder='Select Type'
								onChange={setLocationType}
								value={locationType}
								disabled={!!editableLocation}
							/>
						</Form.Item>
					}
					{
						locationType === LocationTypes.WAREHOUSE &&
						<Form.Item
							label={<div style={{ fontSize: '12px' }}>Warehouse Type</div>}
						>
							<Select
								options={Object.keys(WarehouseDescriptions).map(type => ({
									label: convertEnumToString(type.toLowerCase()),
									value: type
								}))}
								placeholder='Select Warehouse Type'
								onChange={setLocationDescription}
								value={locationDescription}
							/>
						</Form.Item>
					}
				</Form>
				<div style={{ marginTop: 12 }}>
					<Map
						containerStyle={{
							width: '100%',
							height: '250px'
						}}
						center={center}
					>
						{
							center ?
								<LocationMarker
									lat={center.lat}
									lng={center.lng}
								/> : null
						}
					</Map>
				</div>
			</div>
		</Modal>
	)
}

export default LocationModal

const LocationMarker = () => {
	return (
		<div className={styles.locationMarker} />
	)
}
