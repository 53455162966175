import { Button, Dropdown, Empty, Menu } from 'antd'
import moment from 'moment'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../../components/page'
import useSearchParams from '../../hooks/useSearchParams'
import { fetchPurchaseOrders } from '../../store/purchase-orders/actions'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import { paths } from '../../utils/constants'
import { getShortId } from '../../utils/purchase-orders'
import styles from './purchase-orders.module.css'
import Table from '../../components/table'
import { UploadOutlined } from '@ant-design/icons'
import UploadPurchaseOrdersModal from '../../components/upload-purchase-orders-modal'
import { LocationTypes } from '../../components/location-modal'

export const PURCHASE_ORDER_REQUESTED_STATUS = 'requested'
export const PURCHASE_ORDER_APPROVED_STATUS = 'approved'
export const PURCHASE_ORDER_RECEIVED_STATUS = 'received'

const tabs = [
	{
		title: 'Requested',
		status: PURCHASE_ORDER_REQUESTED_STATUS,
		path: `${paths.PURCHASE_ORDERS}?status=${PURCHASE_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'Approved',
		status: PURCHASE_ORDER_APPROVED_STATUS,
		path: `${paths.PURCHASE_ORDERS}?status=${PURCHASE_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'Received',
		status: PURCHASE_ORDER_RECEIVED_STATUS,
		path: `${paths.PURCHASE_ORDERS}?status=${PURCHASE_ORDER_RECEIVED_STATUS}`
	}
]

const purchaseOrderColumns = [
	{
		title: 'Date',
		key: 'purchaseDate',
		render: purchaseOrder => {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{moment(purchaseOrder.purchaseDate).format('MMM D, YYYY')}
				</div>
			)
		}
	},
	{
		title: 'Order #',
		key: 'purchaseOrderID',
		render: purchaseOrder => {
			return (
				<Link href={`${paths.PURCHASE_ORDERS}/${purchaseOrder.id}`}>
					<a className={styles.link}>{getShortId(purchaseOrder)}</a>
				</Link>
			)
		}
	},
	{
		title: 'Vendor',
		key: 'vendor',
		render: purchaseOrder => {
			return purchaseOrder.vendor && `${purchaseOrder.vendor.name}`
		}
	},
	{
		title: 'Location',
		key: 'location',
		render: purchaseOrder => {
			return (
				purchaseOrder.location &&
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{
							!purchaseOrder.distributor &&
							<div className={styles.badge}>
								{convertEnumToString(purchaseOrder.location.type.toLowerCase())}
							</div>
						}
						{
							[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(purchaseOrder.location.type) ?
								<Link href={`${paths.WAREHOUSES}/${purchaseOrder.location.id}`}>
									<a>
										{purchaseOrder.location.label}
										<div className={styles.location}>
											{purchaseOrder.location.address}
										</div>
									</a>
								</Link> :
								<div>
									{purchaseOrder.location.label}
									<div className={styles.location}>
										{purchaseOrder.location.address}
									</div>
								</div>
						}
					</div>
			)
		}
	},
	{
		title: 'Purchase Order Amount',
		key: 'salesAmount',
		render: purchaseOrder => {
			return `${getFormattedCurrency(purchaseOrder.totalAmount)}`
		}
	}
]

const PurchaseOrdersPage = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { permissions } = useSelector(state => state.authReducer)
	const { status } = router.query
	const {
		totalCount,
		page,
		requestedPurchaseOrders,
		approvedPurchaseOrders,
		receivedPurchaseOrders
	} = useSelector(state => state.purchaseOrdersReducer)
	const [currentStatus, setCurrentStatus] = useState(PURCHASE_ORDER_REQUESTED_STATUS)
	const { searchParams } = useSearchParams()
	const [purchaseOrders, setPurchaseOrders] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isUploadPurchaseOrdersModalVisible, setIsUploadPurchaseOrdersModalVisible] = useState(false)

	useEffect(() => {
		if (!permissions.viewPurchaseOrders) {
			return
		}
		let purchaseOrderStatus = PURCHASE_ORDER_REQUESTED_STATUS
		switch (status) {
			case PURCHASE_ORDER_REQUESTED_STATUS:
				purchaseOrderStatus = PURCHASE_ORDER_REQUESTED_STATUS
				break
			case PURCHASE_ORDER_APPROVED_STATUS:
				purchaseOrderStatus = PURCHASE_ORDER_APPROVED_STATUS
				break
			case PURCHASE_ORDER_RECEIVED_STATUS:
				purchaseOrderStatus = PURCHASE_ORDER_RECEIVED_STATUS
				break
			default:
				purchaseOrderStatus = PURCHASE_ORDER_REQUESTED_STATUS
				break
		}
		setCurrentStatus(purchaseOrderStatus)
		getPurchaseOrders({
			status: purchaseOrderStatus,
			page: 0,
			...searchParams
		})
	}, [status, searchParams])

	useEffect(() => {
		let purchaseOrders = requestedPurchaseOrders
		switch (status) {
			case PURCHASE_ORDER_REQUESTED_STATUS:
				purchaseOrders = requestedPurchaseOrders
				break
			case PURCHASE_ORDER_APPROVED_STATUS:
				purchaseOrders = approvedPurchaseOrders
				break
			case PURCHASE_ORDER_RECEIVED_STATUS:
				purchaseOrders = receivedPurchaseOrders
				break
			default:
				purchaseOrders = requestedPurchaseOrders
				break
		}
		setPurchaseOrders(purchaseOrders)
	}, [
		requestedPurchaseOrders,
		approvedPurchaseOrders,
		receivedPurchaseOrders
	])

	const getPurchaseOrders = async ({ status, page }) => {
		setIsLoading(true)
		await dispatch(fetchPurchaseOrders({
			status,
			page,
			...searchParams
		}))
		setIsLoading(false)
	}

	const onPageChanged = async (page) => {
		await getPurchaseOrders({
			status: currentStatus,
			page: page - 1,
			...searchParams
		})
	}

	return (
		<Page>
			<div className={styles.container}>
				<div className={styles.contentHeader}>
					<div style={{ display: 'flex' }}>
						<div className={styles.tabContainer}>
							{
								tabs.map(tab => {
									return (
										<div
											onClick={() => router.push(tab.path)}
											className={currentStatus === tab.status ? styles.tabSelected : styles.tab} key={tab.path}
										>
											<div className={tab.title}>{tab.title}</div>
											<div className={currentStatus === tab.status ? styles.indicator : styles.indicatorGone} />
										</div>
									)
								})
							}
						</div>
						<div className={styles.contentHeaderContainer}>
							<Dropdown overlay={() => {
								return (
									<Menu>
										<Menu.Item
											icon={<UploadOutlined />}
											onClick={() => setIsUploadPurchaseOrdersModalVisible(true)}
											key='2'
											disabled={!permissions.uploadPurchaseOrders}
										>
											Upload Purchase Orders
										</Menu.Item>
									</Menu>
								)
							}} trigger={['click']}
							>
								<Button
									style={{ margin: '0 16px' }}
									shape='round'
									size='large'
									loading={false}
									onClick={() => {}}
								>
									Action
								</Button>
							</Dropdown>
							<Button
								type='primary' shape='round' size='large' className={styles.actionButton}
								href={'/add-purchase-order'}
								disabled={!permissions.addPurchaseOrders}
							>
								Create Purchase Order
							</Button>
						</div>
					</div>
				</div>
				<div className={styles.content}>
					{
						isLoading || purchaseOrders.length > 0 ?
							<Table
								refresh={() => onPageChanged(page)}
								loading={isLoading}
								columns={purchaseOrderColumns}
								dataSource={purchaseOrders}
								pagination={{
									pageSize: 50,
									total: totalCount,
									position: ['topLeft'],
									onChange: onPageChanged,
									showSizeChanger: false,
									current: page + 1
								}}
								scroll={{ y: 'calc(100vh - 340px)' }}
								rowKey='id'
							/> :
							<Empty
								description='No purchase orders. Get started by creating one!'
								imageStyle={{ height: '450px', marginBottom: '24px' }}
								style={{ marginTop: '128px' }}
							/>
					}
				</div>
			</div>
			{
				isUploadPurchaseOrdersModalVisible &&
					<UploadPurchaseOrdersModal
						visible={isUploadPurchaseOrdersModalVisible}
						onCancel={() => setIsUploadPurchaseOrdersModalVisible(false)}
						onComplete={() => {
							getPurchaseOrders({ status: currentStatus, page: 0 })
						}}
					/>
			}
		</Page>
	)
}

export default PurchaseOrdersPage
