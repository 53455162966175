import { HYDRATE } from 'next-redux-wrapper'
import { UPDATE_COMPANY_DETAILS_SUCCESS, UPDATE_PREFERRED_VEHICLES_SUCCESS } from '../companies/action-types'
import {
	SET_AUTH_USER,
	FETCH_USER_PROFILE_SUCCESS,
	SET_ROLE,
	SET_PLAN,
	FETCH_PERMISSIONS_SUCCESS,
	SET_COMPANY_ID
} from './action-types'

const initialState = {
	authUser: null,
	userProfile: null,
	role: null,
	plan: null,
	companyDetails: null,
	companyId: null,
	permissions: {}
}

const authReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case SET_AUTH_USER: {
			return {
				...state,
				authUser: payload
			}
		}
		case FETCH_PERMISSIONS_SUCCESS: {
			return {
				...state,
				permissions: payload
			}
		}
		case FETCH_USER_PROFILE_SUCCESS: {
			const newState = { ...state }
			newState.userProfile = payload
			const company = payload.company
			if (company && company.companyDetails) {
				newState.companyDetails = company.companyDetails
			}
			return newState
		}
		case UPDATE_PREFERRED_VEHICLES_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails = payload.data
			return newState
		}
		case SET_ROLE: {
			return {
				...state,
				role: payload
			}
		}
		case SET_COMPANY_ID: {
			return {
				...state,
				companyId: payload
			}
		}
		case SET_PLAN: {
			return {
				...state,
				plan: payload
			}
		}
		case UPDATE_COMPANY_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.companyDetails = payload.data
			const userProfile = newState.userProfile
			if (userProfile && userProfile.company) {
				userProfile.company.companyDetails = payload.data
			}
			return newState
		}
		default:
			return state
	}
}

export default authReducer
