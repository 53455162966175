import { get, post, patch, destroy } from './index'

export const Vendors = {
	index: ({
		page = 0,
		disabled,
		searchTerm,
		fromDate,
		toDate
	} = {}) =>
		get('/vendors', {
			params: {
				searchTerm,
				page,
				disabled,
				fromDate,
				toDate
			}
		}),
	single: (id) =>
		get(`/vendors/${id}`),
	create: (params) =>
		post('/vendors', params),
	update: (id, params) =>
		patch(`/vendors/${id}`, params),
	remove: (id) =>
		destroy(`/vendors/${id}`),
	metrics: () =>
		get('/vendors/metrics')
}
