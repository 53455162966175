import {
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS,
	SCAN_ADHERENCE_SUMMARY_SUCCESS,
	SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS,
	SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS
} from './action-types'

const initialState = {
	generalAnalyticsStatistics: {
		orders: {
			total: {
				value: 0
			},
			new: {
				value: 0
			},
			open: {
				value: 0
			},
			cancelled: {
				value: 0
			}
		},
		sales: {
			total: {
				value: 0
			},
			new: {
				value: 0
			},
			open: {
				value: 0
			},
			cancelled: {
				value: 0
			}
		}
	},
	generalAnalyticsOrderRate: {
		todayOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		},
		sevenDayOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		},
		oneMonthOrderRate: {
			open: 0,
			cancelled: 0,
			approved: 0,
			flagged: 0,
			processing: 0,
			completed: 0
		}
	},
	generalAnalyticsOrderCycleTime: {
		orderCycleChart: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		},
		orderCycleChartWeekly: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		},
		orderCycleChartMonthly: {
			requestedSalesHours: 0,
			flaggedSalesHours: 0,
			processingSalesHours: 0,
			approvedSalesHours: 0
		}
	},
	generalAnalyticsOrderLeadTime: {
		salesOrderLeadTimeWeekly: [],
		salesOrderLeadTimeMonthly: []
	},
	salesAnalyticsOrders: {
		salesOrderCountTrend: [],
		salesOrderCountTrendMonthly: [],
		salesOrderCountTrendWeekly: []
	},
	salesAnalyticsSales: {
		salesOrderAmountTrend: [],
		salesOrderAmountTrendMonthly: [],
		salesOrderAmountTrendWeekly: []
	},
	salesAnalyticsDistributorGrowth: {
		distributorGrowthAmountDaily: [],
		distributorGrowthAmountMonthly: [],
		distributorGrowthAmountWeekly: []
	},
	salesAnalyticsDivisionSales: {
		divisionWiseSalesDaily: [],
		divisionWiseSalesMonthly: [],
		divisionWiseSalesWeekly: []
	},
	salesAnalyticsItemSales: {
		itemWiseSalesDaily: [],
		itemWiseSalesWeekly: [],
		itemWiseSalesYearly: []
	},
	salesAnalyticsDistributorSales: {
		distributorWiseSalesDaily: [],
		distributorWiseSalesWeekly: [],
		distributorWiseSalesYearly: []
	},
	ordersAssignedAndCompletedRatio: {
		ordersAssignedAndCompletedRatioDaily: [],
		ordersAssignedAndCompletedRatioWeekly: [],
		ordersAssignedAndCompletedRatioMonthly: []
	},
	driverWiseCollectedAmountRatio: {
		driverWiseCashCollectedAmountRatioDaily: [],
		driverWiseCashCollectedAmountRatioWeekly: [],
		driverWiseCashCollectedAmountRatioMonthly: []
	},
	deliveryPartnerByLocation: {
		deliveryPartnerByLocationDaily: [],
		deliveryPartnerByLocationWeekly: [],
		deliveryPartnerByLocationMonthly: []
	},
	distanceTraveledByDeliveryPartner: {
		distanceTraveledByDeliveryPartnerDaily: [],
		distanceTraveledByDeliveryPartnerWeekly: [],
		distanceTraveledByDeliveryPartnerMonthly: []
	},
	scanAdherenceSummary: {
		totalOrders: 0,
		totalSTOs: 0,
		totalSOs: 0,
		deliveredOrdersMissed: 0,
		receivedOrdersMissed: 0,
		totalItemsMissed: 0
	},
	warehouseWiseScanMissed: [],
	totalScanMissedInAllWarehouses: 0,
	nationwideScanMissed: []
}

const dashboardReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS: {
			return {
				...state,
				generalAnalyticsStatistics: payload
			}
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderRate.oneMonthOrderRate = payload.data.orderRate
			} else if (interval === 'weekly') {
				newState.generalAnalyticsOrderRate.sevenDayOrderRate = payload.data.orderRate
			} else {
				newState.generalAnalyticsOrderRate.todayOrderRate = payload.data.orderRate
			}
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderCycleTime.orderCycleChartMonthly = payload.data.orderCycleTime
			} else if (interval === 'weekly') {
				newState.generalAnalyticsOrderCycleTime.orderCycleChartWeekly = payload.data.orderCycleTime
			} else {
				newState.generalAnalyticsOrderCycleTime.orderCycleChart = payload.data.orderCycleTime
			}
			return newState
		}
		case FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.generalAnalyticsOrderLeadTime.salesOrderLeadTimeMonthly = payload.data.orderLeadTime
			} else {
				newState.generalAnalyticsOrderLeadTime.salesOrderLeadTimeWeekly = payload.data.orderLeadTime
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsOrders.salesOrderCountTrendMonthly = payload.data.orderCountTrend
			} else if (interval === 'weekly') {
				newState.salesAnalyticsOrders.salesOrderCountTrendWeekly = payload.data.orderCountTrend
			} else {
				newState.salesAnalyticsOrders.salesOrderCountTrend = payload.data.orderCountTrend
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsSales.salesOrderAmountTrendMonthly = payload.data.orderAmountTrend
			} else if (interval === 'weekly') {
				newState.salesAnalyticsSales.salesOrderAmountTrendWeekly = payload.data.orderAmountTrend
			} else {
				newState.salesAnalyticsSales.salesOrderAmountTrend = payload.data.orderAmountTrend
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountMonthly = payload.data.distributorGrowth
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountWeekly = payload.data.distributorGrowth
			} else {
				newState.salesAnalyticsDistributorGrowth.distributorGrowthAmountDaily = payload.data.distributorGrowth
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesMonthly = payload.data.divisionSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesWeekly = payload.data.divisionSales
			} else {
				newState.salesAnalyticsDivisionSales.divisionWiseSalesDaily = payload.data.divisionSales
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsItemSales.itemWiseSalesYearly = payload.data.itemSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsItemSales.itemWiseSalesWeekly = payload.data.itemSales
			} else {
				newState.salesAnalyticsItemSales.itemWiseSalesDaily = payload.data.itemSales
			}
			return newState
		}
		case FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesYearly = payload.data.distributorSales
			} else if (interval === 'weekly') {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesWeekly = payload.data.distributorSales
			} else {
				newState.salesAnalyticsDistributorSales.distributorWiseSalesDaily = payload.data.distributorSales
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioMonthly = payload.data.ordersAssignedAndCompletedRatio
			} else if (interval === 'weekly') {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioWeekly = payload.data.ordersAssignedAndCompletedRatio
			} else {
				newState.ordersAssignedAndCompletedRatio.ordersAssignedAndCompletedRatioDaily = payload.data.ordersAssignedAndCompletedRatio
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioMonthly = payload.data.driverWiseCollectedAmountRatio
			} else if (interval === 'weekly') {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioWeekly = payload.data.driverWiseCollectedAmountRatio
			} else {
				newState.driverWiseCollectedAmountRatio.driverWiseCashCollectedAmountRatioDaily = payload.data.driverWiseCollectedAmountRatio
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationMonthly = payload.data.deliveryPartners
			} else if (interval === 'weekly') {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationWeekly = payload.data.deliveryPartners
			} else {
				newState.deliveryPartnerByLocation.deliveryPartnerByLocationDaily = payload.data.deliveryPartners
			}
			return newState
		}
		case FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS: {
			const interval = payload.interval
			const newState = { ...state }
			if (interval === 'monthly') {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerMonthly = payload.data.distanceTraveledByDeliveryPartner
			} else if (interval === 'weekly') {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerWeekly = payload.data.distanceTraveledByDeliveryPartner
			} else {
				newState.distanceTraveledByDeliveryPartner.distanceTraveledByDeliveryPartnerDaily = payload.data.distanceTraveledByDeliveryPartner
			}
			return newState
		}
		case SCAN_ADHERENCE_SUMMARY_SUCCESS: {
			return {
				...state,
				scanAdherenceSummary: payload
			}
		}
		case SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS: {
			return {
				...state,
				warehouseWiseScanMissed: payload.warehouseWiseScanMissed,
				totalScanMissedInAllWarehouses: payload.totalScanMissedInAllWarehouses
			}
		}
		case SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS: {
			return {
				...state,
				nationwideScanMissed: payload.nationwideScanMissed
			}
		}
		default:
			return state
	}
}

export default dashboardReducer
