import { Button, notification, Modal, Collapse } from 'antd'
import { useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useDispatch } from 'react-redux'
import { Users } from '../../services/api/users'
import { assignHelperToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import { PlusOutlined } from '@ant-design/icons'
import CustomPhoneInput from '../phone-input'

const { Panel } = Collapse

const AssignHelperModal = ({ visible = false, onCancel, deliveryPlanItemId }) => {
	const dispatch = useDispatch()
	const [isLoadingHelpers, setIsLoadingHelpers] = useState(false)
	const [isCreatingNewHelper, setIsCreatingNewHelper] = useState(false)
	const [isAssigningHelper, setIsAssigningHelper] = useState(false)
	const [helper, setHelper] = useState()
	const [helpers, setHelpers] = useState([])
	const [helperName, setHelperName] = useState('')
	const [helperPhone, setHelperPhone] = useState('+880')
	const [helperEmail, setHelperEmail] = useState('')

	useEffect(() => {
		fetchHelpers()
	}, [])

	const fetchHelpers = async () => {
		setIsLoadingHelpers(true)
		const response = await Users.index({ role: 'helper' })
		setHelpers(response.data.results)
		setIsLoadingHelpers(false)
	}

	const searchUsers = value => {
		return Users.index({ searchTerm: value, role: 'helper' })
	}

	const createHelper = async () => {
		setIsCreatingNewHelper(true)
		try {
			const response = await Users.create({
				name: helperName,
				phone: helperPhone,
				email: helperEmail
			})
			const createdHelper = response.data
			setHelpers([createdHelper, ...helpers])
			setHelper({
				key: createdHelper.id,
				label: createdHelper.name,
				value: createdHelper.id
			})
			clearForm()
			notification.success({
				message: 'New Helper Created',
				description: 'New Helper successfully created!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Create New Helper',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsCreatingNewHelper(false)
		}
	}

	const assignHelper = async () => {
		setIsAssigningHelper(true)
		try {
			await dispatch(assignHelperToDeliveryPlanItem(deliveryPlanItemId, {
				helperId: helper.value
			}))
			onCancel()
			notification.success({
				message: 'Helper Assigned to Delivery Plan',
				description: 'Driver has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Helper to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningHelper(false)
		}
	}

	const clearForm = () => {
		setHelperName('')
		setHelperPhone('+880')
		setHelperEmail('')
	}

	const isHelperInputValid = () => {
		if (!helperName) {
			return false
		}

		if (!helperPhone) {
			return false
		} else {
			if (!isValidPhoneNumber(helperPhone)) {
				return false
			}
		}

		if (!helperEmail) {
			return false
		} else {
			var re = /\S+@\S+\.\S+/
			if (!re.test(helperEmail)) {
				return false
			}
		}
		return true
	}

	return (
		<Modal
			title='Assign Helper'
			visible={visible}
			onCancel={onCancel}
			onOk={assignHelper}
			okText='Assign Helper'
			okButtonProps={{
				disabled: !helper
			}}
			confirmLoading={isAssigningHelper}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<CustomSearchableSelect
					searchTask={searchUsers}
					defaultOptions={helpers}
					isLoading={isLoadingHelpers}
					labelIndex='name'
					valueIndex='id'
					placeholder='Select Helper'
					onChange={option => setHelper(option)}
					onClear={() => setHelper(null)}
					value={helper}
				/>
				<Collapse style={{ marginTop: 24 }}>
					<Panel header='Create New Helper'>
						<Input
							onChange={e => {
								let text = e.target.value.toLowerCase()
								text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
								setHelperName(text)
							}}
							value={helperName}
							title='Helper Name'
						/>
						<div style={{ marginTop: 24 }} />
						<Input
							onChange={e => {
								setHelperEmail(e.target.value)
							}}
							value={helperEmail}
							title='Email'
						/>
						<div style={{ marginTop: 24 }} />
						<CustomPhoneInput
							title='Phone Number'
							value={helperPhone}
							onChange={setHelperPhone}
						/>
						<Button
							onClick={createHelper}
							loading={isCreatingNewHelper}
							disabled={!isHelperInputValid()}
							type='primary'
							style={{ alignSelf: 'flex-start', marginTop: 24 }}
							icon={<PlusOutlined />}
						>
							Create
						</Button>
					</Panel>
				</Collapse>
			</div>
		</Modal>
	)
}

export default AssignHelperModal
