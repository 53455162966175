import moment from 'moment'
import { get, post } from './index'

export const Reports = {
	index: ({
		page = 0,
		searchTerm,
		toDate,
		fromDate,
		type
	} = {}) =>
		get('/reports', {
			params: {
				page,
				searchTerm,
				toDate,
				fromDate,
				type
			}
		}),
	single: (id, {
		accessToken
	} = {}) =>
		get(`/reports/${id}`, {
			headers: {
				authorization: accessToken
			}
		}),
	create: (params) =>
		post('/reports', params),
	downloadReportCsv: async (id, reportName) => {
		const response = await get(`/reports/${id}/csv`, {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		const fileName = reportName ? `${reportName}.csv` : `Report_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadReportXlsx: async (id, reportName) => {
		const response = await get(`/reports/${id}/xlsx`, {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		const fileName = reportName ? `${reportName}.xlsx` : `Report_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
		link.download = fileName
		link.click()
		link.remove()
	}
}
