import { Cascader } from 'antd'
// We use Less styles to override Ant stylings.
require('./CustomCascader.less')

const CustomCascader = ({
	options = [],
	defaultValue,
	placeholder,
	value,
	onChange,
	allowClear,
	style,
	dropdownRender,
	changeOnSelect,
	popupContainer
}) => {
	return (
		<Cascader
			changeOnSelect={changeOnSelect}
			dropdownRender={dropdownRender}
			allowClear={allowClear}
			placeholder={placeholder}
			className='custom-cascader'
			defaultValue={defaultValue}
			value={value}
			options={options}
			onChange={onChange}
			getPopupContainer={() => popupContainer ? popupContainer() : document.body}
			style={{ ...style, width: '100%' }}
		/>
	)
}

export default CustomCascader
