import { Distributors } from '../../services/api/distributors'
import {
	ADD_DISTRIBUTOR_SUCCESS,
	FETCH_DISTRIBUTORS_SUCCESS,
	FETCH_DISTRIBUTOR_DETAILS_SUCCESS,
	FETCH_DISTRIBUTOR_METRICS_SUCCESS,
	UPDATE_DISTRIBUTOR_SUCCESS
} from '../distributors/action-types'

export function fetchDistributors ({
	page,
	disabled,
	searchTerm,
	fromDate,
	toDate
}) {
	return async (dispatch) => {
		const response = await Distributors.index({
			page,
			disabled,
			searchTerm,
			fromDate,
			toDate
		})
		dispatch({ type: FETCH_DISTRIBUTORS_SUCCESS, payload: response.data })
	}
}

export function addDistributor (params) {
	return async (dispatch) => {
		const response = await Distributors.create(params)
		dispatch({ type: ADD_DISTRIBUTOR_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchDistributorDetails (id) {
	return async (dispatch) => {
		const response = await Distributors.single(id)
		dispatch({ type: FETCH_DISTRIBUTOR_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateDistributor (id, params) {
	return async (dispatch) => {
		const response = await Distributors.update(id, params)
		dispatch({ type: UPDATE_DISTRIBUTOR_SUCCESS, payload: response.data })
	}
}

export function fetchDistributorMetrics () {
	return async (dispatch) => {
		const response = await Distributors.metrics()
		dispatch({ type: FETCH_DISTRIBUTOR_METRICS_SUCCESS, payload: response.data })
	}
}
