import React, { useState } from 'react'
import { Button, Empty, Space, Table, Select, notification, Dropdown, Menu, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'
import Page from '../../components/page'
import styles from './fleet.module.css'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchVehicleMetrics, fetchVehicles } from '../../store/vehicles/actions'
import FleetMapView from '../../components/fleet-map-view'
import useSearchParams from '../../hooks/useSearchParams'
import moment from 'moment'
import Link from 'next/link'
import { paths } from '../../utils/constants'
import Input from '../../components/input'
import { createGeofence, deleteGeofence, updateGeofence } from '../../store/geofences/actions'
require('./fleet.less')
import UploadFleetModal from '../../components/upload-fleet-modal'

export const VEHICLE_ACTIVE_STATUS = 'ACTIVE'
export const VEHICLE_INACTIVE_STATUS = 'INACTIVE'
export const VEHICLE_IN_MAINTENANCE_STATUS = 'IN_MAINTENANCE'

const vehicleTypes = {
	VT_40FT_HQ_TRAILER: '40FT HQ (High Cube) Trailer',
	VT_40FT_TRAILER_18_WHEELER: '40FT Trailer (18 Wheeler)',
	VT_40FT_TRAILER_14_WHEELER: '40FT Trailer (14 Wheeler)',
	VT_20FT_TRAILER_20_WHEELER: '20FT Trailer (Power 20 Wheeler)',
	VT_20FT_TRAILER_14_WHEELER: '20FT Trailer (Power 14 Wheeler)',
	VT_20FT_TRAILER_12_WHEELER: '20FT Trailer (12 Wheeler)',
	VT_20FT_TRAILER_10_WHEELER: '20FT Trailer (10 Wheeler)',
	VT_20FT_TRAILER_6_WHEELER: '20FT Trailer (6 Wheeler)',
	VT_23FT_COVERED_VAN: '23FT Covered Van',
	VT_20FT_OPEN_TRUCK: '20FT Open Truck',
	VT_18FT_COVERED_VAN: '18FT Covered Van',
	VT_17_5FT_OPEN_TRUCK: '17.5T Open Truck',
	VT_17FT_COVERED_VAN: '17FT Covered Van',
	VT_16FT_COVERED_VAN: '16FT Covered Van',
	VT_16FT_OPEN_TRUCK: '16FT Open Truck',
	VT_14FT_COVERED_VAN: '14FT Covered Van',
	VT_13_5FT_COVERED_VAN: '13.5FT Covered Van',
	VT_13FT_COVERED_VAN: '13FT Covered Van',
	VT_12_5FT_COVERED_VAN: '12.5FT Covered Van',
	VT_12_5FT_OPEN_TRUCK: '12.5FT Open Truck',
	VT_12FT_OPEN_TRUCK: '12FT Open Truck',
	VT_11FT_OPEN_TRUCK: '11FT Open Truck',
	VT_10FT_COVERED_VAN: '10FT Covered Van',
	VT_8FT_COVERED_VAN: '8FT Covered Van',
	VT_7_5FT_COVERED_VAN: '7.5FT Covered Van',
	VT_7FT_COVERED_VAN: '7FT Covered Van',
	VT_7FT_OPEN_TRUCK: '7FT Open Truck'
}

export const getVehicleTypeLabel = (type) => {
	return vehicleTypes[type] || type
}

export const getStatusColor = (status) => {
	switch (status) {
		case VEHICLE_INACTIVE_STATUS:
			return '#4f4f4f'
		case VEHICLE_IN_MAINTENANCE_STATUS:
			return '#FF5959'
		case VEHICLE_ACTIVE_STATUS:
		default:
			return '#27ae60'
	}
}

export const getVehicleStatusLabel = (status) => {
	switch (status) {
		case VEHICLE_INACTIVE_STATUS:
			return 'Inactive'
		case VEHICLE_IN_MAINTENANCE_STATUS:
			return 'In Maintenance'
		case VEHICLE_ACTIVE_STATUS:
			return 'Active'
		default:
			return status
	}
}

const vehicleColumns = [
	{
		title: 'Vehicle ID',
		key: 'plateNumber',
		render: vehicle => {
			return (
				<Link href={`${paths.FLEET}/${vehicle.id}`}>
					<a className={styles.link}>{vehicle.plateNumber}</a>
				</Link>
			)
		}
	},
	{
		title: 'Registered At',
		key: 'createdAt',
		render: vehicle => {
			return moment(vehicle.createdAt).format('MMM D, YYYY')
		}
	},
	{
		title: 'Brand',
		dataIndex: 'brand',
		key: 'brand'
	},
	{
		title: 'Model',
		dataIndex: 'model',
		key: 'model'
	},
	{
		title: 'Year',
		dataIndex: 'year',
		key: 'year'
	},
	{
		title: 'Type',
		key: 'type',
		render: vehicle => {
			return getVehicleTypeLabel(vehicle.type)
		}
	},
	{
		title: 'Status',
		key: 'status',
		render: vehicle => {
			return (
				<div
					style={{ color: getStatusColor(vehicle.status) }}
				>
					{getVehicleStatusLabel(vehicle.status)}
				</div>
			)
		}
	}
]

export const VIEW_MODE = {
	LIST: 'list',
	MAP: 'map'
}

const geofenceColors = [
	{
		value: '#eb2f96',
		label: 'Pink'
	},
	{
		value: '#f5222d',
		label: 'Red'
	},
	{
		value: '#fadb14',
		label: 'Yellow'
	},
	{
		value: '#fa8c16',
		label: 'Orange'
	},
	{
		value: '#13c2c2',
		label: 'Cyan'
	},
	{
		value: '#52c41a',
		label: 'Green'
	},
	{
		value: '#1890ff',
		label: 'Blue'
	},
	{
		value: '#722ed1',
		label: 'Purple'
	},
	{
		value: '#2f54eb',
		label: 'Geek Blue'
	},
	{
		value: '#fa541c',
		label: 'Volcano'
	},
	{
		value: '#faad14',
		label: 'Gold'
	},
	{
		value: '#a0d911',
		label: 'Lime'
	}
]

const geofenceShapes = [
	{
		value: 'polygon',
		label: 'Polygon'
	},
	{
		value: 'rectangle',
		label: 'Rectangle'
	},
	{
		value: 'circle',
		label: 'Circle'
	}
]

const { Option } = Select

const FleetPage = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { view } = router.query
	const { vehicles, page, totalCount, metrics } = useSelector(state => state.vehiclesReducer)
	const { permissions } = useSelector(state => state.authReducer)
	const { searchParams } = useSearchParams()
	const isMapView = view === VIEW_MODE.MAP
	const [isFetching, setIsFetching] = useState(false)
	const [isGeofencesChecked, setIsGeofencesChecked] = useState(false)
	const [isEditingGeofences, setIsEditingGeofences] = useState(false)
	const [isSavingGeofence, setIsSavingGeofence] = useState(false)
	const [geofenceName, setGeofenceName] = useState('')
	const [geofenceColor, setGeofenceColor] = useState(geofenceColors[0])
	const [geofenceShape, setGeofenceShape] = useState(geofenceShapes[0])
	const [geofenceRadius, setGeofenceRadius] = useState(1) // In kilometers
	const [geofenceCenter, setGeofenceCenter] = useState()
	const [geofencePoints, setGeofencePoints] = useState([])
	const [editableGeofence, setEditableGeofence] = useState()
	const [isUploadFleetModalVisible, setIsUploadFleetModalVisible] = useState()
	const [isFetchingMetrics, setIsFetchingMetrics] = useState(false)

	useEffect(() => {
		if (!permissions.viewFleet) {
			return
		}
		getVehicles()
	}, [searchParams])

	useEffect(() => {
		if (!permissions.viewFleet) {
			return
		}
		getMetrics()
	}, [])

	const getVehicles = async () => {
		setIsFetching(true)
		await dispatch(fetchVehicles({ page: 0, ...searchParams }))
		setIsFetching(false)
	}

	const getMetrics = async () => {
		setIsFetchingMetrics(true)
		await dispatch(fetchVehicleMetrics())
		setIsFetchingMetrics(false)
	}

	const onPageChanged = async (page) => {
		setIsFetching(true)
		await dispatch(fetchVehicles({ page: page - 1, ...searchParams }))
		setIsFetching(false)
	}

	const onSaveGeofence = async () => {
		try {
			setIsSavingGeofence(true)
			await dispatch(createGeofence({
				name: geofenceName,
				shape: geofenceShape.value,
				color: geofenceColor.value,
				center: geofenceShape.value === 'circle' ? geofenceCenter : null,
				radius: geofenceShape.value === 'circle' ? geofenceRadius : null,
				points: geofenceShape.value === 'circle' ? null : geofencePoints
			}))
			clearGeofenceInfo()
		} catch (e) {
			notification.error({
				message: 'Unable to Create Geofence',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSavingGeofence(false)
		}
	}

	const onUpdateGeofence = async () => {
		try {
			if (!editableGeofence) {
				return
			}
			setIsSavingGeofence(true)
			await dispatch(updateGeofence(editableGeofence.id, {
				name: geofenceName,
				shape: geofenceShape.value,
				color: geofenceColor.value,
				center: geofenceShape.value === 'circle' ? geofenceCenter : null,
				radius: geofenceShape.value === 'circle' ? geofenceRadius : null,
				points: geofenceShape.value === 'circle' ? null : geofencePoints
			}))
			clearGeofenceInfo()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Geofence',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSavingGeofence(false)
		}
	}

	const onDeleteGeofence = async () => {
		try {
			if (!editableGeofence) {
				return
			}
			setIsSavingGeofence(true)
			await dispatch(deleteGeofence(editableGeofence.id))
			clearGeofenceInfo()
		} catch (e) {
			notification.error({
				message: 'Unable to Delete Geofence',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSavingGeofence(false)
		}
	}

	const isGeofenceValid = () => {
		if (!geofenceName) {
			return false
		} else if (geofenceName.trim().length === 0) {
			return false
		}
		if (geofenceShape.value === 'circle') {
			if (!geofenceCenter) {
				return false
			}
			if (!geofenceRadius) {
				return false
			}
		} else {
			if (geofencePoints.length === 0) {
				return false
			}
		}
		return true
	}

	const onGeofenceEdit = (geofence) => {
		if (geofence) {
			setEditableGeofence(geofence)
			setGeofenceName(geofence.name)
			setGeofenceColor(geofenceColors.find(option => option.value === geofence.color))
			setGeofenceShape(geofenceShapes.find(option => option.value === geofence.shape))
			if (geofence.shape === 'circle') {
				setGeofenceRadius(geofence.radius)
			}
			setIsEditingGeofences(true)
		}
	}

	const clearGeofenceInfo = () => {
		setGeofenceName('')
		setGeofenceRadius(1)
		setGeofenceCenter(null)
		setGeofencePoints([])
		setEditableGeofence(null)
		setIsEditingGeofences(false)
	}

	return (
		<Page>
			<div className={styles.container}>
				<div className={styles.contentHeader}>
					{
						isEditingGeofences ?
							<Space
								size='middle'
							>
								<Input
									title={<span><span style={{ color: '#ff4d4f', marginRight: 12, fontSize: 14 }}>*</span>Geofence Name</span>}
									value={geofenceName}
									onChange={e => setGeofenceName(e.target.value)}
									small={true}
									style={{ height: 64 }}
								/>
								<div className={styles.inputContainer}>
									<div className={styles.title}>Color</div>
									<Select
										defaultValue={geofenceColors[0]}
										className='geofence-select'
										style={{ width: 120 }}
										onChange={option => setGeofenceColor(option)}
										value={geofenceColor}
										labelInValue
									>
										{
											geofenceColors.map(geofenceColor => {
												return (
													<Option
														key={geofenceColor.value}
														value={geofenceColor.value}
														label={geofenceColor.label}
													>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<div style={{ background: geofenceColor.value, marginRight: 6, height: 18, width: 18, borderRadius: 6 }} />
															{geofenceColor.label}
														</div>
													</Option>
												)
											})
										}
									</Select>
								</div>
								<div className={styles.inputContainer}>
									<div className={styles.title}>Shape</div>
									<Select
										defaultValue={geofenceShapes[0]}
										className='geofence-select'
										style={{ width: 120 }}
										onChange={option => setGeofenceShape(option)}
										value={geofenceShape}
										disabled={!!editableGeofence}
										labelInValue
									>
										{
											geofenceShapes.map(geofenceShape => {
												return (
													<Option
														key={geofenceShape.value}
														value={geofenceShape.value}
														label={geofenceShape.label}
													>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															{geofenceShape.label}
														</div>
													</Option>
												)
											})
										}
									</Select>
								</div>
								{
									geofenceShape.value === 'circle' &&
									<Input
										title='Radius (km)'
										small={true}
										style={{ height: 64 }}
										type='number'
										min={1}
										onChange={value => setGeofenceRadius(+value)}
										value={geofenceRadius}
									/>
								}
							</Space> :
							<h2 style={{ alignSelf: 'center', margin: 0 }}>Fleet</h2>
					}
					<div className={styles.metrics}>
						<div className={styles.count}>
							<h3 className={styles.total}>
								{isFetchingMetrics ? <Spin size='small' /> : metrics.total}
							</h3>
							<p>Total Fleet</p>
						</div>
						<div className={styles.count}>
							<h3 className={styles.new}>
								{isFetchingMetrics ? <Spin size='small' /> : metrics.new}
							</h3>
							<p>New Fleet</p>
						</div>
						<div className={styles.count}>
							<h3 className={styles.active}>
								{isFetchingMetrics ? <Spin size='small' /> : metrics.active}
							</h3>
							<p>Active Fleet</p>
						</div>
					</div>
					<div className={styles.contentHeaderContainer}>
						<div
							onClick={() => router.push(`/fleet?view=${VIEW_MODE.MAP}`)}
							style={{ marginRight: '16px' }}
							className={isMapView ? styles.filterButtonSelected : styles.filterButton}
						>
							<img src='/img/map.svg' width={20} height={20} />
						</div>
						<div
							onClick={() => router.push(`/fleet?view=${VIEW_MODE.LIST}`)}
							style={{ marginRight: '24px' }}
							className={!isMapView ? styles.filterButtonSelected : styles.filterButton}
						>
							<img src='/img/list.svg' />
						</div>
						<Dropdown overlay={() => {
							return (
								<Menu>
									<Menu.Item
										disabled={!permissions.uploadFleet}
										onClick={() => setIsUploadFleetModalVisible(true)}
									>
										<div className={styles.menuItem}>
											<UploadOutlined style={{ marginRight: 12 }} />
											<div className={styles.menuTitle}>Upload Fleet</div>
										</div>
									</Menu.Item>
								</Menu>
							)
						}} trigger={['click']}
						>
							<Button
								shape='round'
								size='large'
								loading={false}
								style={{ marginRight: 24 }}
							>
								Action
							</Button>
						</Dropdown>
						{
							isMapView && isGeofencesChecked ?
								isEditingGeofences ?
									<Space>
										<Button
											danger={true}
											type='default'
											onClick={clearGeofenceInfo}
											shape='round'
											loading={isSavingGeofence}
										>
											Cancel
										</Button>
										{
											editableGeofence &&
											<Button
												danger={true}
												type='primary'
												shape='round'
												onClick={onDeleteGeofence}
												loading={isSavingGeofence}
											>
												Delete
											</Button>
										}
										<Button
											type='primary'
											shape='round'
											onClick={editableGeofence ? onUpdateGeofence : onSaveGeofence}
											loading={isSavingGeofence}
											disabled={editableGeofence ? false : !isGeofenceValid()}
										>
											{editableGeofence ? 'Edit' : 'Save'}
										</Button>
									</Space> :
									<Button
										type='primary'
										onClick={() => setIsEditingGeofences(!isEditingGeofences)}
										shape='round'
										size='large'
										style={{ background: '#9B51E0', borderColor: '#9B51E0' }}
									>
										Set Up Geofence
									</Button> :
								<Button
									type='primary'
									disabled={!permissions.addFleet}
									onClick={() => router.push('/add-vehicle')}
									shape='round'
									size='large'
								>
									Add Vehicle
								</Button>
						}
					</div>
				</div>
				<div className={styles.content}>
					{
						isMapView ?
							<FleetMapView
								geofenceEnabled={isGeofencesChecked}
								enableGeofences={setIsGeofencesChecked}
								drawingEnabled={isEditingGeofences}
								geofenceColor={geofenceColor.value}
								geofenceShape={geofenceShape.value}
								geofenceRadius={geofenceRadius}
								editableGeofence={editableGeofence}
								onGeofenceRadiusChanged={radius => setGeofenceRadius(radius)}
								onGeofenceCenterChanged={point => setGeofenceCenter(point)}
								onGeofencePointsChanged={points => setGeofencePoints(points)}
								onGeofenceEdit={geofence => onGeofenceEdit(geofence)}
							/> :
							isFetching || vehicles.length > 0 ?
								<Table
									refresh={() => onPageChanged(page)}
									style={{ marginTop: '24px' }}
									loading={isFetching && vehicles.length === 0}
									columns={vehicleColumns}
									className={styles.table}
									dataSource={vehicles}
									pagination={{
										pageSize: 50,
										total: totalCount,
										position: ['topLeft'],
										onChange: onPageChanged,
										showSizeChanger: false,
										current: page + 1
									}}
									scroll={{ y: 'calc(100vh - 360px)' }}
									rowKey='id'
								/> :
								<div style={{ textAlign: 'center' }}>
									<Empty
										description='More vehicles means more deliveries means more business.'
										image='/img/vehicles-empty.svg'
										imageStyle={{ height: '450px', marginBottom: '24px' }}
										style={{ margin: '60px 0px' }}
									/>
									<Button
										type='primary'
										onClick={() => router.push('/add-vehicle')}
										shape='round'
										size='large'
									>
										Add Vehicle
									</Button>
								</div>
					}
				</div>
			</div>
			{
				isUploadFleetModalVisible &&
					<UploadFleetModal
						visible={isUploadFleetModalVisible}
						onCancel={() => setIsUploadFleetModalVisible(false)}
						onComplete={() => dispatch(fetchVehicles({ page }))}
					/>
			}
		</Page>
	)
}

export default FleetPage
