import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

export const apiClient = axios.create({
	baseURL: process.env.NEXT_PUBLIC_BASE_URL
})

const getCurrentUser = () => {
	return new Promise((resolve) => {
		if (firebase.auth().currentUser) {
			resolve(firebase.auth().currentUser)
		} else {
			const unsubscribe = firebase.auth().onAuthStateChanged(user => {
				unsubscribe()
				if (user) {
					resolve(user)
				} else {
					resolve(null)
				}
			})
		}
	})
}

apiClient.interceptors.request.use(
	async config => {
		const currentUser = await getCurrentUser()
		if (currentUser) {
			const token = await currentUser.getIdToken()
			config.headers.authorization = token
		}
		return config
	})

apiClient.interceptors.response.use(
	response => response,
	error => {
		const errorResponse = error.response
		if (errorResponse && errorResponse.data) {
			if (errorResponse.data.message) {
				if (Array.isArray(errorResponse.data.message)) {
					const message = errorResponse.data.message.join('\r\n')
					return Promise.reject(new Error(message))
				} else {
					return Promise.reject(new Error(errorResponse.data.message))
				}
			} else {
				return Promise.reject(error)
			}
		} else {
			return Promise.reject(error)
		}
	}
)

const { get, post, put, delete: destroy, patch } = apiClient
export { get, post, put, destroy, patch }
