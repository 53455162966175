import { Modal, notification } from 'antd'
import { useEffect, useState } from 'react'
import CustomSearchableSelect from '../../components/custom-searchable-select'
import { LocationTypes } from '../../components/location-modal'
import { Locations } from '../../services/api/locations'
import styles from './StartLocationModal.module.css'

const StartLocationModal = ({ onCancel, visible, onUseLocation }) => {
	const [location, setLocation] = useState()
	const [locations, setLocations] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isFetchingLocation, setIsFetchingLocation] = useState(false)
	const [selectedLocation, setSelectedLocation] = useState()

	useEffect(() => {
		getLocations()
	}, [])

	const getLocations = async () => {
		setIsLoading(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoading(false)
	}

	const searchLocations = (value, type) => {
		return Locations.index({ searchTerm: value, type })
	}

	const onSelectLocation = async (location) => {
		setLocation(location)
		const locationId = location.value
		try {
			setIsFetchingLocation(true)
			const response = await Locations.single(locationId)
			if (response.data) {
				setSelectedLocation(response.data)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to retrieve location.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsFetchingLocation(false)
		}
	}

	return (
		<Modal
			visible={visible}
			okText='Use Location'
			onOk={() => onUseLocation(selectedLocation)}
			onCancel={onCancel}
			title='Choose Pick Up Location'
			okButtonProps={{
				disabled: !selectedLocation,
				loading: isFetchingLocation
			}}
		>
			<div className={styles.locationPopover} onClick={e => e.stopPropagation()}>
				<CustomSearchableSelect
					title={<span>Pick Up Location</span>}
					defaultOptions={locations}
					isLoading={isLoading}
					labelIndex='address'
					valueIndex='id'
					descriptionIndex='type'
					placeholder='Select Pick Up Location'
					onChange={onSelectLocation}
					onClear={() => setLocation(null)}
					value={location}
					showSearch={true}
					searchTask={value => searchLocations(value, LocationTypes.WAREHOUSE, LocationTypes.FACTORY)}
				/>
			</div>
		</Modal>
	)
}

export default StartLocationModal
