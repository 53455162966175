import { get } from './index'

export const Dashboard = {
	fetchGeneralAnalyticsStatistics: () =>
		get('/dashboard/general-analytics/statistics'),
	fetchGeneralAnalyticsOrderRate: (interval) =>
		get('/dashboard/general-analytics/order-rate', {
			params: {
				interval
			}
		}),
	fetchGeneralAnalyticsOrderCycleTime: (interval, fromDate, toDate, searchTerm) =>
		get('/dashboard/general-analytics/order-cycle-time', {
			params: {
				interval, fromDate, toDate, searchTerm
			}
		}),
	fetchGeneralAnalyticsOrderLeadTime: (interval, leadTimeDate) =>
		get('/dashboard/general-analytics/lead-time', {
			params: {
				interval, leadTimeDate
			}
		}),
	fetchSalesAnalyticsOrders: (interval) =>
		get('/dashboard/sales-analytics/orders', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsSales: (interval) =>
		get('/dashboard/sales-analytics/sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDistributorGrowth: (interval) =>
		get('/dashboard/sales-analytics/distributor-growth', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDivisionSales: (interval) =>
		get('/dashboard/sales-analytics/division-sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsItemSales: (interval) =>
		get('/dashboard/sales-analytics/item-sales', {
			params: {
				interval
			}
		}),
	fetchSalesAnalyticsDistributorSales: (interval) =>
		get('/dashboard/sales-analytics/distributor-sales', {
			params: {
				interval
			}
		}),
	fetchOrdersAssignedAndCompletedRatio: (interval) =>
		get('/dashboard/delivery-partner-analytics/orders-assigned-completed-ratio', {
			params: {
				interval
			}
		}),
	fetchCollectedAmountRatio: (interval) =>
		get('/dashboard/delivery-partner-analytics/cash-collected-ratio', {
			params: {
				interval
			}
		}),
	fetchDeliveryPartnerByLocation: (interval) =>
		get('/dashboard/delivery-partner-analytics/delivery-partner-location', {
			params: {
				interval
			}
		}),
	fetchDistanceTraveledByDeliveryPartner: (interval) =>
		get('/dashboard/delivery-partner-analytics/delivery-partner-distance-traveled', {
			params: {
				interval
			}
		}),
	fetchScanAdherenceSummary: ({ interval, locationIds, fromDate, toDate } = {}) =>
		get('/dashboard/scan-adherence-analytics/summary', {
			params: {
				interval,
				locationIds,
				fromDate,
				toDate
			}
		}),
	fetchScanAdherenceWarehouseWiseScanMissed: ({ interval, fromDate, toDate } = {}) =>
		get('/dashboard/scan-adherence-analytics/warehouse-wise-scan-missed', {
			params: {
				interval,
				fromDate,
				toDate
			}
		}),
	fetchScanAdherenceNationwideScanMissed: ({ interval, fromDate, toDate, page } = {}) =>
		get('/dashboard/scan-adherence-analytics/nationwide-scan-missed', {
			params: {
				interval,
				fromDate,
				toDate,
				page
			}
		})
}
