import styles from './header-tab.module.css'

const HeaderTab = ({ children, onClick, isSelected }) => {
	return (
		<div
			onClick={onClick}
			className={`${styles.headerTab} ${isSelected() && styles.headerTabSelected}`}
		>
			<div>{children}</div>
			<div className={`${styles.indicatorDot} ${isSelected() && styles.indicatorDotActive}`} />
		</div>)
}

export default HeaderTab
