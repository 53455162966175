import { get, post, patch, destroy } from './index'

export const Distributors = {
	index: ({
		page = 0,
		disabled,
		searchTerm,
		fromDate,
		toDate
	} = {}) =>
		get('/distributors', {
			params: {
				searchTerm,
				page,
				disabled,
				fromDate,
				toDate
			}
		}),
	single: (id) =>
		get(`/distributors/${id}`),
	create: (params) =>
		post('/distributors', params),
	update: (id, params) =>
		patch(`/distributors/${id}`, params),
	remove: (id) =>
		destroy(`/distributors/${id}`),
	metrics: () =>
		get('/distributors/metrics')
}
