import { get, post } from './index'

export const Transactions = {
	distributorsIndex: ({
		distributorId
	} = {}) =>
		get(`/transactions/distributors/${distributorId}`),
	create: (params) =>
		post('/transactions', params)
}
