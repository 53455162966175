
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Empty, Modal, notification, Space, Switch, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SALES_ORDER_APPROVED_STATUS } from '../../pages/sales-orders'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import Table from '../table'
import Input from '../input'
import moment from 'moment'
import Link from 'next/link'
import styles from './create-delivery-plan.module.css'
require('./create-delivery-plan.less')
import PlacesAutocomplete from '../places-autocomplete'
import { createCustomDeliveryPlan } from '../../store/delivery-plans/actions'
import { fetchUserProfile } from '../../store/auth/actions'
import SearchInput from '../search-input'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import { Locations } from '../../services/api/locations'
import { DELIVERY_PLAN_PENDING_STATUS } from '../../pages/delivery-plans'
import { getShortId } from '../../utils/sales-orders'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { fetchVehicleTypes } from '../../store/vehicles/actions'
import { paths } from '../../utils/constants'
import Map from '../map'
import { LocationTypes } from '../location-modal'
import { SalesOrders } from '../../services/api/sales-orders'

const salesOrderColumns = [
	{
		title: 'Date',
		key: 'deliveryDate',
		render: salesOrder => {
			return moment(salesOrder.deliveryDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Order #',
		key: 'salesOrderID',
		render: salesOrder => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
					<a className={styles.link}>{getShortId(salesOrder)}</a>
				</Link>
			)
		}
	},
	{
		title: 'Assigned To',
		key: 'assignedDeliveryPlanID',
		render: salesOrder => {
			return (
				salesOrder.deliveryPlan ?
					<Link href={`/delivery-plans/${salesOrder.deliveryPlan.id}`}>
						<a className={styles.link}>
							{`DP-${salesOrder.deliveryPlan.shortId}`}
						</a>
					</Link>
					:
					<div className={styles.unassigned}>
						Unassigned
					</div>
			)
		}
	},
	{
		title: 'Area Manager',
		key: 'areaManager',
		render: salesOrder => {
			if (salesOrder.distributor && salesOrder.distributor.accountManager) {
				return `${salesOrder.distributor.accountManager.name}`
			} else {
				return (
					<div className={styles.unassigned}>
						Unassigned
					</div>
				)
			}
		}
	},
	{
		title: 'Pick Up',
		key: 'from',
		render: salesOrder => {
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						salesOrder.pickUpLocation &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div className={styles.badge}>
								{convertEnumToString(salesOrder.pickUpLocation.type.toLowerCase())}
							</div>
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(salesOrder.pickUpLocation.type) ?
									<Link href={`${paths.WAREHOUSES}/${salesOrder.pickUpLocation.id}`}>
										<a>
											{salesOrder.pickUpLocation.label}
											<div className={styles.location}>
												{salesOrder.pickUpLocation.address}
											</div>
										</a>
									</Link> :
									<div>
										{salesOrder.pickUpLocation.label}
										<div className={styles.location}>
											{salesOrder.pickUpLocation.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Drop Off',
		key: 'to',
		render: salesOrder => {
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						salesOrder.distributor ?
							<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
								<div className={styles.badge} style={{ color: '#278EA5', background: '#ecf9f9' }}>
									Distributor
								</div>
								<Link href={`${paths.DISTRIBUTORS}/${salesOrder.distributor.id}`}>
									<a style={{ color: '#278EA5' }}>
										{salesOrder.distributor.name}
									</a>
								</Link>
							</div> : null
					}
					{
						salesOrder.location &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								!salesOrder.distributor &&
								<div className={styles.badge}>
									{convertEnumToString(salesOrder.location.type.toLowerCase())}
								</div>
							}
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(salesOrder.location.type) ?
									<Link href={`${paths.WAREHOUSES}/${salesOrder.location.id}`}>
										<a>
											{salesOrder.location.label}
											<div className={styles.location}>
												{salesOrder.location.address}
											</div>
										</a>
									</Link> :
									<div>
										{salesOrder.location.label}
										<div className={styles.location}>
											{salesOrder.location.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Sales Amount',
		key: 'salesAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.totalAmount)}`
		}
	}
]

const CreateDeliveryPlanModal = ({ onCancel, visible, date, shouldShowUnassigned = false }) => {
	const dispatch = useDispatch()
	const { userProfile } = useSelector(state => state.authReducer)
	const [approvedSalesOrders, setApprovedSalesOrders] = useState([])
	const [approvedSalesOrdersPage, setApprovedSalesOrdersPage] = useState(0)
	const [approvedSalesOrdersTotalCount, setApprovedSalesOrdersTotalCount] = useState(0)
	const [loadingLocation, setLoadingLocation] = useState(null)
	const [locationOptions, setLocationOptions] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedSalesOrders, setSelectedSalesOrders] = useState([])
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [shouldDisableLoadingLocation, setShouldDisableLoadingLocation] = useState(false)
	const [deliveryDate, setDeliveryDate] = useState(null)
	const [step, setStep] = useState(1)
	const [isCreating, setIsCreating] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [fromDate, setFromDate] = useState(date)
	const [toDate, setToDate] = useState(date ? new Date(date.setHours(23, 59, 59, 999)) : null)
	const [showUnassigned, setShowUnassigned] = useState(shouldShowUnassigned)
	const [showAssigned, setShowAssigned] = useState(false)
	const [showStockTransferOrders, setShowStockTransferOrders] = useState(false)
	const [showSalesOrders, setShowSalesOrders] = useState(false)
	const [map, setMap] = useState()
	const [maps, setMaps] = useState()
	const [useWeight, setUseWeight] = useState(true)
	const [shouldDisableCapacitySwitch, setShouldDisableCapacitySwitch] = useState(false)
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)

	useEffect(() => {
		getSalesOrders()
	},
	[
		debouncedSearchTerm,
		toDate,
		fromDate,
		showAssigned,
		showUnassigned,
		showSalesOrders,
		showStockTransferOrders
	])

	useEffect(() => {
		dispatch(fetchUserProfile())
		fetchLocations()
		dispatch(fetchVehicleTypes())
	}, [])

	useEffect(() => {
		if (userProfile) {
			setLoadingLocation({
				placeId: 'place_id',
				address: userProfile.company.address,
				lat: userProfile.company.latitude,
				lng: userProfile.company.longitude
			})
		}
	}, [userProfile])

	useEffect(() => {
		updateMapBounds()
	}, [loadingLocation])

	const getSalesOrders = async (page) => {
		try {
			setIsLoading(true)
			const response = await SalesOrders.index(getParams(page))
			if (response.data) {
				const { results, page, totalCount } = response.data
				setApprovedSalesOrders(results)
				setApprovedSalesOrdersPage(page)
				setApprovedSalesOrdersTotalCount(totalCount)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to fetch orders.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)
		}
	}

	const getParams = (page = 0) => {
		const params = {
			status: SALES_ORDER_APPROVED_STATUS,
			page: page,
			deliveryPlanStatus: DELIVERY_PLAN_PENDING_STATUS,
			salesOrderItems: true
		}
		if (debouncedSearchTerm) {
			params.searchTerm = debouncedSearchTerm
		}
		if (fromDate && toDate) {
			params.searchTerm = debouncedSearchTerm
			params.fromDate = fromDate
			params.toDate = toDate
		}
		if (showAssigned) {
			params.unassigned = false
		}
		if (showUnassigned) {
			params.unassigned = true
		}
		if (showAssigned && showUnassigned) {
			delete params.unassigned
		}
		if (showSalesOrders) {
			params.isStockTransfer = false
		}
		if (showStockTransferOrders) {
			params.isStockTransfer = true
		}
		if (showSalesOrders && showStockTransferOrders) {
			delete params.isStockTransfer
		}
		return params
	}

	const fetchLocations = async () => {
		const response = await Locations.index()
		const results = response.data.results
		const locationOptions = results.map(result => {
			return {
				label: result.address,
				value: result.id,
				location: result
			}
		})
		setLocationOptions(locationOptions)
	}

	const onPageChanged = async (page) => {
		await getSalesOrders(page - 1)
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedSalesOrderIds(selectedRowKeys)
		setSelectedSalesOrders(selectedRows)
	}

	const createDeliveryPlan = async () => {
		try {
			if (deliveryDate && selectedSalesOrderIds.length !== 0) {
				const payload = {
					deliveryDate: deliveryDate,
					salesOrderIds: selectedSalesOrderIds,
					useWeight
				}
				if (loadingLocation.placeId !== 'place_id') {
					payload.loadingLocation = {
						address: loadingLocation.address,
						latitude: loadingLocation.lat,
						longitude: loadingLocation.lng
					}
				}
				setIsCreating(true)
				const createdDeliveryPlanResponse = await dispatch(createCustomDeliveryPlan(payload))
				setIsCreating(false)
				notification.success({
					message: `Added to Delivery Plan DP-${createdDeliveryPlanResponse.shortId}`,
					description: `Orders successfully added to Delivery Plan DP-${createdDeliveryPlanResponse.shortId}`,
					placement: 'bottomLeft'
				})
				onClose()
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Create Delivery Plan',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally { setIsCreating(false) }
	}

	const resetStates = () => {
		setDeliveryDate(null)
		setSelectedSalesOrderIds([])
		setStep(1)
		if (userProfile) {
			setLoadingLocation({
				placeId: 'place_id',
				address: userProfile.company.address,
				lat: userProfile.company.latitude,
				lng: userProfile.company.longitude
			})
		}
	}

	const onClose = () => {
		resetStates()
		onCancel()
	}

	const onContinue = () => {
		setStep(2)
		const pickUpLocations = {}
		let weightEnabled = true
		let volumeEnabled = true
		for (const salesOrder of selectedSalesOrders) {
			const pickUpLocation = salesOrder.pickUpLocation
			if (pickUpLocation) {
				pickUpLocations[pickUpLocation.id] = pickUpLocation
			}
			for (const salesOrderItem of salesOrder.salesOrderItems) {
				if (+salesOrderItem.totalWeight <= 0 && weightEnabled) {
					weightEnabled = false
				}
				if (+salesOrderItem.totalVolume <= 0 && volumeEnabled) {
					volumeEnabled = false
				}
			}
		}
		if (weightEnabled && volumeEnabled) {
			setShouldDisableCapacitySwitch(false)
		} else if (weightEnabled) {
			setUseWeight(true)
			setShouldDisableCapacitySwitch(true)
		} else if (volumeEnabled) {
			setUseWeight(false)
			setShouldDisableCapacitySwitch(true)
		} else {
			setUseWeight(true)
			setShouldDisableCapacitySwitch(true)
		}
		const disableLoadingLocation = selectedSalesOrders.every(salesOrder => salesOrder.pickUpLocation)
		setShouldDisableLoadingLocation(disableLoadingLocation)
		setPickUpLocations(Object.values(pickUpLocations))
		updateMapBounds()
	}

	const onMapsLoaded = (map, maps) => {
		setMap(map)
		setMaps(maps)
		updateMapBounds()
	}

	const updateMapBounds = () => {
		if (!maps) {
			return
		}
		if (!map) {
			return
		}
		const bounds = new maps.LatLngBounds()
		pickUpLocations.forEach(location => {
			bounds.extend({
				lat: +location.latitude,
				lng: +location.longitude
			})
		})
		if (loadingLocation) {
			bounds.extend({
				lat: +loadingLocation.lat,
				lng: +loadingLocation.lng
			})
			if (pickUpLocations.length === 0) {
				map.panTo({
					lat: +loadingLocation.lat,
					lng: +loadingLocation.lng
				})
			}
		}
		if (pickUpLocations.length > 0) {
			map.fitBounds(bounds)
		}
	}

	const onPickUpLocationClick = (location) => {
		map.panTo({
			lat: +location.latitude,
			lng: +location.longitude
		})
	}

	const renderModalFooter = (currentStep) => {
		switch (currentStep) {
			case 1:
				return [
					<Button
						key={1}
						type='primary'
						shape='round'
						size='large'
						disabled={!selectedSalesOrderIds.length}
						onClick={onContinue}
					>
						Continue
					</Button>
				]
			case 2:
				return [
					<Button
						key={2}
						type='primary'
						shape='round'
						size='large'
						onClick={() => setStep(1)}
						ghost
					>
						Back
					</Button>,
					<Button
						key={3}
						type='primary'
						shape='round'
						size='large'
						loading={isCreating}
						disabled={!selectedSalesOrderIds.length || !deliveryDate}
						onClick={createDeliveryPlan}
					>
						Add to Delivery Plan
					</Button>
				]
			default:
				return []
		}
	}

	return (
		<Modal
			title='Create Delivery Plan'
			visible={visible}
			onCancel={onClose}
			ofterClose={resetStates}
			confirmLoading={false}
			width={step === 1 ? 1300 : undefined}
			footer={renderModalFooter(step)}
			destroyOnClose={true}
		>
			{
				step === 1 &&
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}
				>
					<div
						style={{
							display: 'flex'
						}} className='top'
					>
						<div>
							<h4 style={{ textDecoration: 'underline', color: 'black', fontWeight: 'normal' }}>Filter Orders</h4>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 24
								}}
								size='large'
							>
								<SearchInput
									style={{ width: 400, height: 65 }}
									placeholder='Search Order Number, Distributor Name'
									value={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
								<Space>
									<Input
										title='From'
										type='date'
										style={{ background: 'white' }}
										value={fromDate ? moment(fromDate) : null}
										onChange={(date) => setFromDate(date?.toDate())}
										disabledDate={current => current && toDate && current.valueOf() >= moment(toDate)}
									/>
									<Input
										title='To'
										type='date'
										style={{ background: 'white' }}
										value={toDate ? moment(toDate) : null }
										onChange={(date) => setToDate(date?.toDate())}
										disabledDate={current => current && current.valueOf() <= moment(fromDate)}
									/>
								</Space>
								<Space
									size='large'
									style={{ margin: '24px 0' }}
								>
									<Space direction='vertical'>
										<Checkbox onChange={(e) => setShowSalesOrders(e.target.checked)}>
											Sales Orders
										</Checkbox>
										<Checkbox onChange={(e) => setShowStockTransferOrders(e.target.checked)}>
											Stock Transfer Orders
										</Checkbox>
									</Space>
									<Space direction='vertical'>
										<Checkbox onChange={(e) => setShowAssigned(e.target.checked)}>
											Show assigned orders
										</Checkbox>
										<Checkbox checked={showUnassigned} onChange={(e) => setShowUnassigned(e.target.checked)}>
											Show unassigned orders
										</Checkbox>
									</Space>
								</Space>
							</div>
						</div>
					</div>
					{
						isLoading || approvedSalesOrders.length > 0 ?
							<Table
								loading={isLoading}
								columns={salesOrderColumns}
								dataSource={approvedSalesOrders}
								style={{ marginBottom: 0, marginTop: 12 }}
								size='small'
								pagination={{
									pageSize: 50,
									total: approvedSalesOrdersTotalCount,
									position: ['topLeft'],
									onChange: onPageChanged,
									showSizeChanger: false,
									current: approvedSalesOrdersPage + 1
								}}
								scroll={{ y: 'calc(100vh - 600px)' }}
								rowKey='id'
								rowSelection={{
									preserveSelectedRowKeys: true,
									selectedRowKeys: selectedSalesOrderIds,
									onChange: onSelectChange
								}}
							/> :
							<Empty
								description='Try discussing internally on how we can increase sales or try asking clients on how can we add more value to them.'
								image='/img/sales-orders-empty.svg'
								imageStyle={{ height: '450px', marginBottom: '24px' }}
								style={{ marginTop: '128px' }}
							/>
					}
				</div>
			}
			{
				step === 2 &&
				<div style={{
					minHeight: '500px',
					display: 'flex',
					justifyContent: 'center'
				}}
				>
					<div style={{ width: '450px' }}>
						<Input
							width={400}
							style={{ background: 'white' }}
							value={deliveryDate ? moment(deliveryDate) : null}
							onChange={date => setDeliveryDate(date.toDate())}
							type='date'
							title={<span><span style={{ color: '#ff4d4f', marginRight: 12, fontSize: 14 }}>*</span>Delivery Date</span>}
							disabledDate={current => current && current.valueOf() < moment().subtract(1, 'd')}
						/>
						{
							!shouldDisableLoadingLocation &&
							<div className='custom-location-select-container' style={{ marginTop: 24 }}>
								<label style={{ fontSize: 12 }}>
									<span>
										<span style={{ color: '#ff4d4f', marginRight: 12, fontSize: 14 }}>*</span>
									Loading Location
										<Tooltip title='Every delivery plan needs a starting location.'>
											<QuestionCircleOutlined style={{ marginLeft: 12, color: '#00000073', fontSize: 10 }} />
										</Tooltip>
									</span>
								</label>
								<PlacesAutocomplete
									onClear={() => setLoadingLocation(null)}
									disabledNormalStyle={true}
									placeholder='Loading Location'
									country='bd'
									className='custom-location-select'
									initialOptions={locationOptions}
									value={
										loadingLocation ? {
											value: loadingLocation.placeId,
											label: loadingLocation.address
										} : null
									}
									onLocationSelected={setLoadingLocation}
								/>
							</div>
						}
						{
							pickUpLocations.length > 0 &&
							<div className={styles.pickUpLocations}>
								<label style={{ fontSize: 12 }}>
									<span>
										Pickup Locations
										<Tooltip title='Pickup locations are derived from the selected orders.'>
											<QuestionCircleOutlined style={{ marginLeft: 12, color: '#00000073', fontSize: 10 }} />
										</Tooltip>
									</span>
								</label>
								<div className={styles.locationsList}>
									{
										pickUpLocations.map(location => {
											return (
												<div
													key={location.id}
													className={styles.pickUpLocation}
													onClick={() => onPickUpLocationClick(location)}
												>
													{location.address}
												</div>
											)
										})
									}
								</div>
							</div>
						}
						<Tooltip
							title='Delivery plans can be generated by looking at the total weight or volume of the selected sales orders.'
						>
							<Switch
								className='delivery-plan-custom-switch'
								style={{ marginTop: 24 }}
								checked={useWeight}
								onChange={checked => {
									if (shouldDisableCapacitySwitch) {
										return
									}
									setUseWeight(checked)
								}}
								checkedChildren='Generate Using Weight'
								unCheckedChildren='Generate Using Volume'
							/>
						</Tooltip>
						<div style={{ marginTop: 24 }}>
							<Map
								containerStyle={{
									width: '100%',
									height: '250px'
								}}
								onMapsLoaded={onMapsLoaded}
							>
								{
									loadingLocation &&
										<LocationMarker
											lat={loadingLocation.lat}
											lng={loadingLocation.lng}
										/>
								}
								{
									pickUpLocations.map(location => {
										return (
											<LocationMarker
												isPickUp={true}
												key={location.id}
												lat={location.latitude}
												lng={location.longitude}
											/>
										)
									})
								}
							</Map>
						</div>
					</div>
				</div>
			}
		</Modal>
	)
}

CreateDeliveryPlanModal.defaultProps = {
	onCancel: () => { },
	visible: false
}

const LocationMarker = ({ isPickUp = false }) => {
	return (
		<div className={isPickUp ? styles.pickUpMarker : styles.locationMarker} />
	)
}

export default CreateDeliveryPlanModal
