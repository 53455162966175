import { useEffect, useState } from 'react'
import { Button, Divider, Modal, Space } from 'antd'
import SearchInput from '../search-input'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './InventoryFilterModal.module.css'
import { Locations } from '../../services/api/locations'
import { LocationTypes } from '../location-modal'
import CustomSearchableSelect from '../custom-searchable-select'
import Input from '../input'
import moment from 'moment'
import { convertEnumToString } from '../../utils'

const InventoryFilterModal = ({ visible, onCancel }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const [location, setLocation] = useState()
	const [locations, setLocations] = useState()
	const [isLoadingLocations, setIsLoadingLocations] = useState(false)
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)

	useEffect(() => {
		if (visible) {
			fetchWarehouses()
		}
	}, [visible])

	const fetchWarehouses = async () => {
		setIsLoadingLocations(true)
		const response = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
		if (response.data) {
			setLocations(response.data.results)
		}
		setIsLoadingLocations(false)
	}

	const searchLocations = async value => {
		return Locations.index({ searchTerm: value, types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY] })
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (location) {
			searchParams.locationId = location.value
		}
		if (fromDate) {
			searchParams.updatedFrom = fromDate
		}
		if (toDate) {
			searchParams.updatedTo = toDate
		}
		setSearchParams(searchParams)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setLocation(null)
		setFromDate(null)
		setToDate(null)
		clearSearchParams()
	}

	const isResetButtonDisabled = () => {
		return !searchTerm && !location && !fromDate && !toDate
	}

	const isApplyButtonEnabled = () => {
		return searchTerm || location || fromDate && toDate
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						disabled={isResetButtonDisabled()}
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isApplyButtonEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Product Name, SKU, Barcode Number or Pallet Number'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<div style={{ marginTop: 20 }} />
					<CustomSearchableSelect
						title='Warehouse'
						searchTask={searchLocations}
						defaultOptions={locations}
						isLoading={isLoadingLocations}
						customLabel={(data) => data.label ? data.label : convertEnumToString(data.type)}
						valueIndex='id'
						descriptionIndex='address'
						placeholder='Select Warehouse'
						onChange={location => setLocation(location)}
						onClear={() => setLocation(null)}
						style={{ width: 494 }}
						value={location}
					/>
					<div className={styles.section}>
						<h3 className={styles.label}>Updated At</h3>
						<Space>
							<Input
								style={{ maxWidth: 170 }}
								title='From'
								type='date'
								value={fromDate ? moment(fromDate) : null }
								onChange={(date) => setFromDate(date?.toDate())}
							/>
							<Divider>
								<div className={styles.dash} />
							</Divider>
							<Input
								style={{ maxWidth: 170 }}
								title='To'
								type='date'
								value={toDate ? moment(toDate) : null }
								onChange={(date) => setToDate(date?.toDate())}
								disabledDate={current => current && current.valueOf() < moment(fromDate)}
							/>
						</Space>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default InventoryFilterModal
