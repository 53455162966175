import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'

const UploadInternalOrderIdsModal = ({ visible, onCancel, onComplete }) => {
	return (
		<UploadCsvModal
			title='Replace Order Numbers'
			type='order-internal-ids'
			fields={[
				{ label: 'Existing Order Number', key: 'internalId' },
				{ label: 'Updated Order Number', key: 'newInternalId' }
			]}
			firstStepPrompt='Upload a CSV or Excel file to replace order numbers in bulk.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			uploadTask={Upload.uploadOrderIdsCsv}
			disableSteps={true}
		/>
	)
}

export default UploadInternalOrderIdsModal
