import { Dashboard } from '../../services/api/dashboard'
import {
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS,
	FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS,
	FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS,
	FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS,
	SCAN_ADHERENCE_SUMMARY_SUCCESS,
	SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS,
	SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS
} from './action-types'

export function fetchGeneralAnalyticsStatistics () {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsStatistics()
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_STATISTICS_SUCCESS, payload: response.data })
	}
}

export function fetchGeneralAnalyticsOrderRate (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderRate(interval)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_RATE_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchGeneralAnalyticsOrderCycleTime ({ interval, fromDate, toDate, searchTerm }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderCycleTime(interval, fromDate, toDate, searchTerm)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_CYCLE_TIME_SUCCESS, payload: { data: response.data, interval, fromDate, toDate, searchTerm } })
	}
}

export function fetchGeneralAnalyticsOrderLeadTime ({ interval, leadTimeDate }) {
	return async (dispatch) => {
		const response = await Dashboard.fetchGeneralAnalyticsOrderLeadTime(interval, leadTimeDate)
		dispatch({ type: FETCH_DASHBOARD_GENERAL_ANALYTICS_ORDER_LEAD_TIME_SUCCESS, payload: { data: response.data, interval, leadTimeDate } })
	}
}

export function fetchSalesAnalyticsOrders (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsOrders(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_ORDERS_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDistributorGrowth (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDistributorGrowth(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_GROWTH_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDivisionSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDivisionSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DIVISION_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsItemSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsItemSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_ITEM_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchSalesAnalyticsDistributorSales (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchSalesAnalyticsDistributorSales(interval)
		dispatch({ type: FETCH_DASHBOARD_SALES_ANALYTICS_DISTRIBUTOR_SALES_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchOrdersAssignedAndCompletedRatio (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchOrdersAssignedAndCompletedRatio(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_ORDER_ASSIGNED_AND_COMPLETED_RATIO_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchCollectedAmountRatio (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchCollectedAmountRatio(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_TOTAL_AND_COLLECTED_AMOUNT_RATIO_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchDeliveryPartnerByLocation (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchDeliveryPartnerByLocation(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DELIVERY_PARTNER_BY_LOCATION_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchDistanceTraveledByDeliveryPartner (interval) {
	return async (dispatch) => {
		const response = await Dashboard.fetchDistanceTraveledByDeliveryPartner(interval)
		dispatch({ type: FETCH_DASHBOARD_DELIVERY_PARTNER_ANALYTICS_DISTANCE_TRAVELED_BY_DELIVERY_PARTNER_SUCCESS, payload: { data: response.data, interval } })
	}
}

export function fetchScanAdherenceSummary (params) {
	return async (dispatch) => {
		const response = await Dashboard.fetchScanAdherenceSummary(params)
		dispatch({ type: SCAN_ADHERENCE_SUMMARY_SUCCESS, payload: response.data })
	}
}

export function fetchScanAdherenceWarehouseWiseScanMissed (params) {
	return async (dispatch) => {
		const response = await Dashboard.fetchScanAdherenceWarehouseWiseScanMissed(params)
		dispatch({ type: SCAN_ADHERENCE_WAREHOUSE_WISE_SCAN_MISSED_SUCCESS, payload: response.data })
	}
}
export function fetchScanAdherenceNationwideScanMissed (params) {
	return async (dispatch) => {
		const response = await Dashboard.fetchScanAdherenceNationwideScanMissed(params)
		dispatch({ type: SCAN_ADHERENCE_NATIONWIDE_SCAN_MISSED_SUCCESS, payload: response.data })
	}
}
