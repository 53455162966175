import moment from 'moment'
import { getSubdomain } from '../../config'
import { get } from './index'

const getBaseUrl = () => {
	// Indicates that we are running locally.
	if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
		return 'http://localhost:3002/'
	} else {
		return `https://${getSubdomain()}pdf-api.nuport.io`
	}
}

export const Pdf = {
	downloadSalesOrder: async (id, timezone) => {
		const response = await get(`/sales-orders/${id}`, {
			baseURL: getBaseUrl(),
			responseType: 'blob',
			params: {
				timezone
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `sales-order-${id}.pdf`
		link.click()
		link.remove()
	},
	downloadSalesOrders: async ({ toDate, fromDate, statuses, isStockTransfer, dateRangeType, columns }, timezone) => {
		const response = await get('/sales-orders', {
			baseURL: getBaseUrl(),
			responseType: 'blob',
			params: {
				timezone,
				statuses,
				toDate,
				fromDate,
				isStockTransfer,
				dateRangeType,
				columns
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		const fileName = `sales-orders_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}_${statuses.join('_')}.pdf`
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadReportDetails: async (id, timezone) => {
		const response = await get(`/reports/${id}`, {
			baseURL: getBaseUrl(),
			responseType: 'blob',
			params: {
				timezone
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		const fileName = `reports_${moment().format('MMM_DD_YYYY')}.pdf`
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadDeliveryPlan: async (id, timezone) => {
		const response = await get(`/delivery-plans/${id}`, {
			baseURL: getBaseUrl(),
			responseType: 'blob',
			params: {
				timezone
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `delivery-plan-${id}.pdf`
		link.click()
		link.remove()
	}
}
