import { get, post, destroy, patch } from './index'

export const DeliveryPlans = {
	index: ({
		page = 0,
		status,
		searchParams
	} = {}) =>
		get('/delivery-plans', {
			params: {
				page,
				status,
				...searchParams
			}
		}),
	single: (id, {
		accessToken
	} = {}) =>
		get(`/delivery-plans/${id}`, {
			headers: {
				authorization: accessToken
			}
		}),
	create: (params) =>
		post('/delivery-plans', params),
	update: (id, {
		status
	} = {}) =>
		patch(`/delivery-plans/${id}`, {
			status
		}),
	updateDeliveryPlanSalesOrders: (id, {
		salesOrderIds
	} = {}) =>
		patch(`/delivery-plans/${id}/sales-orders`, {
			salesOrderIds
		}),
	updateDeliveryPlanRoutes: (id, {
		routes,
		salesOrderIds
	} = {}) =>
		patch(`/delivery-plans/${id}/routes`, {
			routes,
			salesOrderIds
		}),
	updateDeliveryPlanItem: (id, params) =>
		patch(`/delivery-plans/delivery-plan-items/${id}`, params),
	updateDeliveryPlanItemStatus: (id, params) =>
		patch(`/delivery-plans/delivery-plan-items/${id}/status`, params),
	remove: (id) =>
		destroy(`/delivery-plans/${id}`),
	regenerateDeliveryPlan: (id) =>
		post(`/delivery-plans/${id}/regenerate`),
	singleDeliveryPlanItem: (id, {
		accessToken
	} = {}) =>
		get(`/delivery-plans/delivery-plan-items/${id}`, {
			headers: {
				authorization: accessToken
			}
		}),
	updateVehicleAssignmentStatus: (requestId, params) =>
		patch(`/requests/${requestId}/status`, params)
}
