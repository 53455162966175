import { Upload, message, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
require('./ImageUpload.less')
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import Cropper from 'react-cropper'
import Head from 'next/head'

const ImageUpload = ({ title, width, height, image, onImageUploaded, pathname }) => {
	const [fileList, setFileList] = useState([])
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [imgSrc, setImgSrc] = useState('')
	const [croppedImgData, setCroppedImgData] = useState('')
	const cropperRef = useRef (null)

	useEffect(() => {
		if (image) {
			setFileList([
				{
					uid: '-1',
					name: 'company-logo',
					status: 'done',
					url: image
				}
			])
		} else {
			setFileList([])
		}
	}, [image])

	const validateFile = (file, showMessage = true) => {
		const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || 'image/svg'
		if (!isJpgOrPngOrSvg && showMessage) {
			message.error('You can only upload JPG/PNG/SVG file.')
		}
		const isLt2M = file.size / 1024 / 1024 < 2
		if (!isLt2M && showMessage) {
			message.error('Image must be smaller than 2MB.')
		}
		return isJpgOrPngOrSvg && isLt2M
	}

	const beforeUpload = (file) => {
		const reader = new FileReader()
		reader.onload = e =>
			setImgSrc(e.target.result)
		reader.readAsDataURL(file)

		return validateFile(file)
	}

	const onChange = ({ fileList: newFileList }) => {
		if (newFileList.length === 0) {
			setFileList(newFileList)
			if (onImageUploaded) {
				onImageUploaded(null)
			}
		} else {
			const newFile = newFileList[0]
			if (validateFile(newFile, false)) {
				setFileList(newFileList)

				if (!newFileList[0].type.includes('svg'))
					setIsModalVisible(true)
			}
		}
	}

	const onPreview = async () => {
		const image = new Image()
		image.src = croppedImgData
		const imgWindow = window.open()

		if (imgWindow) {
			imgWindow.document.write(image.outerHTML)
		} else {
			window.location.href = undefined
		}
	}

	const onFileUpload = async ({ onSuccess, onError, onProgress, file }) => {
		const fileName = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
		const storageRef = firebase.storage().ref()
		const docRef = firebase.firestore().collection(pathname).doc()
		const uploadTask = storageRef.child(`${pathname}/${docRef.id}`).put(file, {
			customMetadata: {
				name: fileName
			},
			cacheControl: 'public,max-age=31536000'
		})
		uploadTask.on('state_changed', snapshot => {
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
			switch (snapshot.state) {
				case firebase.storage.TaskState.PAUSED:
					break
				case firebase.storage.TaskState.RUNNING:
					onProgress({ percent: progress })
					break
			}
		}, error => {
			onError({ err: error })
		}, async () => {
			const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
			file.url = downloadUrl
			if (onImageUploaded) {
				onImageUploaded(downloadUrl)
			}
			onSuccess(undefined, file)
		})
	}

	const handleCrop = () => {
		const imageElement = cropperRef?.current
		const cropper = imageElement?.cropper
		setCroppedImgData(cropper.getCroppedCanvas().toDataURL())
	}

	return (
		<>
			<Head>
				<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/cropperjs/2.0.0-alpha.2/cropper.css' />
			</Head>
			<Upload
				accept='image/*, .png, .jpg, .jpeg, .svg'
				onChange={onChange}
				fileList={fileList}
				onPreview={onPreview}
				beforeUpload={(file) => beforeUpload(file, false) }
				customRequest={onFileUpload}
				listType='picture-card'
				className='upload-container'
			>
				{
					fileList.length < 1 &&
					<div className='upload-container'>
						<PlusOutlined style={{ marginBottom: 12 }} />
						{title}
						<div className='heading'>{`(${width} x ${height})`}</div>
					</div>
				}
			</Upload>
			<Modal
				title='Crop Image'
				visible={isModalVisible}
				onOk={() => {
					const croppedImage = { ...fileList[0], thumbUrl: croppedImgData }

					setFileList([croppedImage])
					setIsModalVisible(false)
				}}
				onCancel={() => {
					setFileList([])
					setIsModalVisible(false)
				}}
			>
				<Cropper
					style={{ height: 'auto', width: '100%' }}
					zoomTo={0}
					src={imgSrc}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					autoCropArea={1}
					checkOrientation={false}
					responsive
					ref={cropperRef}
					ready={handleCrop}
					cropend={handleCrop}
				/>
			</Modal>
		</>
	)
}

export default ImageUpload
