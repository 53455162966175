import { HYDRATE } from 'next-redux-wrapper'
import { FETCH_LOCATIONS_SUCCESS, FETCH_WAREHOUSE_LOCATIONS_SUCCESS } from './action-types'

const initialState = {
	locations: [],
	totalCount: 0,
	page: 0,
	warehouses: [],
	warehousesTotalCount: 0,
	warehousesPage: 0
}

const locationsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_LOCATIONS_SUCCESS: {
			return {
				...state,
				locations: payload.data.results,
				totalCount: payload.data.count,
				page: payload.data.page
			}
		}
		case FETCH_WAREHOUSE_LOCATIONS_SUCCESS: {
			return {
				...state,
				warehouses: payload.data.results,
				warehousesTotalCount: payload.data.count,
				warehousesPage: payload.data.page
			}
		}
		default:
			return state
	}
}

export default locationsReducer
