/* eslint-disable react/jsx-wrap-multilines */
import { Menu, Radio, Empty, Spin, Progress } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styles from './NotificationsTray.module.css'
require('./NotificationsTray.less')
import moment from 'moment'
import { fetchNotifications, fetchUnreadNotifications, readNotification } from '../../store/notifications/actions'
import { useEffect, useState } from 'react'
import { Auth } from '../../services/api/firebase'
import { useRouter } from 'next/router'
import { paths } from '../../utils/constants'

const notificationOptions = [
	{
		label: 'Unread',
		value: 'unread_notifications'
	},
	{
		label: 'All',
		value: 'notifications'
	}
]

export const getNotificationArgs = notification => {
	const notificationArgs = {
		message: notification.message.en,
		description: notification.description.en,
		placement: 'bottomLeft',
		duration: 3
	}
	// Customize notifications based on the event that occurred for the notification.
	switch (notification.key) {
		case 'delivery-plans_finished_processing_succeeded':
		case 'delivery-plans_finished_processing_failed': {
			notificationArgs.duration = 5
			notificationArgs.description =
				<div style={{ display: 'flex', marginTop: 12, alignItems: 'center' }}>
					<Progress
						width={28}
						percent={100}
						status={notification.key === 'delivery-plans_finished_processing_failed' ? 'exception' : undefined}
						type='circle'
						style={{ marginRight: 12 }}
					/>
					<div className={styles.description}>{notification.description.en}</div>
				</div>
			break
		}
	}
	return notificationArgs
}

const NotificationsTray = (props) => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { unreadNotifications, notifications, count } = useSelector(state => state.notificationsReducer)
	const [notificationOption, setNotificationOption] = useState(notificationOptions[0].value)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		getNotifications()
	}, [])

	const getNotifications = async () => {
		setIsLoading(true)
		const user = await Auth.getCurrentUser()
		await dispatch(fetchUnreadNotifications(user.uid))
		setIsLoading(false)
	}

	const onTabChanged = async e => {
		const value = e.target.value
		setNotificationOption(value)
		setIsLoading(true)
		const user = await Auth.getCurrentUser()
		if (value === 'unread_notifications') {
			await dispatch(fetchUnreadNotifications(user.uid))
		} else {
			await dispatch(fetchNotifications(user.uid))
		}
		setIsLoading(false)
	}

	const onReadNotification = async (id) => {
		await dispatch(readNotification(id))
	}

	const onNotificationClick = notification => {
		onReadNotification(notification.id)
		if (notification.key.startsWith('delivery-plans')) {
			router.push(`${paths.DELIVERY_PLANS}/${notification.data.id}`)
		} else if (notification.key.startsWith('sales-orders_staleness') || notification.key.startsWith('sales-orders_stock_transfer_staleness')) {
			router.push(`${paths.SALES_ORDERS}?status=${notification.data.status.toLowerCase()}`)
		} else if (notification.key.startsWith('sales-orders_sales_orders_unassigned') || notification.key.startsWith('sales-orders_stock_transfer_sales_orders_unassigned')) {
			const date = notification.data.date.toDate()
			const dateStr = date.toISOString().split('T')[0]
			router.push(`${paths.DELIVERY_PLANS}?createDeliveryPlan=true&createDeliveryPlanDate=${dateStr}`)
		} else if (notification.key.startsWith('sales-orders_')) {
			if (notification.data.id) {
				router.push(`${paths.SALES_ORDERS}/${notification.data.id}`)
			}
		} else if (notification.key.startsWith('vehicles_')) {
			if (notification.data.id) {
				router.push(`${paths.FLEET}/${notification.data.id}`)
			}
		} else if (notification.key.startsWith('requests_requested_vehicle')) {
			if (notification.data.deliveryPlanId) {
				router.push(`${paths.DELIVERY_PLANS}?expandedId=${notification.data.deliveryPlanId}`)
			}
		}
	}

	const notificationItems = notificationOption === 'unread_notifications' ? unreadNotifications : notifications
	return (
		<Menu {...props} className={styles.notificationsTray}>
			{
				count > 0 ?
					<>
						<Radio.Group
							className={styles.notificationHeader}
							size='small'
							buttonStyle='solid'
							optionType='button'
							options={notificationOptions}
							value={notificationOption}
							onChange={onTabChanged}
						/>
						{
							notificationItems.length > 0 ?
								notificationItems.map(notification => {
									return (
										<Menu.Item
											onClick={() => onNotificationClick(notification)}
											key={notification.id} className={styles.notification}
										>
											<div
												onClick={(e) => {
													e.stopPropagation()
													onReadNotification(notification.id)
												}}
												style={{ visibility: notification.isRead ? 'hidden' : 'visible' }}
												className={styles.unreadIndicator}
											/>
											<div className={styles.notificationContainer}>
												<div className={styles.message}>
													{notification.message.en}
												</div>
												<div className={styles.description}>
													{getNotificationArgs(notification).description}
												</div>
												<div className={styles.date}>
													{moment(notification.createdAt).fromNow()}
												</div>
											</div>
										</Menu.Item>
									)
								}) :
								notificationItems.length === 0 && isLoading ?
									<div className={styles.loadingContainer}>
										<Spin size='large' />
									</div> :
									notificationOption === 'unread_notifications' ?
										<Empty style={{ width: 350, padding: 12 }} description='No Unread Notifications' /> :
										<Empty style={{ width: 350, padding: 12 }} description='No Notifications' />
						}
					</> :
					<Empty description='No Notifications' />
			}
		</Menu>
	)
}

export default NotificationsTray
