
import { get, patch, post } from './index'

export const Permissions = {
	fetchDefaultPermissions: (role = 'USER') => get('/permissions/default', {
		params: {
			role
		}
	}),
	createPermissionGroup: ({
		label,
		permissions
	}) =>
		post('/permissions', {
			label,
			permissions
		}),
	updatePermissionGroup: (id, data) => patch(`/permissions/groups/${id}`, data),
	fetchPermissionGroups: ({ includeDefaultGroups } = {}) => get('/permissions/groups', {
		params: {
			includeDefaultGroups
		}
	}),
	fetchPermissionGroupDetails: (id) => get(`/permissions/groups/${id}`),
	fetchPermissions: () => get('/permissions'),
	fetchUserPermissions: (uid) => get(`/permissions/${uid}`),
	updatePermissions: (uid, permissions) => patch(`/permissions/${uid}`, permissions)
}
