import { Button, Modal, notification, Progress } from 'antd'
import styles from './UploadCsvModal.module.css'
import { DownloadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Tasks } from '../../services/api/firebase'
import { useSelector } from 'react-redux'
import { FlatfileButton } from '@flatfile/react'

const UploadCsvModal = ({
	title,
	type,
	fields = [],
	fieldHooks = {},
	firstStepPrompt,
	secondStepPrompt,
	visible,
	onCancel,
	downloadTask,
	uploadTask,
	onComplete,
	allowCustom = false,
	importOnly = false, // Flag indicates we do not proceed with uploading the data.
	disableSteps = false
}) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
	const [file, setFile] = useState()
	const [importedValues, setImportedValues] = useState([])
	const [isUploadingCsv, setIsUploadingCsv] = useState(false)
	const [logs, setLogs] = useState([])
	const [taskId, setTaskId] = useState()
	const [progress, setProgress] = useState(0)
	const [status, setStatus] = useState()

	useEffect(() => {
		if (taskId) {
			const unsubscribeTask = Tasks.listenToTask(
				userProfile.companyId,
				taskId,
				task => {
					setProgress(task.progress)
					switch (task.loadState) {
						case 'SUCCEEDED':
							setStatus('completed')
							unsubscribeTask()
							if (task.logs) {
								setLogs(logs => [...logs, ...task.logs])
							}
							break
						case 'FAILED':
							setStatus('exception')
							unsubscribeTask()
							break
						default:
							setStatus('active')
							if (task.logs) {
								setLogs(logs => [...logs, ...task.logs])
							}
							break
					}
				}
			)
			return () => {
				unsubscribeTask()
			}
		}
	}, [taskId])

	const downloadCsv = async () => {
		setIsDownloadingCsv(true)
		if (downloadTask) {
			await downloadTask()
		}
		setIsDownloadingCsv(false)
	}

	const onUploadFile = async () => {
		try {
			if (importOnly) {
				if (onComplete) {
					onComplete(importedValues)
				}
			} else {
				setIsUploadingCsv(true)
				setLogs([])
				if (uploadTask) {
					const response = await uploadTask(file)
					const { taskId, errorMessages } = response.data
					setLogs(errorMessages)
					if (errorMessages.length === 0) {
						setTaskId(taskId)
						setStatus('active')
					}
				}
			}
		} catch (e) {
			setLogs([])
			notification.error({
				message: 'File Upload Failed',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUploadingCsv(false)
		}
	}

	const onOk = () => {
		if (onComplete) {
			onComplete()
		}
		onCancel()
	}

	const parseResults = (results) => {
		const { fileName, validData } = results
		return new Promise(resolve => {
			const data = [].concat.apply([], validData)
			if (data.length > 0) {
				const headerRow = data[0]
				let header = Object.keys(headerRow)
				if (headerRow.$custom) {
					header = [...header, ...Object.keys(headerRow.$custom)].filter(key => key !== '$custom')
				}
				const csv = [
					header.join(','),
					...data.map(row => {
						if (row.$custom) {
							const custom = { ...row.$custom }
							delete row.$custom
							row = {
								...custom,
								...row
							}
						}
						return header
							.map(fieldName => JSON.stringify(row[fieldName], (key, value) => value === null || value === undefined ? '' : value).replace(/\\"/g, '""'))
							.join(',')
					})
				].join('\r\n')
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
				const file = new File([blob], fileName, { type: 'text/csv' })
				resolve(file)
			} else {
				resolve()
			}
		})
	}

	const isLoading = isUploadingCsv || (taskId !== undefined && status === 'active')
	const isFinished = taskId !== undefined && status !== 'active'
	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={onCancel}
			maskClosable={false}
			okText={isUploadingCsv ? 'Validating' : isLoading ? 'Uploading...' : isFinished ? 'OK' : importOnly ? 'Import' : 'Upload'}
			okButtonProps={{
				loading: isLoading,
				disabled: !file
			}}
			closable={!isLoading}
			onOk={isFinished ? onOk : onUploadFile}
			cancelButtonProps={{
				disabled: isLoading
			}}
			width={720}
		>
			<div>
				<div className={styles.uploadContainer}>
					<div style={{ display: 'flex' }}>
						<div className={styles.item}>
							<div>
								{
									!disableSteps &&
									<span style={{ marginRight: 12 }}><b>1.</b></span>
								}
								<span>{firstStepPrompt}</span>
							</div>
							{
								!disableSteps &&
								<Button
									onClick={downloadCsv}
									loading={isDownloadingCsv}
									style={{ marginTop: 24 }}
									icon={<DownloadOutlined />}
								>
								Download CSV
								</Button>
							}
						</div>
						{
							!disableSteps &&
							<div className={styles.item}>
								<div>
									<span style={{ marginRight: 12 }}><b>2.</b></span>
									<span>{secondStepPrompt}</span>
								</div>
							</div>
						}
					</div>
					<div className={styles.uploadItem}>
						<div>
							<div>
								{
									!disableSteps &&
									<span style={{ marginRight: 12 }}><b>3.</b></span>
								}
								<span>Start importing your data.</span>
							</div>
							<div className={styles.import}>
								<FlatfileButton
									licenseKey={process.env.NEXT_PUBLIC_FLAT_FILE_KEY}
									customer={{
										companyId: userProfile.company.id,
										companyName: userProfile.company.name,
										email: userProfile.email,
										name: userProfile.name,
										userId: userProfile.id
									}}
									settings={{
										type,
										title,
										fields,
										managed: true,
										devMode: false,
										allowCustom,
										theme: {
											buttons: {
												primary: {
													backgroundColor: '#278EA5',
													border: '1px solid #278EA5'
												}
											}
										}
									}}
									fieldHooks={fieldHooks}
									onData={async (results) => {
										if (importOnly) {
											const { validData, fileName } = results
											setImportedValues(validData)
											setFile({
												name: fileName
											})
										} else {
											const file = await parseResults(results)
											setFile(file)
										}
										return 'Done Validating Import!'
									}}
									render={(importer, launch) => {
										return (
											<Button
												disabled={file !== undefined}
												size='large'
												type='primary'
												shape='round'
												onClick={launch}
											>
												Get Started
											</Button>
										)
									}}
								/>
								{
									file &&
									<div className={styles.file}>
										{file.name}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				{
					taskId &&
					<Progress percent={progress} format={percent => `${Math.round(percent)} %`} size='small' status={status} style={{ marginTop: 24 }} />
				}
				{
					logs && logs.length > 0 ?
						<div className={styles.output}>
							{
								logs.map((data, index) => {
									return (
										<div key={index} className={styles.outputRow}>
											{data}
										</div>
									)
								})
							}
						</div> : null
				}
			</div>
		</Modal>
	)
}

export default UploadCsvModal
