import React from 'react'
import GoogleMapReact from 'google-map-react'
import MapStyles from '../../styles/map-styles.json'
require('./Map.less')

const Map = ({
	containerStyle,
	children,
	onMapsLoaded,
	center = { lat: 23.8103, lng: 90.4125 },
	draggableCursor = 'grab',
	onMapClick = () => {}
}) => {
	return (
		<div className='custom-map' style={containerStyle}>
			<GoogleMapReact
				options={{
					styles: MapStyles,
					clickableIcons: false,
					draggableCursor
				}}
				bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_MAPS_API_KEY, libraries: ['places', 'geometry'] }}
				defaultCenter={{ lat: 23.8103, lng: 90.4125 }}
				defaultZoom={11}
				onGoogleApiLoaded={({ map, maps }) => {
					onMapsLoaded ? onMapsLoaded(map, maps) : null
				}}
				center={center}
				yesIWantToUseGoogleMapApiInternals
				onClick={onMapClick}
			>
				{children}
			</GoogleMapReact>
		</div>
	)
}

export default Map
