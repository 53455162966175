import { init } from 'next-firebase-auth'
import { apiClient } from '../services/api'

const initAuth = () => {
	init({
		debug: false,
		authPageURL: '/login',
		appPageURL: '/',
		firebaseClientInitConfig: {
			apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY ? process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY : 'default',
			authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
			databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
			projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
			storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
		},
		firebaseAuthEmulatorHost: process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST,
		cookies: {
			name: 'Nuport',
			signed: false
		},
		tokenChangedHandler: async (authUser) => {
			apiClient.interceptors.request.use(
				async config => {
					const token = await authUser.getIdToken()
					config.headers.authorization = token
					return config
				})
		}
	})
}

export default initAuth
