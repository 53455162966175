import { useState, useEffect } from 'react'
import { Button, Divider, Modal, Radio, Space } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import {
	DELIVERY_PLAN_PENDING_STATUS,
	DELIVERY_PLAN_APPROVED_STATUS,
	DELIVERY_PLAN_SCHEDULED_STATUS,
	DELIVERY_PLAN_DELIVERED_STATUS,
	VIEW_MODE
} from '../../pages/delivery-plans'
import styles from './DeliveryPlanFilterModal.module.css'
import useSearchParams from '../../hooks/useSearchParams'


const DeliveryPlanFilterModal = ({ visible, onCancel }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [minSalesOrderCount, setMinSalesOrderCount] = useState()
	const [maxSalesOrderCount, setMaxSalesOrderCount] = useState()
	const [minOrderValue, setMinOrderValue] = useState()
	const [maxOrderValue, setMaxOrderValue] = useState()
	const [minDistributorCount, setMinDistributorCount] = useState()
	const [maxDistributorCount, setMaxDistributorCount] = useState()
	const [searchTerm, setSearchTerm] = useState('')
	const [status, setStatus] = useState()
	const { setSearchParams, clearSearchParams, isSearchApplied } = useSearchParams()
	const router = useRouter()
	const { query } = router

	useEffect(() => {
		if (query.status) {
			setStatus(query.status)
		}
	}, [query.status])

	useEffect(() => {
		if (!isSearchApplied) {
			resetFilter()
		}
	}, [isSearchApplied])

	const isFilterEnabled = () => {
		if (
			searchTerm || (fromDate && toDate) ||
			(minSalesOrderCount !== undefined && maxSalesOrderCount !== undefined) ||
			(minDistributorCount !== undefined && maxDistributorCount !== undefined) ||
			(minOrderValue !== undefined && maxOrderValue !== undefined)
		) {
			if (fromDate || toDate) {
				if (!(fromDate && toDate)) {
					return false
				}
			}
			if (minSalesOrderCount !== undefined || maxSalesOrderCount !== undefined) {
				if (!(minSalesOrderCount !== undefined && maxSalesOrderCount !== undefined)) {
					return false
				}
			}
			if (minDistributorCount !== undefined || maxDistributorCount !== undefined) {
				if (!(minDistributorCount !== undefined && maxDistributorCount !== undefined)) {
					return false
				}
			}
			if (minOrderValue !== undefined || maxOrderValue !== undefined) {
				if (!(minOrderValue !== undefined && maxOrderValue !== undefined)) {
					return false
				}
			}
			return true
		}

		return false
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}

		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (!isNaN(minSalesOrderCount) && !isNaN(maxSalesOrderCount)) {
			searchParams.minSalesOrderCount = minSalesOrderCount
			searchParams.maxSalesOrderCount = maxSalesOrderCount
		}
		if (!isNaN(minOrderValue) && !isNaN(maxOrderValue)) {
			searchParams.minOrderValue = minOrderValue
			searchParams.maxOrderValue = maxOrderValue
		}
		if (!isNaN(minDistributorCount) && !isNaN(maxDistributorCount)) {
			searchParams.minDistributorCount = minDistributorCount
			searchParams.maxDistributorCount = maxDistributorCount
		}
		setSearchParams(searchParams)
		if (status) {
			router.push(`/delivery-plans?status=${status}&view=${VIEW_MODE.LIST}`)
		}
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setMinSalesOrderCount()
		setMaxSalesOrderCount()
		setMinDistributorCount()
		setMaxDistributorCount()
		setMinOrderValue()
		setMaxOrderValue()
		setStatus(query.status)
		clearSearchParams()
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okButtonProps={{
				disabled: false
			}}
			okText='Apply'
			footer={
				[
					<Button
						style={{ marginRight:'272px' }}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Delivery Plan Numbers, Dealer Name'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setStatus(e.target.value)}
						value={status}
					>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={DELIVERY_PLAN_PENDING_STATUS}>Pending Approval</Radio>
							<Radio value={DELIVERY_PLAN_APPROVED_STATUS}>Approved</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={DELIVERY_PLAN_SCHEDULED_STATUS}>Scheduled</Radio>
							<Radio value={DELIVERY_PLAN_DELIVERED_STATUS}>Delivered</Radio>
						</div>
					</Radio.Group>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Total Order Count</h3>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Min'
							value={minSalesOrderCount}
							onChange={(value) => setMinSalesOrderCount(Math.trunc(+value))}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Max'
							value={maxSalesOrderCount}
							onChange={(value) => setMaxSalesOrderCount(Math.trunc(+value))}
						/>
					</Space>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Total Order Value (BDT)</h3>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Min'
							value={minOrderValue}
							onChange={(value) => setMinOrderValue(+value)}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Max'
							value={maxOrderValue}
							onChange={(value) => setMaxOrderValue(+value)}
						/>
					</Space>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Total Distributor Count</h3>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Min'
							value={minDistributorCount}
							onChange={(value) => setMinDistributorCount(Math.trunc(+value))}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							type='number'
							title='Max'
							value={maxDistributorCount}
							onChange={(value) => setMaxDistributorCount(Math.trunc(+value))}
						/>
					</Space>
				</div>
			</div>
		</Modal>
	)
}

export default DeliveryPlanFilterModal
