import Page from '../../components/page'
import styles from './sales-orders.module.css'
require('./sales-orders.less')
import { useRouter } from 'next/router'
import { Button, Empty, Tooltip, Dropdown, Menu, Checkbox, Popover } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSalesOrders } from '../../store/sales-orders/actions'
import Table from '../../components/table'
import moment from 'moment'
import { convertEnumToString } from '../../utils'
import Link from 'next/link'
import { ExportOutlined, UploadOutlined } from '@ant-design/icons'
import useSearchParams from '../../hooks/useSearchParams'
import SalesOrdersExportModal from '../../components/sales-orders-export-modal'
import { getShortId } from '../../utils/sales-orders'
import { Industry } from '../../components/company-settings'
import { paths } from '../../utils/constants'
import UploadOrdersModal from '../../components/upload-orders-modal'
import { LocationTypes } from '../../components/location-modal'
import HeaderTab from '../../components/header-tab'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import UploadInternalOrderIdsModal from '../../components/upload-internal-order-ids-modal'

export const SALES_ORDER_REQUESTED_STATUS = 'requested'
export const SALES_ORDER_FLAGGED_STATUS = 'flagged'
export const SALES_ORDER_APPROVED_STATUS = 'approved'
export const SALES_ORDER_PROCESSING_STATUS = 'processing'
export const SALES_ORDER_COMPLETED_STATUS = 'completed'
export const SALES_ORDER_CANCELLED_STATUS = 'cancelled'

export const SALES_ORDER_SHIPPED_SUB_STATUS = 'shipped'
export const SALES_ORDER_IN_TRANSIT_SUB_STATUS = 'in_transit'
export const SALES_ORDER_UNLOADED_SUB_STATUS = 'unloaded'
export const SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS = 'payment_collected'

export const EXPORT_TYPE = {
	PDF: 'PDF',
	CSV: 'CSV'
}

export const SALES_ORDER_STATUS_BACKGROUND_COLORS = {
	[SALES_ORDER_REQUESTED_STATUS]: '#1890ff',
	[SALES_ORDER_FLAGGED_STATUS]: '#f5222d',
	[SALES_ORDER_APPROVED_STATUS]: '#52c41a',
	[SALES_ORDER_PROCESSING_STATUS]: '#F5C451',
	[SALES_ORDER_COMPLETED_STATUS]: '#278EA5',
	[SALES_ORDER_CANCELLED_STATUS]: '#d9d9d9'
}

export const SALES_ORDER_STATUS_COLORS = {
	[SALES_ORDER_REQUESTED_STATUS]: '#1F4286',
	[SALES_ORDER_FLAGGED_STATUS]: '#FF5959',
	[SALES_ORDER_APPROVED_STATUS]: '#49BEB7',
	[SALES_ORDER_PROCESSING_STATUS]: '#288EA5',
	[SALES_ORDER_COMPLETED_STATUS]: '#085F63',
	[SALES_ORDER_CANCELLED_STATUS]: '#FF0000'
}

export const SALES_ORDER_SUB_STATUS_COLORS = {
	[SALES_ORDER_SHIPPED_SUB_STATUS]: '#00C0C1',
	[SALES_ORDER_IN_TRANSIT_SUB_STATUS]: '#2D9CDB',
	[SALES_ORDER_UNLOADED_SUB_STATUS]: '#00BA88'
}

export const PROCESSING_SUB_STATUSES = [
	SALES_ORDER_SHIPPED_SUB_STATUS,
	SALES_ORDER_IN_TRANSIT_SUB_STATUS,
	SALES_ORDER_UNLOADED_SUB_STATUS
]

export const COMPLETED_SUB_STATUSES = [
	SALES_ORDER_PAYMENT_COLLECTED_SUB_STATUS
]

export const STATUS_NAMES = {
	[SALES_ORDER_REQUESTED_STATUS]: 'Requested',
	[SALES_ORDER_FLAGGED_STATUS]: 'Flagged',
	[SALES_ORDER_APPROVED_STATUS]: 'Created',
	[SALES_ORDER_PROCESSING_STATUS]: 'In-Transit',
	[SALES_ORDER_COMPLETED_STATUS]: 'Delivered',
	[SALES_ORDER_CANCELLED_STATUS]: 'Cancelled'
}

export const tabs = [
	{
		title: 'All',
		status: '',
		path: `${paths.SALES_ORDERS}`
	},
	{
		title: 'Requested',
		status: SALES_ORDER_REQUESTED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_REQUESTED_STATUS}`
	},
	{
		title: 'Flagged',
		status: SALES_ORDER_FLAGGED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_FLAGGED_STATUS}`
	},
	{
		title: 'Created',
		status: SALES_ORDER_APPROVED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_APPROVED_STATUS}`
	},
	{
		title: 'In-Transit',
		status: SALES_ORDER_PROCESSING_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_PROCESSING_STATUS}`
	},
	{
		title: 'Delivered',
		status: SALES_ORDER_COMPLETED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_COMPLETED_STATUS}`
	},
	{
		title: 'Cancelled',
		status: SALES_ORDER_CANCELLED_STATUS,
		path: `${paths.SALES_ORDERS}?status=${SALES_ORDER_CANCELLED_STATUS}`
	}
]

const initializeColumnValues = (isChecked) => {
	return {
		isAllChecked: isChecked,
		creationDate: isChecked,
		deliveryDate: isChecked,
		orderDate: isChecked,
		type: isChecked,
		salesOrderID: isChecked,
		areaManager: isChecked,
		to: isChecked,
		from: isChecked,
		salesAmount: isChecked,
		advanceAmount: isChecked,
		paymentMethod: isChecked,
		attachments: isChecked,
		totalOrderQuantity: isChecked
	}
}

const SalesOrdersPage = () => {
	const salesOrderColumns = [
		{
			title: 'Order Date',
			key: 'orderDate',
			render: salesOrder => {
				return (
					<div style={{
						display: 'flex',
						alignItems: 'center',
						minHeight: salesOrder.week ? 120 : 'auto'
					}}
					>
						{
							salesOrder.week &&
								<div style={{ display: 'flex', position: 'absolute', top: 0, marginTop: 12 }}>
									<div className={styles.weekHeading}>
										{getWeekHeading(salesOrder.week)}
									</div>
								</div>
						}
						{
							salesOrder.staleness >= 2 ?
								<Tooltip title={`Status has not updated in ${salesOrder.staleness} days.`}>
									<div className={styles.staleIndicator} />
								</Tooltip> : null
						}
						<div style={{ width: '100%' }}>
							{moment(salesOrder.orderDate).format('MMM D, YYYY')}
						</div>
					</div>
				)
			}
		},
		{
			title: 'Creation Date',
			key: 'creationDate',
			render: salesOrder => { return moment(salesOrder.createdAt).format('MMM D, YYYY')}
		},
		{
			title: 'Order Type',
			key: 'type',
			render: salesOrder => {
				return (
					salesOrder.isStockTransfer ?
						<div>
							STO
						</div> :
						<div>
							SO
						</div>
				)
			}
		},
		{
			title: 'Order #',
			key: 'salesOrderID',
			render: salesOrder => {
				return (
					<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
						<a className={styles.link}>{getShortId(salesOrder)}</a>
					</Link>
				)
			}
		},
		{
			title: 'Total Quantity - TH (sticks)',
			key: 'totalOrderQuantity',
			render: salesOrder => {
				const salesOrderItems = salesOrder.salesOrderItems || []
				const totalQuantity = salesOrderItems.reduce((acc, item) => {
					const size = item.packaging ? item.packaging.size : 0
					const total = item.packageQuantity * size
					return acc + total
				}, 0)
				return (
					<div>
						{(totalQuantity / 1000).toFixed(3)}
					</div>
				)
			}
		},
		{
			title: 'From',
			key: 'from',
			render: salesOrder => {
				return (
					<div className={styles.locations}>
						{
							salesOrder.pickUpLocation &&
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div className={styles.badge}>
									{convertEnumToString(salesOrder.pickUpLocation.type.toLowerCase())}
								</div>
								{
									[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(salesOrder.pickUpLocation.type) ?
										<Link href={`${paths.WAREHOUSES}/${salesOrder.pickUpLocation.id}`}>
											<a>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													{salesOrder.pickUpLocation.label}
													<div className={styles.location}>
														{salesOrder.pickUpLocation.address}
													</div>
												</div>
											</a>
										</Link> :
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{salesOrder.pickUpLocation.label}
											<div className={styles.location}>
												{salesOrder.pickUpLocation.address}
											</div>
										</div>
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'To',
			key: 'to',
			render: salesOrder => {
				return (
					<div className={styles.locations}>
						{
							salesOrder.distributor ?
								<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
									<div className={styles.badge} style={{ color: '#278EA5', background: '#ecf9f9' }}>
										Distributor
									</div>
									<Link href={`${paths.DISTRIBUTORS}/${salesOrder.distributor.id}`}>
										<a style={{ color: '#278EA5' }}>
											{salesOrder.distributor.name}
										</a>
									</Link>
								</div> : null
						}
						{
							salesOrder.location &&
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{
									!salesOrder.distributor &&
									<div className={styles.badge}>
										{convertEnumToString(salesOrder.location.type.toLowerCase())}
									</div>
								}
								{
									[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(salesOrder.location.type) ?
										<Link href={`${paths.WAREHOUSES}/${salesOrder.location.id}`}>
											<a>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													{salesOrder.location.label}
													<div className={styles.location}>
														{salesOrder.location.address}
													</div>
												</div>
											</a>
										</Link> :
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{salesOrder.location.label}
											<div className={styles.location}>
												{salesOrder.location.address}
											</div>
										</div>
								}
							</div>
						}
					</div>
				)
			}
		},
		{
			title: 'Status',
			key: 'status',
			render: salesOrder => {
				return (
					<div style={{ display: 'flex' }}>
						<div className={styles.status} style={{
							background: salesOrder.subStatus ?
								SALES_ORDER_SUB_STATUS_COLORS[salesOrder.subStatus.toLowerCase()] : SALES_ORDER_STATUS_COLORS[salesOrder.status.toLowerCase()]
						}}
						>
							{
								salesOrder.subStatus ?
									convertEnumToString(salesOrder.subStatus.toLowerCase()) :
									STATUS_NAMES[salesOrder.status.toLowerCase()]
							}
						</div>
					</div>
				)
			}
		}
	]

	const router = useRouter()
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		allSalesOrders,
		requestedSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders
	} = useSelector(state => state.salesOrdersReducer)
	const { companyDetails, permissions } = useSelector(state => state.authReducer)
	const { status } = router.query
	const [currentStatus, setCurrentStatus] = useState(SALES_ORDER_REQUESTED_STATUS)
	const [isLoading, setIsLoading] = useState(false)
	const [salesOrders, setSalesOrders] = useState([])
	const [salesOrderWeeklyGroupings, setSalesOrderWeeklyGroupings] = useState([])
	const [isSalesOrdersExportModalVisible, setIsSalesOrdersExportModalVisible] = useState(false)
	const [isUploadOrdersModalVisible, setIsUploadOrdersModalVisible] = useState(false)
	const [isReplaceOrderNumbersModalVisible, setIsReplaceOrderNumbersModalVisible] = useState(false)
	const [filteredColumns, setFilteredColumns] = useLocalStorage('filteredColumns', initializeColumnValues(true))
	const [isFilterControlVisible, setIsFilterControlVisible] = useState(false)
	const [exportType, setExportType] = useState()
	const { searchParams } = useSearchParams()
	const orderStatusFlow = companyDetails?.orderStatusFlow

	useEffect(() => {
		if (!permissions.viewOrders) {
			return
		}
		let salesOrderStatus = undefined
		let salesOrderSubStatus = undefined
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS:
				salesOrderStatus = SALES_ORDER_REQUESTED_STATUS
				break
			case SALES_ORDER_FLAGGED_STATUS:
				salesOrderStatus = SALES_ORDER_FLAGGED_STATUS
				break
			case SALES_ORDER_APPROVED_STATUS:
				salesOrderStatus = SALES_ORDER_APPROVED_STATUS
				break
			case SALES_ORDER_PROCESSING_STATUS:
				salesOrderStatus = SALES_ORDER_PROCESSING_STATUS
				break
			case SALES_ORDER_COMPLETED_STATUS:
				salesOrderStatus = SALES_ORDER_COMPLETED_STATUS
				break
			case SALES_ORDER_CANCELLED_STATUS:
				salesOrderStatus = SALES_ORDER_CANCELLED_STATUS
				break
			default:
				salesOrderStatus = undefined
				break
		}
		setCurrentStatus(salesOrderStatus)
		getSalesOrders({
			status: salesOrderStatus,
			subStatus: salesOrderSubStatus,
			page: 0,
			...searchParams
		})
	}, [status, searchParams])

	useEffect(() => {
		let salesOrders = allSalesOrders
		switch (status) {
			case SALES_ORDER_REQUESTED_STATUS:
				salesOrders = requestedSalesOrders
				break
			case SALES_ORDER_FLAGGED_STATUS:
				salesOrders = flaggedSalesOrders
				break
			case SALES_ORDER_APPROVED_STATUS:
				salesOrders = approvedSalesOrders
				break
			case SALES_ORDER_PROCESSING_STATUS:
				salesOrders = processingSalesOrders
				break
			case SALES_ORDER_COMPLETED_STATUS:
				salesOrders = completedSalesOrders
				break
			case SALES_ORDER_CANCELLED_STATUS:
				salesOrders = cancelledSalesOrders
				break
			default:
				salesOrders = allSalesOrders
				break
		}
		setSalesOrders(salesOrders)
		setSalesOrderWeeklyGroupings(getSalesOrderGroupings(salesOrders))
	}, [
		allSalesOrders,
		requestedSalesOrders,
		flaggedSalesOrders,
		approvedSalesOrders,
		processingSalesOrders,
		completedSalesOrders,
		cancelledSalesOrders
	])

	useEffect(() => {
		if (orderStatusFlow) {
			setCurrentStatus(orderStatusFlow.statuses[0].toLowerCase())
		}
	}, [orderStatusFlow])

	const isAllColumnChecked = (filteredColumns) => {
		return Object.values(filteredColumns).every((value) => value === true)
	}

	const getSalesOrders = async ({ status, subStatus, isStockTransfer, page }) => {
		setIsLoading(true)
		await dispatch(fetchSalesOrders({
			status,
			subStatus,
			page,
			isStockTransfer,
			...searchParams
		}))
		setIsLoading(false)
	}

	const onPageChanged = async (page) => {
		await getSalesOrders({
			status: currentStatus,
			page: page - 1,
			...searchParams
		})
	}

	const onExportCSV = () => {
		setExportType(EXPORT_TYPE.CSV)
		setIsSalesOrdersExportModalVisible(true)
	}

	const onExportPDF = () => {
		setExportType(EXPORT_TYPE.PDF)
		setIsSalesOrdersExportModalVisible(true)
	}

	const onUploadOrder = () => {
		setIsUploadOrdersModalVisible(true)
	}

	const onReplaceOrderNumbers = () => {
		setIsReplaceOrderNumbersModalVisible(true)
	}

	const renderActionMenu = () => {
		return (
			<Menu>
				<Menu.Item
					icon={<ExportOutlined />}
					onClick={onExportCSV}
					key='0'
				>
					Export As CSV
				</Menu.Item>
				<Menu.Item
					icon={<ExportOutlined />}
					onClick={onExportPDF}
					key='1'
				>
					Export As PDF
				</Menu.Item>
				<Menu.Item
					icon={<UploadOutlined />}
					onClick={onUploadOrder}
					key='2'
					disabled={!permissions.uploadOrders}
				>
					Upload Orders
				</Menu.Item>
				<Menu.Item
					icon={<UploadOutlined />}
					onClick={onReplaceOrderNumbers}
					key='3'
					disabled={!permissions.replaceOrdersNumbers}
				>
					Replace Order Numbers
				</Menu.Item>
			</Menu>
		)
	}

	const renderCreateMenu = () => {
		return (
			<Menu>
				<Menu.Item
					key='0'
					disabled={!permissions.addOrders}
				>
					<Link href='/add-sales-order'>
						Create Sales Order
					</Link>
				</Menu.Item>
				<Menu.Item
					key='1'
					disabled={!permissions.addOrders}
				>
					<Link href='/add-sales-order?stockTransfer=true'>
						Create Stock Transfer Order
					</Link>
				</Menu.Item>
			</Menu>
		)
	}

	const getSalesOrderGroupings = (salesOrders) => {
		// Sales orders are sorted by delivery date by default.
		const salesOrderWeeklyGroupings = []
		let sameKey = undefined
		salesOrders.forEach(salesOrder => {
			const date = moment(salesOrder.orderDate)
			const yearWeekKey = `${date.startOf('isoWeek').year()}-${date.startOf('isoWeek').isoWeek()}`
			if (sameKey !== yearWeekKey) {
				salesOrderWeeklyGroupings.push({
					week: yearWeekKey,
					...salesOrder
				})
				sameKey = yearWeekKey
			} else {
				salesOrderWeeklyGroupings.push(salesOrder)
			}
		})
		return salesOrderWeeklyGroupings
	}

	const getWeekHeading = (dateKey) => {
		const currentDate = new Date()
		const currentYear = moment(currentDate).year()
		const currentWeek = moment(currentDate).isoWeek()
		const [year, week] = dateKey.split('-').map(val => +val)
		const date = moment().year(year).isoWeek(week)
		if (currentYear === year && currentWeek === week) {
			return 'This Week'
		} else if (currentYear === year && currentWeek + 1 === week) {
			return 'Next Week'
		} else if (currentYear === year && currentWeek - 1 === week) {
			return 'Last Week'
		} else {
			return `${date.startOf('isoWeek').format('MMM Do')} - ${date.endOf('isoWeek').format('MMM Do')}`
		}
	}

	const isTabSelected = (tab) => {
		if (currentStatus === tab.status) {
			return true
		} else if (currentStatus === undefined && tab.status === '') {
			return true
		} else {
			return false
		}
	}

	const currentSalesOrderColumns = useMemo(() => {
		return salesOrderColumns
			.filter(column => {
				if (column.key === 'status') {
					if (!currentStatus) {
						return true
					} else {
						return false
					}
				} else if (column.key === 'buyingAmount') {
					if (companyDetails && companyDetails.industry && companyDetails.industry.toLowerCase() === Industry.AGRICULTURE) {
						return true
					} else {
						return false
					}
				} else {
					return true
				}
			})
			.filter(column => {
				if (filteredColumns) {
					if (column.key === 'status') {
						return true
					}
					return filteredColumns[column.key]
				} else {
					return false
				}
			})
	}, [currentStatus, companyDetails, filteredColumns])

	const modifiedTabs = useMemo(() => {
		if (orderStatusFlow) {
			return tabs.filter(tab => orderStatusFlow.statuses.includes(tab.status.toUpperCase()) || tab.status === '')
		} else {
			return tabs
		}
	}, [orderStatusFlow])

	return (
		<Page>
			<div className={styles.container}>
				<div className={styles.contentHeader}>
					<div style={{ display: 'flex' }}>
						<div className={styles.tabContainer}>
							{
								modifiedTabs.map(tab => {
									return (
										<HeaderTab key={tab.path} onClick={() => router.push(tab.path)} isSelected={() => isTabSelected(tab)}>{tab.title}</HeaderTab>
									)
								})
							}
						</div>
						<div className={styles.contentHeaderContainer}>
							<Dropdown overlay={renderActionMenu()} trigger={['click']}>
								<Button
									style={{ margin: '0 16px' }}
									shape='round'
									size='large'
									loading={false}
									onClick={() => {}}
								>
									Action
								</Button>
							</Dropdown>
							<Dropdown overlay={renderCreateMenu()} trigger={['click']}>
								<Button
									type='primary' shape='round' size='large' className={styles.actionButton}
								>
									Create Order
								</Button>
							</Dropdown>
						</div>
					</div>
					<div style={{ display: 'flex' }}>
						<div className={styles.filterBtnContainer}>
							{
								filteredColumns ?
									<Popover
										content={
											<div className={styles.filterOptions}>
												<div className={styles.filterOption}>
													<Checkbox
														checked={isAllColumnChecked(filteredColumns)}
														value='isAllChecked'
														onChange={(e) => {
															if (e.target.checked) {
																setFilteredColumns(initializeColumnValues(true))
															} else {
																setFilteredColumns(initializeColumnValues(false))
															}
														}}
													>
													Select All
													</Checkbox>
												</div>
												{
													salesOrderColumns.map(column => {
														if (column.key !== 'buyingAmount' && column.key !== 'status') {
															return (
																<div key={column.key} className={styles.filterOption}>
																	<Checkbox
																		checked={filteredColumns[column.key]}
																		value={column.key}
																		onChange={(e) => setFilteredColumns({ ...filteredColumns, [column.key]: e.target.checked })}
																	>
																		{column.title}
																	</Checkbox>
																</div>
															)
														}
													})
												}
											</div>
										}
										trigger='click'
										visible={isFilterControlVisible}
										onVisibleChange={setIsFilterControlVisible}
									>
										<span className={styles.filterBtnContent}>
											<img src='/img/filter.svg' width={20} height={20} />
											<span>Filter Column</span>
										</span>
									</Popover> : null
							}
						</div>
					</div>
				</div>
				<div className={styles.content} style={{ marginTop: 140 }}>
					{
						isLoading || salesOrders.length > 0 ?
							<Table
								refresh={() => onPageChanged(page)}
								loading={isLoading}
								columns={currentSalesOrderColumns}
								dataSource={salesOrderWeeklyGroupings}
								pagination={{
									pageSize: 50,
									total: totalCount,
									position: ['topLeft'],
									onChange: onPageChanged,
									showSizeChanger: false,
									current: page + 1
								}}
								scroll={{ y: 'calc(100vh - 404px)' }}
								rowKey='id'
								size='small'
							/> :
							<Empty
								description='Try discussing internally on how we can increase sales or try asking clients on how can we add more value to them.'
								image='/img/sales-orders-empty.svg'
								imageStyle={{ height: '400px', marginBottom: '24px' }}
								style={{ marginTop: '24px' }}
							/>
					}
				</div>
			</div>
			{
				isSalesOrdersExportModalVisible ?
					<SalesOrdersExportModal
						filteredColumns={filteredColumns}
						exportType={exportType}
						onCancel={() => setIsSalesOrdersExportModalVisible(false)}
						visible={isSalesOrdersExportModalVisible}
					/> : null
			}
			{
				isUploadOrdersModalVisible &&
					<UploadOrdersModal
						visible={isUploadOrdersModalVisible}
						onCancel={() => setIsUploadOrdersModalVisible(false)}
						onComplete={async () => {
							await getSalesOrders({
								status: currentStatus,
								page: page
							})
						}}
					/>
			}
			{
				isReplaceOrderNumbersModalVisible &&
					<UploadInternalOrderIdsModal
						visible={isReplaceOrderNumbersModalVisible}
						onCancel={() => setIsReplaceOrderNumbersModalVisible(false)}
						onComplete={async () => {
							await getSalesOrders({
								status: currentStatus,
								page: page
							})
						}}
					/>
			}
		</Page>
	)
}

export default SalesOrdersPage
