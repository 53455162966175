export const MeasurementUnits = {
	MM: 'mm',
	CM: 'cm',
	M: 'm',
	IN: 'in',
	FT: 'ft',
	YD: 'yd'
}

export const WeightUnits = {
	KG: 'kg',
	G: 'g',
	LBS: 'lbs',
	OZ: 'oz',
	TONNE: 'tonne'
}

export const VolumeUnits = {
	M3: 'm3',
	L: 'l',
	CC: 'cc',
	CUIN: 'cuin',
	CUFT: 'cuft'
}

/**
 * Convert measurement unit to metres
 * @param {*} value The measurement value.
 * @param {*} measuremenUnit The unit to convert from.
 * @returns a number in metres.
 */
export const convertMeasurementUnit = (value, measuremenUnit) => {
	if (!value) {
		return 0
	}
	switch (measuremenUnit) {
		case MeasurementUnits.CM:
			return value / 100.00
		case MeasurementUnits.FT:
			return value / 3.2808
		case MeasurementUnits.IN:
			return value / 39.37
		case MeasurementUnits.M:
			return value
		case MeasurementUnits.MM:
			return value / value, 1000.00
		case MeasurementUnits.YD:
			return value / value, 1.094
		default:
			return value
	}
}

/**
 * Convert weight unit to kilograms
 * @param {*} value The weight value.
 * @param {*} weightUnit The unit to convert from.
 * @returns a number in kilograms
 */
export const convertWeightUnit = (value, weightUnit) => {
	if (!value) {
		return 0
	}
	switch (weightUnit) {
		case WeightUnits.G:
			return value / 1000.00
		case WeightUnits.KG:
			return value
		case WeightUnits.LBS:
			return value / 2.205
		case WeightUnits.OZ:
			return value / 35.274
		case WeightUnits.TONNE:
			return value * 1000
		default:
			return value
	}
}
