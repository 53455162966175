import { HYDRATE } from 'next-redux-wrapper'
import {
	SALES_ORDER_CANCELLED_STATUS,
	SALES_ORDER_COMPLETED_STATUS,
	SALES_ORDER_FLAGGED_STATUS,
	SALES_ORDER_PROCESSING_STATUS,
	SALES_ORDER_APPROVED_STATUS,
	SALES_ORDER_REQUESTED_STATUS
} from '../../pages/sales-orders'
import {
	ADD_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDER_DETAILS_SUCCESS,
	UPDATE_SALES_ORDER_SUCCESS,
	ADD_TRANSACTION_SUCCESS
} from './action-types'

const initialState = {
	salesOrderDetails: {},
	allSalesOrders: [],
	requestedSalesOrders: [],
	flaggedSalesOrders: [],
	approvedSalesOrders: [],
	processingSalesOrders: [],
	completedSalesOrders: [],
	cancelledSalesOrders: [],
	totalCount: 0,
	page: 0
}

const salesOrdersReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_SALES_ORDERS_SUCCESS: {
			switch (payload.status) {
				case SALES_ORDER_REQUESTED_STATUS:
					return {
						...state,
						requestedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case SALES_ORDER_FLAGGED_STATUS:
					return {
						...state,
						flaggedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case SALES_ORDER_APPROVED_STATUS:
					return {
						...state,
						approvedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case SALES_ORDER_PROCESSING_STATUS:
					return {
						...state,
						processingSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case SALES_ORDER_COMPLETED_STATUS:
					return {
						...state,
						completedSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				case SALES_ORDER_CANCELLED_STATUS:
					return {
						...state,
						cancelledSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
				default:
					return {
						...state,
						allSalesOrders: payload.data.results,
						totalCount: payload.data.count,
						page: +payload.data.page
					}
			}
		}
		case ADD_SALES_ORDERS_SUCCESS: {
			if (payload.status === SALES_ORDER_REQUESTED_STATUS) {
				const requestedSalesOrders = [payload, ...state.requestedSalesOrders]
				return {
					...state,
					requestedSalesOrders
				}
			} else if (payload.status === SALES_ORDER_APPROVED_STATUS) {
				const approvedSalesOrders = [payload, ...state.approvedSalesOrders]
				return {
					...state,
					approvedSalesOrders
				}
			} else {
				return state
			}
		}
		case FETCH_SALES_ORDER_DETAILS_SUCCESS: {
			const newState = { ...state }
			newState.salesOrderDetails[payload.id] = payload.data
			return newState
		}
		case UPDATE_SALES_ORDER_SUCCESS: {
			const newState = { ...state }
			const salesOrder = newState.salesOrderDetails[payload.id]
			if (salesOrder && payload) {
				const newSalesOrder = Object.assign(salesOrder, payload)
				newState.salesOrderDetails[payload.id] = newSalesOrder
			}
			return newState
		}
		case ADD_TRANSACTION_SUCCESS: {
			const newState = { ...state }
			const salesOrderDetails = newState.salesOrderDetails[payload.id]
			if (salesOrderDetails) {
				if (salesOrderDetails.transactions) {
					salesOrderDetails.transactions.push(payload.data)
				}
			}
			return newState
		}
		default:
			return state
	}
}

export default salesOrdersReducer
