export const FETCH_PRODUCTS_SUCCESS = 'fetch_products_success'
export const ADD_PRODUCT_SUCCESS = 'add_product_success'
export const UPDATE_PRODUCT_SUCCESS = 'update_product_success'
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'fetch_product_categories'
export const FETCH_PRODUCT_DETAILS_SUCCESS = 'fetch_product_details_success'
export const FETCH_INVENTORY_SUCCESS = 'fetch_inventory_success'
export const FETCH_PRODUCT_METRICS_SUCCESS = 'fetch_product_metrics_success'
export const FETCH_WAREHOUSE_DETAILS_SUCCESS = 'fetch_warehouse_details_success'
export const FETCH_INVENTORY_PRODUCTS_SUCCESS = 'fetch_inventory_products_success'
export const FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS = 'fetch_inventory_on_hand_stock_success'
export const FETCH_INVENTORY_SKUS_SUCCESS = 'fetch_inventory_skus_success'
export const ADD_INVENTORY_PRODUCT_ITEM_SUCCESS = 'add_inventory_product_item_success'
export const UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS = 'update_inventory_product_item_success'
export const FETCH_INVENTORY_FEED_ITEMS_SUCCESS = 'fetch_inventory_feed_items_success'
export const FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS = 'fetch_more_inventory_feed_items_success'
export const ADD_INVENTORY_FEED_ITEM = 'add_inventory_feed_item'
export const FETCH_INVENTORY_STOCK_MONITORING_SUCCESS = 'fetch_inventory_stock_monitoring_success'
export const FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS = 'fetch_inventory_order_movement_success'
export const FETCH_BLOCKED_INVENTORY_SUCCESS = 'fetch_blocked_inventory_success'
export const BLOCK_INVENTORY_ITEMS_SUCCESS = 'block_inventory_items_success'
export const SORT_INVENTORY_SKUS = 'sort_inventory_skus'
export const FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS = 'fetch_inventory_scan_adherence_success'


