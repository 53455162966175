import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchParams as setSearchParamsAction } from '../store/common/actions'
import { isEmptyObject } from '../utils'

const useSearchParams = () => {
	const { searchParams } = useSelector(state => state.commonReducer)
	const { pathname } = useRouter()
	const dispatch = useDispatch()

	const isSearchApplied = !isEmptyObject(searchParams)

	const clearSearchParams = () => {
		dispatch(setSearchParamsAction({}))
	}

	const setSearchParams = (params = {}) => {
		dispatch(setSearchParamsAction(params))
	}

	useEffect(() => {
		if (isSearchApplied) {
			clearSearchParams()
		}
	}, [pathname])

	return {
		clearSearchParams,
		setSearchParams,
		isSearchApplied,
		searchParams
	}
}

export default useSearchParams
