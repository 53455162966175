import { SalesOrders } from '../../services/api/sales-orders'
import { Transactions } from '../../services/api/transactions'
import {
	ADD_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDERS_SUCCESS,
	FETCH_SALES_ORDER_DETAILS_SUCCESS,
	UPDATE_SALES_ORDER_SUCCESS,
	ADD_TRANSACTION_SUCCESS
} from './action-types'

export function fetchSalesOrders (
	{
		status,
		subStatus,
		page,
		searchTerm,
		minOrderValue,
		maxOrderValue,
		fromDate,
		toDate,
		deliveryPlanStatus,
		unassigned,
		deliveryPlanId,
		isStockTransfer,
		pickUpLocationIds
	} = {}
) {
	return async (dispatch) => {
		const response = await SalesOrders.index({
			status,
			subStatus,
			page,
			searchTerm,
			minOrderValue,
			maxOrderValue,
			fromDate,
			toDate,
			deliveryPlanStatus,
			unassigned,
			deliveryPlanId,
			isStockTransfer,
			pickUpLocationIds
		})
		dispatch({ type: FETCH_SALES_ORDERS_SUCCESS, payload: { data: response.data, status } })
	}
}

export function addSalesOrder (params) {
	return async (dispatch) => {
		const response = await SalesOrders.create(params)
		dispatch({ type: ADD_SALES_ORDERS_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchSalesOrderDetails (id, inventory) {
	return async (dispatch) => {
		const response = await SalesOrders.single(id, {}, inventory)
		dispatch({ type: FETCH_SALES_ORDER_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateSalesOrder (id, params) {
	return async (dispatch) => {
		const response = await SalesOrders.update(id, params)
		dispatch({ type: UPDATE_SALES_ORDER_SUCCESS, payload: response.data })
	}
}

export function addTransaction (id, params) {
	return async (dispatch) => {
		const response = await Transactions.create(params)
		dispatch({ type: ADD_TRANSACTION_SUCCESS, payload: { data: response.data, id } })
	}
}
