import { Select, Input, Divider } from 'antd'
import styles from './CustomSelect.module.css'
// We use Less styles to override Ant stylings.
require('./CustomSelect.less')
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { convertStringToEnum } from '../../utils'

const { Option, OptGroup } = Select

const CustomSelect = ({
	options = [],
	title,
	style,
	defaultValue,
	placeholder,
	value,
	onChange,
	addItem,
	loading,
	small,
	disabled,
	labelInValue,
	allowClear,
	disabledOptions = { }, // Map of options to disable,
	optGroupLabel,
	unclipOptions = false,
	popupContainer
}) => {
	const [addItemInputValue, setAddItemInputValue] = useState()
	const [currentOptions, setCurrentOptions] = useState(options)

	useEffect(() => {
		setCurrentOptions(options)
	}, [options])

	const onAddItemClick = () => {
		if (addItemInputValue) {
			const newItemValue = convertStringToEnum(addItemInputValue)
			if (currentOptions.findIndex(option => option.value === newItemValue) === -1) {
				const newOptions = [...currentOptions, {
					value: newItemValue,
					label: addItemInputValue
				}]
				setCurrentOptions(newOptions)
				setAddItemInputValue(null)
			}
		}
	}

	return (
		<div className={styles.customSelectContainer}>
			<p className={styles.customSelectTitle}>{title}</p>
			<Select
				style={style}
				loading={loading}
				placeholder={placeholder}
				className={`${small ? disabled ? 'custom-select-small-disabled' : 'custom-select-small' :
					disabled ? 'custom-select-disabled' : 'custom-select'} ${title && 'select-title-visible'}`}
				defaultValue={defaultValue}
				disabled={disabled}
				labelInValue={labelInValue}
				value={value}
				onChange={onChange}
				allowClear={allowClear}
				dropdownRender={menu => {
					return (
						<div>
							{menu}
							{
								addItem ?
									<Divider style={{ margin: '4px 0' }} /> : null
							}
							{
								addItem ?
									<div className='add-item-option'>
										<Input
											value={addItemInputValue}
											onChange={e => {
												let text = e.target.value.toLowerCase()
												text = text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
												setAddItemInputValue(text)
											}}
										/>
										<div className='add-item-container' onClick={onAddItemClick}>
											<PlusOutlined />
											<div className='text'>Add</div>
										</div>
									</div> : null
							}
						</div>
					)
				}}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
			>
				{
					optGroupLabel ?
						<OptGroup label={optGroupLabel}>
							{
								currentOptions.map(option => {
									return (
										<Option
											className={unclipOptions ? 'full-custom-option' : ''}
											key={option.value}
											value={option.value}
											disabled={disabledOptions[option.value]}
										>{option.label}
										</Option>
									)
								})
							}
						</OptGroup> :
						currentOptions.map(option => {
							return (
								<Option
									className={unclipOptions ? 'full-custom-option' : ''}
									key={option.value}
									value={option.value}
									disabled={disabledOptions[option.value]}
								>{option.label}
								</Option>
							)
						})
				}
			</Select>
		</div>
	)
}

export default CustomSelect
