import { notification, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VEHICLE_ACTIVE_STATUS } from '../../pages/fleet'
import { Vehicles } from '../../services/api/vehicles'
import { assignVehicleToDeliveryPlanItem } from '../../store/delivery-plans/actions'
import { fetchVehicles } from '../../store/vehicles/actions'
import CustomSearchableSelect from '../custom-searchable-select'



const AssignVehicleModal = ({ visible, onCancel, deliveryPlanItemId }) => {
	const dispatch = useDispatch()
	const { vehicles } = useSelector(state => state.vehiclesReducer)
	const [isLoadingVehicles, setIsLoadingVehicles] = useState(false)
	const [isAssigningVehicle, setIsAssigningVehicle] = useState(false)
	const [vehicle, setVehicle] = useState()

	useEffect(() => {
		fetchActiveVehicles()
	}, [])

	const fetchActiveVehicles = async () => {
		setIsLoadingVehicles(true)
		await dispatch(fetchVehicles({ page: -1, statuses: [VEHICLE_ACTIVE_STATUS], unassigned: true }))
		setIsLoadingVehicles(false)
	}

	const searchVehicles = value => {
		return Vehicles.index({ searchTerm: value, statuses: [VEHICLE_ACTIVE_STATUS], unassigned: true })
	}

	const assignVehicle = async () => {
		setIsAssigningVehicle(true)
		try {
			await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItemId, {
				vehicleId: vehicle.value
			}))

			onCancel()
			notification.success({
				message: 'Vehicle Assigned to Delivery Plan',
				description: 'Vehicle has been successfully assigned to a route.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Assign Vehicle to Route',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsAssigningVehicle(false)
		}
	}

	return (
		<Modal
			title='Assign Vehicle'
			visible={visible}
			onCancel={onCancel}
			onOk={assignVehicle}
			okText='Assign Vehicle'
			okButtonProps={{
				disabled: !vehicle
			}}
			confirmLoading={isAssigningVehicle}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<CustomSearchableSelect
					searchTask={searchVehicles}
					defaultOptions={vehicles}
					isLoading={isLoadingVehicles}
					labelIndex='plateNumber'
					valueIndex='id'
					placeholder='Select Vehicle'
					onChange={option => setVehicle(option)}
					onClear={() => setVehicle(null)}
					value={vehicle}
				/>
			</div>
		</Modal>
	)
}

AssignVehicleModal.defaultProps = {
	visible: false,
	onCancel: () => { },
	deliveryPlanItemId: null
}

export default AssignVehicleModal
