import 'firebase/firestore'
import firebase from 'firebase/app'
import { isNotEmpty } from '../../utils'

export const convertTimestamps = (data) => {
	if (data.createdAt) {
		data.createdAt = data.createdAt.toDate()
	}
	if (data.updatedAt) {
		data.updatedAt = data.updatedAt.toDate()
	}
	return data
}

export const Auth = {
	getCurrentUser: () => {
		return new Promise((resolve) => {
			const unsubscribe = firebase.auth().onIdTokenChanged(authUser => {
				if (authUser) {
					resolve(authUser)
					unsubscribe()
				} else {
					resolve()
					unsubscribe()
				}
			})
		})
	}
}

export const Notifications = {
	listenToNotificationCount: (uid, onSnapshot) => {
		return firebase.firestore()
			.collection('user-notifications')
			.doc(uid)
			.onSnapshot(doc => {
				if (doc.data()) {
					onSnapshot(doc.data())
				}
			})
	},
	fetchUnreadNotificationsOnce: async (uid) => {
		const snapshot = await firebase.firestore()
			.collection('user-notifications')
			.doc(uid)
			.collection('notifications')
			.where('isRead', '==', false)
			.orderBy('createdAt', 'desc')
			.limit(50)
			.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	},
	fetchNotificationsOnce: async (uid) => {
		const snapshot = await firebase.firestore()
			.collection('user-notifications')
			.doc(uid)
			.collection('notifications')
			.orderBy('createdAt', 'desc')
			.limit(50)
			.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	},
	listenToUnreadNotifications: (uid, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('user-notifications')
			.doc(uid)
			.collection('notifications')
			.where('isRead', '==', false)
			.orderBy('createdAt')
			.where('createdAt', '>', new Date())
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	listenToNotifications: (uid, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('user-notifications')
			.doc(uid)
			.collection('notifications')
			.orderBy('createdAt')
			.where('createdAt', '>', new Date())
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	readNotification: async (id) => {
		const user = await Auth.getCurrentUser()
		await firebase.firestore()
			.collection('user-notifications')
			.doc(user.uid)
			.collection('notifications')
			.doc(id)
			.update({ isRead: true })
	}
}

export const SalesOrders = {
	listenToSalesOrder: (id, onChange) => {
		return firebase.firestore()
			.collection('sales-orders')
			.doc(id)
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				onChange(doc.data())
			})
	},
	listenToSalesOrderHistory: (id, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('sales-orders-history')
			.doc(id)
			.collection('history')
			.orderBy('createdAt')
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	fetchSaleOrderHistories: async (id) => {
		const snapshot = await firebase.firestore()
			.collection('sales-orders-history')
			.doc(id)
			.collection('history')
			.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	}
}

export const DeliveryPlans = {
	listenToLoadingDeliveryPlans: (companyId, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('delivery-plans')
			.where('companyId', '==', companyId)
			.where('loadState', '==', 'LOADING')
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	listenToDeliveryPlans: (companyId, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('delivery-plans')
			.where('companyId', '==', companyId)
			.where('createdAt', '>=', new Date())
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	}
}

export const Companies = {
	listenToCompany: (companyId, onChange) => {
		return firebase.firestore()
			.collection('companies')
			.doc(companyId)
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				onChange(doc.data())
			})
	}
}

export const Tasks = {
	listenToTask: (companyId, taskId, onChange) => {
		return firebase.firestore()
			.collection('tasks')
			.doc(companyId)
			.collection('tasks')
			.doc(taskId)
			.onSnapshot({
				includeMetadataChanges: true
			}, doc => {
				onChange(doc.data())
			})
	}
}

export const Locations = {
	listenToUserLocation: (uid, onCreated) => {
		return firebase.firestore()
			.collection('user-locations')
			.doc(uid)
			.collection('locations')
			.where('createdAt', '>', new Date())
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	fetchUserLocationsOnce: async (uid) => {
		const date = new Date()
		date.setHours(date.getHours() - 5)
		const snapshot = await firebase.firestore()
			.collection('user-locations')
			.doc(uid)
			.collection('locations')
			.where('createdAt', '>=', date)
			.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	}
}

export const Vehicles = {
	listenToVehicles: (companyId, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('vehicles')
			.where('companyId', '==', companyId)
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	listenToVehiclesByIds: (ids, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('vehicles')
			.where('id', 'in', ids)
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	}
}

export const InventoryItems = {
	fetchInventoryItemHistories: async (id) => {
		const snapshot = await firebase.firestore()
			.collection('inventory-items-history')
			.doc(id)
			.collection('history')
			.orderBy('createdAt', 'desc')
			.limitToLast(100)
			.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	}
}

export const InventoryProductItems = {
	listenToInventoryProductItems: (companyId, onCreated, onUpdated, onRemoved) => {
		return firebase.firestore()
			.collection('inventory-product-items')
			.where('companyId', '==', companyId)
			.where('updatedAt', '>=', new Date())
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	},
	fetchInventoryProductItemHistoryOnce: async (cid, changeState, searchTerm, fromDate, toDate) => {
		let query = firebase.firestore()
			.collection('inventory-product-items-history')
			.doc(cid)
			.collection('history')
		if (changeState) {
			query = query
				.where('change', '==', changeState)
		}
		if (isNotEmpty(searchTerm)) {
			if (searchTerm.trim().length === 8) {
				// Pack code search
				query = query
					.where('packCode', '==', searchTerm.trim())
			} else if (searchTerm.trim().length === 18 || searchTerm.trim().length > 20) {
				// Pallet number search
				query = query
					.where('palletId', '==', searchTerm.trim())
			} else if (searchTerm.trim().length >= 20) {
				// Serial number search
				query = query
					.where('serialNumber', '==', searchTerm.trim())
			}
		}
		if (fromDate) {
			query = query
				.where('updatedAt', '>=', fromDate)
		}
		if (toDate) {
			query = query
				.where('updatedAt', '<=', toDate)
		}
		query = query
			.orderBy('updatedAt', 'desc')
			.limit(50)
		const snapshot = await query.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	},
	fetchMoreInventoryProductItemHistory: async (cid, lastDocumentId, changeState, searchTerm, fromDate, toDate) => {
		const lastDocument = await firebase.firestore()
			.collection('inventory-product-items-history')
			.doc(cid)
			.collection('history')
			.doc(lastDocumentId)
			.get()
		let query = firebase.firestore()
			.collection('inventory-product-items-history')
			.doc(cid)
			.collection('history')
		if (changeState) {
			query = query
				.where('change', '==', changeState)
		}
		if (isNotEmpty(searchTerm)) {
			if (searchTerm.trim().length === 8) {
				// Pack code search
				query = query
					.where('packCode', '==', searchTerm.trim())
			} else if (searchTerm.trim().length === 18 || searchTerm.trim().length > 20) {
				// Pallet number search
				query = query
					.where('palletId', '==', searchTerm.trim())
			} else if (searchTerm.trim().length >= 20) {
				// Serial number search
				query = query
					.where('serialNumber', '==', searchTerm.trim())
			}
		}
		if (fromDate) {
			query = query
				.where('updatedAt', '>=', fromDate)
		}
		if (toDate) {
			query = query
				.where('updatedAt', '<=', toDate)
		}
		query = query
			.orderBy('updatedAt', 'desc')
			.startAfter(lastDocument)
			.limit(50)
		const snapshot = await query.get()
		return snapshot.docs.map(doc => {
			return convertTimestamps(doc.data())
		})
	},
	listenToInventoryProductItemHistory: (cid, changeState, onCreated, onUpdated, onRemoved) => {
		let collectionRef = firebase.firestore()
			.collection('inventory-product-items-history')
			.doc(cid)
			.collection('history')
		if (changeState) {
			collectionRef = collectionRef
				.where('change', '==', changeState)
		}
		collectionRef = collectionRef
			.orderBy('updatedAt')
			.where('updatedAt', '>', new Date())
		return collectionRef
			.onSnapshot(snapshot => {
				snapshot.docChanges().forEach(change => {
					if (change.type === 'added' && onCreated) {
						onCreated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'modified' && onUpdated) {
						onUpdated(convertTimestamps(change.doc.data()))
					}
					if (change.type === 'removed' && onRemoved) {
						onRemoved(convertTimestamps(change.doc.data()))
					}
				})
			})
	}
}
