import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_INVENTORY_PRODUCTS_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT_CATEGORIES_SUCCESS,
	FETCH_PRODUCT_DETAILS_SUCCESS,
	FETCH_PRODUCT_METRICS_SUCCESS,
	FETCH_WAREHOUSE_DETAILS_SUCCESS,
	UPDATE_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SKUS_SUCCESS,
	UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS,
	ADD_INVENTORY_PRODUCT_ITEM_SUCCESS,
	FETCH_INVENTORY_FEED_ITEMS_SUCCESS,
	FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS,
	ADD_INVENTORY_FEED_ITEM,
	FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS,
	FETCH_INVENTORY_STOCK_MONITORING_SUCCESS,
	FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS,
	FETCH_BLOCKED_INVENTORY_SUCCESS,
	SORT_INVENTORY_SKUS,
	FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	products: [],
	categories: {},
	steelCategories: {},
	productDetails: {},
	inventoryTotalCount: 0,
	inventoryPage: 0,
	warehouseDetails: {},
	inventory: [],
	inventoryProducts: [],
	inventoryProductsTotalCount: 0,
	inventoryProductsPage: 0,
	inventoryUnpalletedProducts: [],
	inventoryUnpalletedProductsTotalCount: 0,
	inventoryUnpalletedProductsPage: 0,
	inventoryProductsBlocked: [],
	inventoryProductsBlockedTotalCount: 0,
	inventoryProductsBlockedPage: 0,
	inventorySkus: [],
	previousInventorySkus: [],
	inventorySkusTotalCount: 0,
	inventorySkusPage: 0,
	inventoryOnHandStock: [],
	inventoryOnHandStockTotalCount: 0,
	inventoryOnHandStockPage: 0,
	inventoryOrderMovement: [],
	inventoryOrderMovementTotalCount: 0,
	inventoryOrderMovementPage: 0,
	inventoryFeedItems: [],
	inventoryFeedItemMap: {},
	inventoryStockMonitoring: [],
	inventoryScanAdherence: [],
	metrics: {
		new: 0,
		total: 0,
		active: 0
	}
}

const productsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_PRODUCTS_SUCCESS: {
			return {
				...state,
				products: payload.results,
				totalCount: payload.count,
				page: +payload.page
			}
		}
		case ADD_PRODUCT_SUCCESS: {
			const products = [payload, ...state.products]
			return {
				...state,
				products
			}
		}
		case UPDATE_PRODUCT_SUCCESS: {
			const newState = { ...state }
			const product = newState.productDetails[payload.id]
			if (product && payload) {
				const newProduct = Object.assign(product, payload)
				newState.productDetails[payload.id] = newProduct
			}
			return newState
		}
		case FETCH_PRODUCT_CATEGORIES_SUCCESS:
			if (payload.industry === 'steel') {
				return {
					...state,
					steelCategories: payload.data
				}
			} else {
				return {
					...state,
					categories: payload.data
				}
			}
		case FETCH_PRODUCT_DETAILS_SUCCESS: {
			const newState = { ...state }
			const productDetail = payload.data
			newState.productDetails[productDetail.id] = productDetail
			return newState
		}
		case FETCH_INVENTORY_SUCCESS: {
			return {
				...state,
				inventory: payload.results,
				inventoryTotalCount: payload.count,
				inventoryPage: +payload.page
			}
		}
		case FETCH_INVENTORY_PRODUCTS_SUCCESS: {
			const data = payload.data
			if (payload.isInStock) {
				return {
					...state,
					inventoryProducts: data.results,
					inventoryProductsTotalCount: data.count,
					inventoryProductsPage: +data.page
				}
			} else {
				return {
					...state,
					inventoryUnpalletedProducts: data.results,
					inventoryUnpalletedProductsTotalCount: data.count,
					inventoryUnpalletedProductsPage: +data.page
				}
			}
		}
		case FETCH_BLOCKED_INVENTORY_SUCCESS: {
			const data = payload.data
			return {
				...state,
				inventoryProductsBlocked: data.results,
				inventoryProductsBlockedTotalCount: data.count,
				inventoryProductsBlockedPage: +data.page
			}
		}
		case FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS: {
			return {
				...state,
				inventoryOnHandStock: payload.results,
				inventoryOnHandStockTotalCount: payload.count,
				inventoryOnHandStockPage: +payload.page
			}
		}
		case FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS: {
			return {
				...state,
				inventoryOrderMovement: payload.results,
				inventoryOrderMovementTotalCount: payload.count,
				inventoryOrderMovementPage: +payload.page
			}
		}
		case FETCH_INVENTORY_SCAN_ADHERENCE_SUCCESS: {
			return {
				...state,
				inventoryScanAdherence: payload
			}
		}
		case FETCH_INVENTORY_STOCK_MONITORING_SUCCESS: {
			return {
				...state,
				inventoryStockMonitoring: payload
			}
		}
		case FETCH_INVENTORY_SKUS_SUCCESS: {
			return {
				...state,
				inventorySkus: payload,
				previousInventorySkus: payload
			}
		}
		case SORT_INVENTORY_SKUS: {
			const quantitySortingOrder = payload.quantitySortingOrder
			let inventorySkus = [...state.inventorySkus]
			if (quantitySortingOrder === 'desc') {
				inventorySkus.sort((a, b) => {
					return +b.total - +a.total
				})
			} else if (quantitySortingOrder === 'asc') {
				inventorySkus.sort((a, b) => {
					return +a.total - +b.total
				})
			} else {
				inventorySkus = [...state.previousInventorySkus]
			}
			return {
				...state,
				inventorySkus
			}
		}
		case FETCH_INVENTORY_FEED_ITEMS_SUCCESS: {
			return {
				...state,
				inventoryFeedItems: payload
			}
		}
		case FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS: {
			const inventoryFeedItems = [...state.inventoryFeedItems, ...payload]
			return {
				...state,
				inventoryFeedItems
			}
		}
		case ADD_INVENTORY_FEED_ITEM: {
			if (state.inventoryFeedItemMap[payload.id]) {
				return state
			}
			const maxSize = Math.max(50, state.inventoryFeedItems.length)
			const inventoryFeedItems = [payload, ...state.inventoryFeedItems].slice(0, maxSize)
			return {
				...state,
				inventoryFeedItemMap: {
					...state.inventoryFeedItemMap,
					[payload.id]: true
				},
				inventoryFeedItems
			}
		}
		case FETCH_PRODUCT_METRICS_SUCCESS: {
			return {
				...state,
				metrics: payload
			}
		}
		case FETCH_WAREHOUSE_DETAILS_SUCCESS: {
			const newState = { ...state }
			const warehouseDetail = payload.data
			newState.warehouseDetails[warehouseDetail.id] = warehouseDetail
			return newState
		}
		case UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProducts = newState.inventoryProducts.map(inventoryProduct =>
				payload.id === inventoryProduct.id ?
					Object.assign(inventoryProduct, payload) :
					inventoryProduct
			)
			return newState
		}
		case ADD_INVENTORY_PRODUCT_ITEM_SUCCESS: {
			const newState = { ...state }
			newState.inventoryProducts = [payload, ...newState.inventoryProducts]
			return newState
		}
		default:
			return state
	}
}

export default productsReducer
