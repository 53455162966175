import UploadCsvModal from '../upload-csv-modal'
import { Upload } from '../../services/api/upload'

const UploadOrdersModal = ({ visible, onCancel, onComplete }) => {
	return (
		<UploadCsvModal
			title='Upload Orders'
			type='orders'
			fields={[
				{ label: 'Order Number', key: 'id' },
				{ label: 'From', key: 'from' },
				{ label: 'To', key: 'to' },
				{ label: 'Order Type', key: 'orderType' },
				{ label: 'Delivery Date', key: 'deliveryDate' },
				{ label: 'Material Code', key: 'productId' },
				{ label: 'Outer Quantity', key: 'quantity' },
				{ label: 'Master Case Quantity', key: 'packageQuantity' }
			]}
			firstStepPrompt='Download an example CSV file. Do not modify the first row of the CSV. This CSV will inform you on how to structure your data.'
			secondStepPrompt='Edit the CSV file by adding new rows. Rows will be uploaded as new orders into the platform.'
			visible={visible}
			onCancel={onCancel}
			onComplete={onComplete}
			downloadTask={Upload.downloadOrdersCsv}
			uploadTask={Upload.uploadOrdersCsv}
		/>
	)
}

export default UploadOrdersModal
