import { Companies } from '../../services/api/companies'
import {
	FETCH_COMPANIES_SUCCESS,
	FETCH_COMPANY_DETAILS_SUCCESS,
	UPDATE_COMPANY_DETAILS_SUCCESS,
	UPDATE_COMPANY_SUCCESS,
	UPDATE_PREFERRED_VEHICLES_SUCCESS
} from './action-types'

export function fetchCompanies (page, searchTerm) {
	return async (dispatch) => {
		const response = await Companies.index({ page, searchTerm })
		dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchCompanyDetails (id) {
	return async (dispatch) => {
		const response = await Companies.single(id)
		dispatch({ type: FETCH_COMPANY_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateCompanyDetails (id, params) {
	return async (dispatch) => {
		const response = await Companies.updateDetails(id, params)
		dispatch({ type: UPDATE_COMPANY_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updatePreferredVehicles (id, params) {
	return async (dispatch) => {
		const response = await Companies.updatePreferredVehicles(id, params)
		dispatch({ type: UPDATE_PREFERRED_VEHICLES_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateCompany (id, params) {
	return async (dispatch) => {
		const response = await Companies.update(id, params)
		dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: { data: response.data, id } })
	}
}
