import React from 'react'
import Split from 'react-split'
import styles from './splitter.module.css'

const Splitter = ({ children, disabled }) => {
	return (
		<Split
			className={styles.split}
			sizes={disabled ? [25, 75] : [50, 50]}
			expandToMin={false}
			gutterSize={disabled ? 0 : 16}
			gutterAlign='center'
			snapOffset={30}
			direction='horizontal'
			cursor='col-resize'
			gutter={(_, direction) => {
				const gutter = document.createElement('img')
				gutter.src = '/img/slide.svg'
				gutter.className = `gutter gutter-${direction}`
				return gutter
			}}
		>
			{children}
		</Split>
	)
}

Splitter.defaultProps = {
	disabled: false
}

export default Splitter
