import moment from 'moment'
import { get, post, patch, destroy } from './index'

export const SalesOrders = {
	index: ({
		page = 0,
		status,
		subStatus,
		searchTerm,
		minOrderValue,
		maxOrderValue,
		fromDate,
		toDate,
		statuses,
		deliveryPlanStatus,
		accessToken,
		unassigned,
		deliveryPlanId,
		isStockTransfer,
		dateRangeType,
		salesOrderItems,
		pickUpLocationIds
	} = {}) =>
		get('/sales-orders', {
			headers: {
				authorization: accessToken
			},
			params: {
				page,
				status,
				subStatus,
				searchTerm,
				minOrderValue,
				maxOrderValue,
				fromDate,
				toDate,
				deliveryPlanStatus,
				statuses,
				unassigned,
				deliveryPlanId,
				isStockTransfer,
				dateRangeType,
				salesOrderItems,
				pickUpLocationIds
			}
		}),
	single: (
		id, {
			accessToken,
			company
		} = {},
		inventory
	) =>
		get(`/sales-orders/${id}`, {
			headers: {
				authorization: accessToken
			},
			params: {
				company,
				inventory
			}
		}),
	create: (params) =>
		post('/sales-orders', params),
	update: (id, {
		status,
		subStatus,
		flagReason,
		deliveryDate,
		orderDate,
		paymentMethod,
		distributorAdvancePayment,
		transportFareAmount,
		additionalNotes,
		salesOrderItems,
		internalId,
		locationId,
		location,
		loadingLocationId,
		loadingLocation,
		unloadingLocationId,
		unloadingLocation,
		pickUpLocationId,
		pickUpLocation,
		attachments,
		truckPlateNumber,
		discount,
		createSubSalesOrder,
		disableAutoApproveSubSalesOrder,
		customerId
	} = {}) =>
		patch(`/sales-orders/${id}`, {
			status,
			subStatus,
			flagReason,
			deliveryDate,
			orderDate,
			paymentMethod,
			distributorAdvancePayment,
			transportFareAmount,
			additionalNotes,
			salesOrderItems,
			internalId,
			locationId,
			location,
			loadingLocationId,
			loadingLocation,
			unloadingLocationId,
			unloadingLocation,
			pickUpLocationId,
			pickUpLocation,
			attachments,
			truckPlateNumber,
			discount,
			createSubSalesOrder,
			disableAutoApproveSubSalesOrder,
			customerId
		}),
	remove: (id) =>
		destroy(`/sales-orders/${id}`),
	downloadAttachment: async (downloadUrl, fileName) => {
		const response = await get(downloadUrl, {
			responseType: 'blob'
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadCSV: async ({ statuses, toDate, fromDate, isStockTransfer, dateRangeType, columns }) => {
		const response = await get('/sales-orders/csv', {
			responseType: 'blob',
			params: {
				statuses,
				toDate,
				fromDate,
				isStockTransfer,
				dateRangeType,
				columns
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		const fileName = `sales-orders_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}_${statuses.join('_')}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	fetchOrderStatusFlows: () => {
		return get('/sales-orders/status-flows')
	},
	updateStatus: (id, status) =>
		patch(`/sales-orders/${id}/status/inventory-items`, { status })
}
