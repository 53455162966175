import { Select, Spin, Empty } from 'antd'
import styles from './CustomSearchableSelect.module.css'
// We use Less styles to override Ant stylings.
require('./CustomSearchableSelect.less')
import { useState, useMemo, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

const { Option } = Select

const CustomSearchableSelect = ({
	style,
	value,
	onChange = () => {},
	onChangeData = () => {},
	onClear,
	placeholder,
	searchTask, // Should be a function that takes in a search constraint parameter and returns a Promise that resolves to an array of results
	labelIndex, // The field that should be the label when creating options asynchronously
	valueIndex, // The field that should be the value when creating options asynchronously
	descriptionIndex = -1, // The field that should be the description value when creating options
	disabledOptions = { }, // Map of options to disable.
	defaultOptions = [],
	isLoading,
	disabled,
	unclipOptions = false, // If true, allows word wrapping.
	title,
	showSearch = true,
	labelInValue = true,
	allowClear = false,
	customLabel, // A function in the form: (data) => {}, that can be used to formulate a label for the option.
	customDescription, // A function in the form: (data) => {}, that can be used to formulate a description for the option.
	popupContainer
}) => {
	const [loading, setLoading] = useState(isLoading ? isLoading : false)
	const [options, setOptions] = useState([])

	useEffect(() => {
		setLoading(isLoading)
	}, [isLoading])

	useEffect(() => {
		if (defaultOptions && defaultOptions.length > 0) {
			const options = mapOptions(defaultOptions)
			setOptions(options)
		}
	}, [defaultOptions])

	const fetchRef = useRef(0)
	const debounceSearch = useMemo(() => {
		const loadOptions = searchParam => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setLoading(true)
			searchTask(searchParam)
				.then(response => {
					if (fetchId !== fetchRef.current) {
						return
					}
					const { data } = response
					const results = Array.isArray(data) ? data : data.results
					if (results.length) {
						const newOptions = mapOptions(results)
						setOptions(newOptions)
					} else {
						setOptions([])
					}
				})
				.finally(() => setLoading(false))
		}
		return debounce(loadOptions, 800)
	})

	const mapOptions = data => {
		return data.map(result => {
			let label = result[labelIndex]
			if (customLabel) {
				label = customLabel(result)
			}
			let value = result[valueIndex]
			let description = result[descriptionIndex]
			if (customDescription) {
				description = customDescription(result)
			}
			return {
				label,
				value,
				data: result,
				disabled: disabledOptions[value],
				description
			}
		})
	}

	const handleChange = value => {
		onChange(value)
		const option = options.find(option => value && option.value === value.value)
		if (option) {
			onChangeData(option.data)
		}
	}

	return (
		title ?
			<div className={`custom-select-container ${title && styles.titleVisible}`}>
				<h4 style={{ marginBottom: -.85 }}>{title}</h4>
				<Select
					allowClear={allowClear}
					showSearch={showSearch}
					notFoundContent={loading ? <Spin size='small' /> : <Empty />}
					labelInValue={labelInValue}
					filterOption={false}
					loading={loading}
					placeholder={placeholder}
					className={`custom-select-small ${title && 'searchable-select-title-visible'}`}
					value={value}
					showArrow={false}
					onSearch={showSearch ? debounceSearch : undefined}
					onChange={handleChange}
					onClear={onClear}
					disabled={disabled}
					style={style}
					getPopupContainer={() => popupContainer ? popupContainer() : document.body}
				>
					{
						options.map(option => {
							return (
								<Option
									className={unclipOptions ? 'full-custom-option' : ''}
									key={option.value}
									value={option.value}
									disabled={disabledOptions[option.value]}
								>
									<div className='custom-option'>
										<span className='custom-option-value'>{option.label}</span>
										<div className='custom-option-description'>{option.description}</div>
									</div>
								</Option>
							)
						})
					}
				</Select>
			</div> :
			<Select
				allowClear={allowClear}
				showSearch
				notFoundContent={loading ? <Spin size='small' /> : <Empty />}
				labelInValue={labelInValue}
				filterOption={false}
				options={options}
				loading={loading}
				placeholder={placeholder}
				className='custom-select'
				value={value}
				showArrow={false}
				onSearch={showSearch ? debounceSearch : undefined}
				onChange={handleChange}
				onClear={onClear}
				disabled={disabled}
				style={style}
				getPopupContainer={() => popupContainer ? popupContainer() : document.body}
			>
				{
					options.map(option => {
						return (
							<Option
								className={unclipOptions ? 'full-custom-option' : ''}
								key={option.value}
								value={option.value}
								disabled={disabledOptions[option.value]}
							>{option.label}
							</Option>
						)
					})
				}
			</Select>
	)
}

export default CustomSearchableSelect
