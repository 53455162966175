export const convertEnumToString = (enumField) => {
	if (!enumField) {
		return
	}
	return enumField.replace(/_/g, ' ')
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export const convertStringToEnum = (string) => {
	if (!string) return ''
	return string.replace(/\W+/g, ' ')
		.split(/ |\B(?=[A-Z])/)
		.map(word => word.toLowerCase())
		.join('_')
}

export const convertCamelCaseToString = (string) => {
	const result = string.replace(/([A-Z])/g, ' $1')
	return result.charAt(0).toUpperCase() + result.slice(1)
}

export const getAbbreviation = (text) => {
	if (typeof text != 'string' || !text) {
		return ''
	}
	if (text.trim().split(' ').length === 1) {
		return text.toUpperCase().substring(0, 5)
	} else {
		let acronym = text.match(/\b([A-Z])/g)
		if (acronym) {
			return acronym.join('').substring(0, 5)
		} else {
			return ''
		}
	}
}

export const getFormattedCurrency = (amount, hasCurrencyTag = true) => {
	let value = amount ? Number.parseFloat(amount) : 0.0
	value = isNaN(value) ? 0.0 : value

	return hasCurrencyTag ?
		value.toLocaleString('en-US', {
			style: 'currency',
			currency: 'BDT',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}) :
		value.toLocaleString('en-US', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		})
}

export const numberToAbbreviatedString = (num, fixed) => {
	if (num === null) return null
	if (num === 0) return '0'
	fixed = !fixed || fixed < 0 ? 0 : fixed
	var b = num.toPrecision(2).split('e'),
		k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
		c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
		d = c < 0 ? c : Math.abs(c),
		e = d + ['', 'K', 'M', 'B', 'T'][k]
	return e
}

export const calculateDiscount = (amount, discountInPercentage = 0) => {
	return ((amount * discountInPercentage) / 100).toFixed(2)
}

export const isEmptyObject = object => Object.keys(object).length === 0

export const isValidUUID = (str) => {
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
	return regexExp.test(str)
}

export const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
	const p = 0.017453292519943295 // Math.PI / 180
	const a = 0.5 - Math.cos((lat2 - lat1) * p) / 2 +
			Math.cos(lat1 * p) * Math.cos(lat2 * p) *
			(1 - Math.cos((lon2 - lon1) * p)) / 2

	return 12742 * Math.asin(Math.sqrt(a)) * 1000
}

export const removeURLParameter = (url, parameter) => {
	const urlparts = url.split('?')
	if (urlparts.length >= 2) {

		const prefix = encodeURIComponent(parameter) + '='
		const pars = urlparts[1].split(/[&;]/g)

		for (let i = pars.length; i-- > 0;) {
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1)
			}
		}

		return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
	}
	return url
}

export const isNotEmpty = (value) => {
	return value !== null && value !== undefined && value !== '' && value !== 'undefined' && value !== 'null'
}

export const formatCurrency = (number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number).replace('$', '')
