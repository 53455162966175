import { HYDRATE } from 'next-redux-wrapper'

import {
	CREATE_REPORTS_SUCCESS,
	FETCH_REPORTS_SUCCESS,
	FETCH_REPORT_DETAIL_SUCCESS
} from './action-types'

const initialState = {
	totalCount: 0,
	page: 0,
	pageSize: 0,
	reports: [],
	reportDetails: {}
}

const reportsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case FETCH_REPORTS_SUCCESS: {
			return {
				...state,
				reports: payload.data.results,
				totalCount: payload.data.count,
				page: +payload.data.page
			}
		}
		case FETCH_REPORT_DETAIL_SUCCESS: {
			const newState = { ...state }
			newState.reportDetails[payload.id] = payload
			return newState
		}
		case CREATE_REPORTS_SUCCESS: {
			const reports = [payload, ...state.reports]
			return {
				...state,
				reports
			}
		}
		default:
			return state
	}
}

export default reportsReducer
