import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Empty, Modal, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { SALES_ORDER_APPROVED_STATUS } from '../../pages/sales-orders'
import { convertEnumToString, getFormattedCurrency } from '../../utils'
import Table from '../table'
import moment from 'moment'
import Link from 'next/link'
import styles from './AddRoutePointsModal.module.css'
import { fetchSalesOrders } from '../../store/sales-orders/actions'
import { fetchDeliveryPlanDetails } from '../../store/delivery-plans/actions'
import { DELIVERY_PLAN_PENDING_STATUS } from '../../pages/delivery-plans'
import { getShortId } from '../../utils/sales-orders'
import SearchInput from '../search-input'
import Input from '../input'
import { useDebounceValue } from '../../hooks/useDebounceValue'
import { paths } from '../../utils/constants'
import { LocationTypes } from '../location-modal'

const salesOrderColumns = [
	{
		title: 'Date',
		key: 'deliveryDate',
		render: salesOrder => {
			return moment(salesOrder.deliveryDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Order #',
		key: 'salesOrderID',
		render: salesOrder => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
					<a className={styles.link}>{getShortId(salesOrder)}</a>
				</Link>
			)
		}
	},
	{
		title: 'Assigned To',
		key: 'assignedDeliveryPlanID',
		render: salesOrder => {
			return (
				salesOrder.deliveryPlan ?
					<Link href={`/delivery-plans/${salesOrder.deliveryPlan.id}`}>
						<a className={styles.link}>
							{`DP-${salesOrder.deliveryPlan.shortId}`}
						</a>
					</Link>
					:
					<div className={styles.unassigned}>
						Unassigned
					</div>
			)
		}
	},
	{
		title: 'Area Manager',
		key: 'areaManager',
		render: salesOrder => {
			if (salesOrder.distributor && salesOrder.distributor.accountManager) {
				return `${salesOrder.distributor.accountManager.name}`
			} else {
				return (
					<div className={styles.unassigned}>
						Unassigned
					</div>
				)
			}
		}
	},
	{
		title: 'Pick Up',
		key: 'from',
		render: salesOrder => {
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						salesOrder.pickUpLocation &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div className={styles.badge}>
								{convertEnumToString(salesOrder.pickUpLocation.type.toLowerCase())}
							</div>
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(salesOrder.pickUpLocation.type) ?
									<Link href={`${paths.WAREHOUSES}/${salesOrder.pickUpLocation.id}`}>
										<a>
											{salesOrder.pickUpLocation.label}
											<div className={styles.location}>
												{salesOrder.pickUpLocation.address}
											</div>
										</a>
									</Link> :
									<div>
										{salesOrder.pickUpLocation.label}
										<div className={styles.location}>
											{salesOrder.pickUpLocation.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Drop Off',
		key: 'to',
		render: salesOrder => {
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						salesOrder.distributor ?
							<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
								<div className={styles.badge} style={{ color: '#278EA5', background: '#ecf9f9' }}>
									Distributor
								</div>
								<Link href={`${paths.DISTRIBUTORS}/${salesOrder.distributor.id}`}>
									<a style={{ color: '#278EA5' }}>
										{salesOrder.distributor.name}
									</a>
								</Link>
							</div> : null
					}
					{
						salesOrder.location &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								!salesOrder.distributor &&
								<div className={styles.badge}>
									{convertEnumToString(salesOrder.location.type.toLowerCase())}
								</div>
							}
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(salesOrder.location.type) ?
									<Link href={`${paths.WAREHOUSES}/${salesOrder.location.id}`}>
										<a>
											{salesOrder.location.label}
											<div className={styles.location}>
												{salesOrder.location.address}
											</div>
										</a>
									</Link> :
									<div>
										{salesOrder.location.label}
										<div className={styles.location}>
											{salesOrder.location.address}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Sales Amount',
		key: 'salesAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.totalAmount)}`
		}
	},
	{
		title: 'Advance Amount',
		key: 'advanceAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.distributorAdvancePayment)}`
		}
	},
	{
		title: 'Payment Method',
		key: 'paymentMethod',
		render: salesOrder => {
			return `${convertEnumToString(salesOrder.paymentMethod)}`
		}
	}
]

const arrayToObject = (arr) => {
	return arr.reduce((acc, currentValue) => ({ ...acc, [currentValue]: currentValue }), {})
}

const AddRoutePointsModal = ({ onCancel, visible, deliveryPlanId, onAddClicked }) => {
	const dispatch = useDispatch()
	const {
		totalCount,
		page,
		approvedSalesOrders
	} = useSelector(state => state.salesOrdersReducer)

	const [isFetching, setIsFetching] = useState(false)
	const [isFetchingDeliveryPlanDetail, setIsFetchingDeliveryPlanDetail] = useState(false)
	const [selectedSalesOrderIds, setSelectedSalesOrderIds] = useState([])
	const [selectedSalesOrders, setSelectedSalesOrders] = useState([])
	const [previousSelectedSalesOrders, setPreviousSelectedSalesOrders] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const debouncedSearchTerm = useDebounceValue(searchTerm, 500)
	const { deliveryPlanDetails } = useSelector(state => state.deliveryPlansReducer)
	const deliveryPlanDetail = deliveryPlanDetails[deliveryPlanId]
	const [showUnassigned, setShowUnassigned] = useState(false)
	const [showAssigned, setShowAssigned] = useState(false)
	const [showStockTransferOrders, setShowStockTransferOrders] = useState(false)
	const [showSalesOrders, setShowSalesOrders] = useState(false)
	const [showAssignedToDeliveryPlan, setShowAssignedToDeliveryPlan] = useState(false)

	useEffect(() => {
		getSalesOrders()
	}, [
		debouncedSearchTerm,
		toDate,
		fromDate,
		showAssigned,
		showUnassigned,
		showSalesOrders,
		showStockTransferOrders,
		showAssignedToDeliveryPlan
	])

	useEffect(() => {
		if (deliveryPlanId) {
			getDeliveryPlanDetails(deliveryPlanId)
		}
	}, [deliveryPlanId])

	useEffect(() => {
		if (deliveryPlanDetail) {
			const salesOrderIds = deliveryPlanDetail.salesOrders.map(so => so.id)
			setSelectedSalesOrderIds(salesOrderIds)
			setPreviousSelectedSalesOrders(arrayToObject(salesOrderIds))
		}
	}, [deliveryPlanDetail])

	const getSalesOrders = async () => {
		setIsFetching(true)
		await dispatch(fetchSalesOrders(getSearchParams()))
		setIsFetching(false)
	}

	const getDeliveryPlanDetails = async (deliveryPlanId) => {
		if (deliveryPlanId) {
			setIsFetchingDeliveryPlanDetail(true)
			await dispatch(fetchDeliveryPlanDetails(deliveryPlanId))
			setIsFetchingDeliveryPlanDetail(false)
		}
	}

	const getSearchParams = (page = 0) => {
		const params = {
			status: SALES_ORDER_APPROVED_STATUS,
			page: page,
			deliveryPlanStatus: DELIVERY_PLAN_PENDING_STATUS
		}
		if (debouncedSearchTerm) {
			params.searchTerm = debouncedSearchTerm
		}
		if (fromDate && toDate) {
			params.searchTerm = debouncedSearchTerm
			params.fromDate = fromDate
			params.toDate = toDate
		}
		if (showAssigned) {
			params.unassigned = false
		}
		if (showUnassigned) {
			params.unassigned = true
		}
		if (showAssigned && showUnassigned) {
			delete params.unassigned
		}
		if (showSalesOrders) {
			params.isStockTransfer = false
		}
		if (showStockTransferOrders) {
			params.isStockTransfer = true
		}
		if (showSalesOrders && showStockTransferOrders) {
			delete params.isStockTransfer
		}
		if (showAssignedToDeliveryPlan && deliveryPlanDetail) {
			params.deliveryPlanId = deliveryPlanDetail.id
		}
		return params
	}

	const isModified = () => {
		return JSON.stringify(arrayToObject(selectedSalesOrderIds)) !== JSON.stringify(previousSelectedSalesOrders)
	}

	const onPageChanged = async (page) => {
		setIsFetching(true)
		await dispatch(fetchSalesOrders(getSearchParams(page - 1)))
		setIsFetching(false)
	}

	const onSelectChange = (selectedRowKeys, selectedRows) => {
		setSelectedSalesOrderIds(selectedRowKeys)
		setSelectedSalesOrders(selectedRows)
	}

	const onAdd = () => {
		const addedSalesOrders = selectedSalesOrders.filter(so => so && !previousSelectedSalesOrders[so.id])
		onAddClicked(addedSalesOrders)
		onCancel()
	}

	const isLoading = () => {
		return (isFetching && approvedSalesOrders.length === 0) || isFetchingDeliveryPlanDetail
	}

	const renderModalFooter = () => {
		return [
			<Button
				key='update-delivery-plan'
				type='primary'
				shape='round'
				size='large'
				disabled={!selectedSalesOrderIds.length || !isModified()}
				onClick={onAdd}
			>
				Add
			</Button>
		]
	}

	return (
		<Modal
			title={`Delivery Plan: DP-${deliveryPlanDetail?.shortId}`}
			visible={visible}
			onCancel={onCancel}
			width={1400}
			footer={renderModalFooter()}
			destroyOnClose={true}
		>
			<Space size='large'>
				<SearchInput
					style={{ width: 400, height: 65 }}
					placeholder='Search Order Number, Distributor Name'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				<Space>
					<Input
						title='From'
						type='date'
						style={{ background: 'white' }}
						value={fromDate ? moment(fromDate) : null}
						disabledDate={current => current && current.valueOf() > moment(toDate)}
						onChange={(date) => {setFromDate(date?.toDate())}}
					/>
					<Input
						title='To'
						type='date'
						style={{ background: 'white' }}
						value={toDate ? moment(toDate) : null }
						onChange={(date) => setToDate(date?.toDate())}
						disabledDate={current => current && current.valueOf() < moment(fromDate)}
					/>
				</Space>
				<Space
					size='large'
				>
					<Space direction='vertical'>
						<Checkbox onChange={(e) => setShowSalesOrders(e.target.checked)}>
							Sales Orders
						</Checkbox>
						<Checkbox onChange={(e) => setShowStockTransferOrders(e.target.checked)}>
							Stock Transfer Orders
						</Checkbox>
					</Space>
					<Space direction='vertical'>
						<Checkbox onChange={(e) => setShowAssigned(e.target.checked)}>
							Show assigned orders
						</Checkbox>
						<Checkbox onChange={(e) => setShowUnassigned(e.target.checked)}>
							Show unassigned orders
						</Checkbox>
						<Checkbox onChange={(e) => setShowAssignedToDeliveryPlan(e.target.checked)}>
							Show orders assigned to this delivery plan
						</Checkbox>
					</Space>
				</Space>
			</Space>
			{
				isFetching || approvedSalesOrders.length > 0 ?
					<Table
						loading={isLoading()}
						columns={salesOrderColumns}
						dataSource={approvedSalesOrders}
						style={{ marginBottom: 0, marginTop: 24 }}
						pagination={{
							pageSize: 50,
							total: totalCount,
							position: ['topLeft'],
							onChange: onPageChanged,
							showSizeChanger: false,
							current: page + 1
						}}
						scroll={{ y: 'calc(100vh - 600px)' }}
						rowKey='id'
						rowSelection={{
							preserveSelectedRowKeys: true,
							selectedRowKeys: selectedSalesOrderIds,
							onChange: (rowIds, rows) => onSelectChange(rowIds, rows),
							getCheckboxProps: (record) => ({
								disabled: !!previousSelectedSalesOrders[record.id]
							})
						}}
					/> :
					<Empty
						description='Try discussing internally on how we can increase sales or try asking clients on how can we add more value to them.'
						image='/img/sales-orders-empty.svg'
						imageStyle={{ height: '450px', marginBottom: '24px' }}
						style={{ marginTop: '128px' }}
					/>
			}
		</Modal>
	)
}

AddRoutePointsModal.defaultProps = {
	onCancel: () => { },
	onAddClicked: () => { },
	visible: false,
	deliveryPlanId: null
}

export default AddRoutePointsModal

