import { HYDRATE } from 'next-redux-wrapper'
import {
	ADD_NOTIFICATION,
	UPDATE_NOTIFICATION,
	FETCH_NOTIFICATIONS_SUCCESS,
	FETCH_UNREAD_NOTIFICATIONS_SUCCESS,
	READ_NOTIFICATION,
	SET_NOTIFICATION_COUNT,
	SET_UNREAD_NOTIFICATION_COUNT
} from './action-types'

const initialState = {
	unreadCount: 0,
	count: 0,
	unreadNotifications: [],
	unreadNotificationsMap: {},
	notifications: []
}

const notificationsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case HYDRATE: {
			return { ...state, ...payload }
		}
		case ADD_NOTIFICATION: {
			if (state.unreadNotificationsMap[payload.id]) {
				return state
			}
			const unreadNotifications = [payload, ...state.unreadNotifications]
			const notifications = [payload, ...state.notifications]
			return {
				...state,
				unreadNotificationsMap: {
					...state.unreadNotificationsMap,
					[payload.id]: true
				},
				notifications,
				unreadNotifications
			}
		}
		case UPDATE_NOTIFICATION: {
			const unreadNotifications = [payload, ...state.unreadNotifications.filter(notification => notification.id !== payload.id)]
			return {
				...state,
				unreadNotificationsMap: {
					...state.unreadNotificationsMap,
					[payload.id]: true,
					unreadNotifications
				}
			}
		}
		case FETCH_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				notifications: payload
			}
		}
		case FETCH_UNREAD_NOTIFICATIONS_SUCCESS: {
			return {
				...state,
				unreadNotifications: payload
			}
		}
		case SET_UNREAD_NOTIFICATION_COUNT: {
			return {
				...state,
				unreadCount: payload
			}
		}
		case SET_NOTIFICATION_COUNT: {
			return {
				...state,
				count: payload
			}
		}
		case READ_NOTIFICATION: {
			const id = payload
			return {
				...state,
				notifications: state.notifications.map(notification => notification.id === id ?
					{
						...notification,
						isRead: true
					} :
					notification
				),
				unreadNotifications: state.unreadNotifications.filter(notification => notification.id !== id)
			}
		}
		default:
			return state
	}
}

export default notificationsReducer
