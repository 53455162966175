import { Button, Table } from 'antd'
// We use Less styles to override Ant stylings.
require('./Table.less')
import { SyncOutlined } from '@ant-design/icons'

const CustomTable = (props) => {
	return (
		<div style={{ position: 'relative' }}>
			{
				props.refresh &&
				props.dataSource &&
				props.dataSource.length > 0 &&
				<Button
					onClick={props.refresh}
					style={{
						position: props.pagination ? 'absolute' : 'relative',
						right: props.pagination ? 12 : '0',
						top: props.pagination ? props.size === 'default' || !props.size ? 16 : 12 : '0',
						zIndex: 10,
						marginBottom: props.pagination ? 0 : 12
					}}
					loading={props.loading}
					icon={<SyncOutlined />}
				/>
			}
			<Table
				className='custom-table'
				{...props}
			/>
		</div>
	)
}

export default CustomTable
