import { useState } from 'react'
import { Button, Divider, Modal, Space } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import SearchInput from '../search-input'
import moment from 'moment'
import Select from '../../components/custom-select'
import useSearchParams from '../../hooks/useSearchParams'
import styles from './ReportFilterModal.module.css'
import { paths } from '../../utils/constants'
import { getFormattedReportType, ReportTypes } from '../../utils/reports'

const reportTypeOptions = [
	{
		label: getFormattedReportType(ReportTypes.ORDER_FULFILLMENT),
		value: ReportTypes.ORDER_FULFILLMENT
	},
	{
		label: getFormattedReportType(ReportTypes.PENDING_ORDERS),
		value: ReportTypes.PENDING_ORDERS
	},
	{
		label: getFormattedReportType(ReportTypes.TRACEABILITY),
		value: ReportTypes.TRACEABILITY
	},
	{
		label: getFormattedReportType(ReportTypes.NATIONAL_ON_HAND_STOCK),
		value: ReportTypes.NATIONAL_ON_HAND_STOCK
	},
	{
		label: getFormattedReportType(ReportTypes.BY_LOCATION_ON_HAND_STOCK),
		value: ReportTypes.BY_LOCATION_ON_HAND_STOCK
	}
]

const ReportFilterModal = ({ visible, onCancel }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [type, setType] = useState()
	const { setSearchParams, clearSearchParams } = useSearchParams()
	const router = useRouter()

	const isFilterEnabled = () => {
		return searchTerm || (fromDate && toDate) || type
	}

	const onApply = async () => {
		const searchParams = {
			searchTerm
		}
		if (fromDate && toDate) {
			searchParams.fromDate = fromDate
			searchParams.toDate = toDate
		}
		if (type) {
			searchParams.type = type
		}

		setSearchParams(searchParams)
		router.push(paths.REPORTS)
		onCancel()
	}

	const resetFilter = () => {
		setSearchTerm('')
		setFromDate(null)
		setToDate(null)
		setType()
		clearSearchParams()
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '265px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						disabled={!isFilterEnabled()}
						onClick={onApply}
					>
						Apply
					</Button>
				]
			}
		>
			<div className={styles.container}>
				<div className={styles.searchBox}>
					<SearchInput
						placeholder='Search Report ID'
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</div>
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Creation Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
				<div className='section'>
					<h3 className={styles.label}>Report Type</h3>
					<Select
						style={{ width: '100%' }}
						onChange={option => setType(option)}
						value={type}
						placeholder='Select Report Type'
						options={reportTypeOptions}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ReportFilterModal
