import styles from './PhoneInput.module.css'
require('./PhoneInput.less')
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const CustomPhoneInput = ({ disabled, title, value, onChange, placeholder = 'Phone Number' }) => {
	return (
		<div
			className={`${styles.phoneInput} ${title && styles.phoneInputLarge}`}
		>
			{
				title &&	<span className={styles.title}>{title}</span>
			}
			<PhoneInput
				placeholder={!title && placeholder ? placeholder : ''}
				value={value}
				onChange={(phoneNumber) => onChange(phoneNumber ? `+${phoneNumber}` : '')}
				disabled={disabled}
			/>
		</div>
	)
}

export default CustomPhoneInput
