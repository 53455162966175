import { Users } from '../../services/api/users'
import { Permissions } from '../../services/api/permissions'
import {
	SET_AUTH_USER,
	FETCH_USER_PROFILE_SUCCESS,
	SET_ROLE,
	SET_PLAN,
	SIGN_OUT,
	FETCH_PERMISSIONS_SUCCESS,
	SET_COMPANY_ID
} from './action-types'

export function setAuthUser (authUser) {
	return async (dispatch) => {
		dispatch({ type: SET_AUTH_USER, payload: authUser })
	}
}

export function fetchUserProfile () {
	return async (dispatch) => {
		const response = await Users.single('current')
		dispatch({ type: FETCH_USER_PROFILE_SUCCESS, payload: response.data })
	}
}

export function fetchPermissions () {
	return async (dispatch) => {
		const response = await Permissions.fetchPermissions()
		dispatch({ type: FETCH_PERMISSIONS_SUCCESS, payload: response.data })
	}
}

export function setRole (role) {
	return async (dispatch) => {
		dispatch({ type: SET_ROLE, payload: role })
	}
}

export function setCompanyId (cid) {
	return async (dispatch) => {
		dispatch({ type: SET_COMPANY_ID, payload:cid })
	}
}

export function setPlan (plan) {
	return async (dispatch) => {
		dispatch({ type: SET_PLAN, payload: plan })
	}
}

export function signOut () {
	return async (dispatch) => {
		dispatch({ type: SIGN_OUT })
	}
}
