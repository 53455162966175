let autocompleteService
const getAutocompleteService = () => {
	if (!autocompleteService) {
		autocompleteService = new window.google.maps.places.AutocompleteService()
	}
	return autocompleteService
}

let geocoder
const getGeocoder = () => {
	if (!geocoder) {
		geocoder = new window.google.maps.Geocoder()
	}
	return geocoder
}

export const Maps = {
	autocomplete: (searchTerm, country) => {
		return new Promise(resolve => {
			if (!searchTerm || searchTerm.trim().length === 0) {
				resolve([])
				return
			}
			getAutocompleteService().getPlacePredictions(
				{
					input: searchTerm,
					componentRestrictions: { country }
				},
				(predictions, status) => {
					if (status === window.google.maps.places.PlacesServiceStatus.OK) {
						resolve(predictions)
					} else {
						resolve([])
					}
				}
			)
		})
	},
	getPlaceDetails: (placeId) => {
		return new Promise(resolve => {
			getGeocoder().geocode(
				{
					placeId
				},
				(results, status) => {
					if (status === window.google.maps.GeocoderStatus.OK) {
						resolve(results[0])
					} else {
						resolve()
					}
				}
			)
		})
	},
	reverseGeocode: (lat, lng) => {
		return new Promise(resolve => {
			getGeocoder().geocode({
				location: { lat, lng }
			}, (results, status) => {
				if (status === window.google.maps.GeocoderStatus.OK) {
					resolve(results[0].formatted_address)
				} else {
					resolve()
				}
			})
		})
	}
}
