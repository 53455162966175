export const ReportTypes = {
	ORDER_FULFILLMENT: 'ORDER_FULFILLMENT',
	PENDING_ORDERS: 'PENDING_ORDERS',
	TRACEABILITY: 'TRACEABILITY',
	NATIONAL_ON_HAND_STOCK: 'NATIONAL_ON_HAND_STOCK',
	BY_LOCATION_ON_HAND_STOCK: 'BY_LOCATION_ON_HAND_STOCK'
}

export const getFormattedReportType = (type) => {
	switch (type) {
		case ReportTypes.ORDER_FULFILLMENT:
			return 'Order Fulfillment'
		case ReportTypes.PENDING_ORDERS:
			return 'Pending Orders'
		case ReportTypes.NATIONAL_ON_HAND_STOCK:
			return 'National - On Hand Stock'
		case ReportTypes.BY_LOCATION_ON_HAND_STOCK:
			return 'By Location - On Hand Stock'
		case ReportTypes.TRACEABILITY:
			return 'Traceability'
		default:
			return type
	}
}
