import { useState, useEffect } from 'react'
import { Button, Checkbox, Col, Divider, Modal, notification, Row, Space, Radio } from 'antd'
import { useRouter } from 'next/router'
import Input from '../input'
import moment from 'moment'
import 'moment-timezone'
import {
	SALES_ORDER_APPROVED_STATUS,
	SALES_ORDER_CANCELLED_STATUS,
	SALES_ORDER_COMPLETED_STATUS,
	SALES_ORDER_FLAGGED_STATUS,
	SALES_ORDER_PROCESSING_STATUS,
	SALES_ORDER_REQUESTED_STATUS,
	EXPORT_TYPE
} from '../../pages/sales-orders'

export const ORDER_CREATION_DATE = 'createdAt'
export const ORDER_DATE = 'orderDate'
export const DELIVERY_DATE = 'deliveryDate'

import styles from './SalesOrdersExportModal.module.css'
import { SalesOrders } from '../../services/api/sales-orders'
import { Pdf } from '../../services/api/pdf'

const SalesOrdersExportModal = ({ visible, onCancel, exportType, filteredColumns }) => {
	const [fromDate, setFromDate] = useState(null)
	const [toDate, setToDate] = useState(null)
	const [statuses, setStatuses] = useState([SALES_ORDER_REQUESTED_STATUS])
	const [isExporting, setIsExporting] = useState(false)
	const [isStockTransfer, setIsStockTransfer] = useState(undefined)
	const [dateRangeType, setDateRangeType] = useState(ORDER_DATE)
	const router = useRouter()
	const { query } = router

	const statusOptions = [
		{ label: 'Requested', value: SALES_ORDER_REQUESTED_STATUS },
		{ label: 'Approved', value: SALES_ORDER_APPROVED_STATUS },
		{ label: 'Completed', value: SALES_ORDER_COMPLETED_STATUS },
		{ label: 'Flagged', value: SALES_ORDER_FLAGGED_STATUS },
		{ label: 'Processing', value: SALES_ORDER_PROCESSING_STATUS },
		{ label: 'Cancelled', value: SALES_ORDER_CANCELLED_STATUS }
	]

	useEffect(() => {
		if (query.status) {
			setStatuses([query.status])
		}
	}, [query.status])

	const isFilterEnabled = () => {
		return (fromDate && toDate) && statuses.length !== 0
	}

	const onExport = async () => {
		try {
			if (!isFilterEnabled()) {
				return
			}
			const columns = Object.keys(filteredColumns).filter((key) => filteredColumns[key]).join(',')
			const filters = {
				statuses,
				toDate,
				fromDate,
				isStockTransfer,
				dateRangeType,
				columns
			}
			setIsExporting(true)
			if (exportType == EXPORT_TYPE.CSV) {
				await SalesOrders.downloadCSV(filters)
			} else if (exportType == EXPORT_TYPE.PDF) {
				await Pdf.downloadSalesOrders(filters, moment.tz.guess())
			}

		} catch (e) {
			notification.error({
				message: `Unable to export ${exportType}`,
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsExporting(false)
			onCancel()
		}
	}

	const resetFilter = () => {
		setIsStockTransfer(null)
		setDateRangeType(null)
		setFromDate(null)
		setToDate(null)
		if (query.status) {
			setStatuses([query.status])
		} else {
			setStatuses([SALES_ORDER_REQUESTED_STATUS])
		}
	}

	const onStatusesChange = (statuses) => {
		setStatuses(statuses)
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			width={640}
			okText='Apply'
			footer={
				[
					<Button
						style={{
							marginRight: '200px',
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='reset'
						type='primary'
						shape='round'
						size='large'
						onClick={resetFilter}
						ghost
					>
						Reset Filter
					</Button>,
					<Button
						style={{
							border: '2px solid #D9E0E9',
							fontWeight: 'bold'
						}}
						key='cancel'
						type='primary'
						shape='round'
						size='large'
						onClick={onCancel}
						ghost
					>
						Cancel
					</Button>,
					<Button
						style={{ fontWeight: 'bold' }}
						key='apply'
						type='primary'
						shape='round'
						size='large'
						loading={isExporting}
						disabled={!isFilterEnabled()}
						onClick={onExport}
					>
						{`Export ${exportType}`}
					</Button>
				]
			}
		>
			<div className={styles.exportModalContainer}>
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setIsStockTransfer(e.target.value)}
						value={isStockTransfer}
					>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={false}>Sales Order</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={true}>Stock Transfer Order</Radio>
						</div>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Checkbox.Group value={[...statuses]} style={{ width: '100%' }} onChange={onStatusesChange}>
						<Row style={{ rowGap: '10px' }}>
							{
								statusOptions.map(statusOption => {
									return (
										<Col key={statusOption.value} span={8}>
											<Checkbox value={statusOption.value}>{statusOption.label}</Checkbox>
										</Col>
									)
								})
							}
						</Row>
					</Checkbox.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Radio.Group
						style={{ display: 'flex' }}
						onChange={e => setDateRangeType(e.target.value)}
						value={!dateRangeType ? ORDER_DATE : dateRangeType}
					>
						<div className={styles.radioColumn}>
							<Radio checked style={{ marginBottom: 16 }} value={ORDER_CREATION_DATE}>Creation Date</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={ORDER_DATE}>Order Date</Radio>
						</div>
						<div className={styles.radioColumn}>
							<Radio style={{ marginBottom: 16 }} value={DELIVERY_DATE}>Delivery Date</Radio>
						</div>
					</Radio.Group>
				</div>
				<Divider />
				<div className={styles.section}>
					<Space>
						<h3 className={styles.label}>Date Range</h3>
						<Input
							style={{ maxWidth: 170 }}
							title='From'
							type='date'
							value={fromDate ? moment(fromDate) : null }
							onChange={(date) => setFromDate(date?.toDate())}
						/>
						<Divider>
							<div className={styles.dash} />
						</Divider>
						<Input
							style={{ maxWidth: 170 }}
							title='To'
							type='date'
							value={toDate ? moment(toDate) : null }
							onChange={(date) => setToDate(date?.toDate())}
							disabledDate={current => current && current.valueOf() < moment(fromDate)}
						/>
					</Space>
				</div>
			</div>
		</Modal>
	)
}

SalesOrdersExportModal.defaultProps = {
	exportType: ''
}

export default SalesOrdersExportModal
