import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

const initFirebase = () => {
	if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
		if (!firebase.apps.length) {
			firebase.initializeApp({ projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID })
		}
		const db = firebase.firestore()
		const localUrl = process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST.split(':')
		db.useEmulator(localUrl[0], localUrl[1])
	}
	if (process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST) {
		const storage = firebase.storage()
		const localUrl = process.env.NEXT_PUBLIC_STORAGE_EMULATOR_HOST.split(':')
		storage.useEmulator(localUrl[0], localUrl[1])
	}
}

export default initFirebase
