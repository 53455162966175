import { Vendors } from '../../services/api/vendors'
import {
	ADD_VENDOR_SUCCESS,
	FETCH_VENDORS_SUCCESS,
	FETCH_VENDOR_DETAILS_SUCCESS,
	FETCH_VENDOR_METRICS_SUCCESS,
	UPDATE_VENDOR_SUCCESS
} from './action-types'

export function fetchVendors ({
	page,
	disabled,
	searchTerm,
	fromDate,
	toDate
}) {
	return async (dispatch) => {
		const response = await Vendors.index({
			page,
			disabled,
			searchTerm,
			fromDate,
			toDate
		})
		dispatch({ type: FETCH_VENDORS_SUCCESS, payload: response.data })
	}
}

export function addVendor (params) {
	return async (dispatch) => {
		const response = await Vendors.create(params)
		dispatch({ type: ADD_VENDOR_SUCCESS, payload: response.data ? response.data : [] })
	}
}

export function fetchVendorDetails (id) {
	return async (dispatch) => {
		const response = await Vendors.single(id)
		dispatch({ type: FETCH_VENDOR_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateVendor (id, params) {
	return async (dispatch) => {
		const response = await Vendors.update(id, params)
		dispatch({ type: UPDATE_VENDOR_SUCCESS, payload: response.data })
	}
}

export function fetchVendorMetrics () {
	return async (dispatch) => {
		const response = await Vendors.metrics()
		dispatch({ type: FETCH_VENDOR_METRICS_SUCCESS, payload: response.data })
	}
}
