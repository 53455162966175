import React, { useRef, useState } from 'react'
import styles from './Input.module.css'
require('./Input.less')
import { Tooltip, DatePicker, InputNumber, Select, Spin, Button } from 'antd'
import { QuestionCircleOutlined, LoadingOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'

export const currencies = [
	{
		value: 'bdt',
		label: 'BDT'
	},
	{
		value: 'usd',
		label: 'USD'
	},
	{
		value: 'pkr',
		label: 'PKR'
	},
	{
		value: 'inr',
		label: 'INR'
	}
]

export const currencyFormatter = currency => {
	if (!currency) return ''
	return `${currency.toUpperCase()} `
}

const Input = ({
	title,
	disabled,
	min,
	max,
	value,
	onChange,
	optional,
	type = 'text',
	tooltip,
	readOnly,
	highlighted = false,
	onCurrencyChanged,
	disableCurrency,
	disabledNormalStyle,
	loading,
	style,
	small,
	placeholder,
	...restProps
}) => {
	const inputContainerRef = useRef(null)
	const [currency, setCurrency] = useState(currencies[0].value)
	const [viewPassword, setViewPassword] = useState(false)

	const determineStyle = () => {
		if (disabled) {
			if (disabledNormalStyle) {
				if (small) {
					return styles.smallInputContainer
				} else {
					return styles.inputContainer
				}
			} else {
				return styles.disabledInputContainer
			}
		} else {
			if (small) {
				if (highlighted) {
					return styles.highlightedSmallInputContainer
				} else {
					return styles.smallInputContainer
				}
			} else {
				if (highlighted) {
					return styles.highlightedInputContainer
				} else {
					return styles.inputContainer
				}
			}
		}
	}

	return (
		<div
			style={{ height: type === 'textarea' ? 82 : null, ...style }}
			ref={inputContainerRef} className={determineStyle()} tabIndex='1'
		>
			<div className={styles.title}>
				{title}
				{
					optional ?
						<div className={styles.optional}>(Optional)</div> : null
				}
				{
					tooltip ?
						<Tooltip placement='right' title={tooltip} className={styles.tooltip}>
							<QuestionCircleOutlined
								size='small'
							/>
						</Tooltip> : null
				}
			</div>
			{
				type === 'textarea' ?
					<textarea
						value={value}
						onChange={onChange}
						disabled={disabled}
						className={styles.input}
					/> : null
			}
			{
				type === 'date' ?
					<DatePicker
						style={style}
						value={value}
						onChange={onChange}
						className='date-picker'
						{...restProps}
					/> : null
			}
			{
				type === 'currency' ?
					<div className='currency-container'>
						<NumberFormat
							className='currency-input'
							value={value}
							onValueChange={values => onChange(values.floatValue)}
							disabled={disabled}
							readOnly={readOnly}
							thousandSeparator={true}
							isNumericString={true}
							prefix={currencyFormatter(currency)}
							placeholder={`${currencyFormatter(currency)} 0.00`}
							decimalScale={2}
							fixedDecimalScale={true}
							onFocus={e => e.target.select()}
							max={max}
							min={min}
							isAllowed={values => {
								const value = values.floatValue
								if (value < min) {
									return false
								}
								if (value > max) {
									return false
								}
								return true
							}}
						/>
						{
							!disableCurrency ?
								<Select
									className='currency-select'
									value={currency}
									onChange={option => {
										if (onCurrencyChanged) {
											onCurrencyChanged(option)
										}
										setCurrency(option)
									}}
									options={currencies}
								/> : null
						}
					</div> : null
			}
			{
				type === 'text' ?
					<input
						type='text'
						value={value}
						readOnly={readOnly}
						onChange={onChange}
						disabled={disabled || loading}
						className={small ? styles.smallInput : styles.input}
						min={min}
						max={max}
						placeholder={placeholder}
						style={style}
					/> : null
			}
			{
				type === 'number' ?
					<InputNumber
						className='number-input'
						value={value}
						onChange={onChange}
						disabled={disabled}
						readOnly={readOnly}
						onFocus={e => e.target.select()}
						max={max}
						min={min}
					/> : null
			}
			{
				type === 'password' ?
					<input
						type={viewPassword ? 'text' : 'password'}
						value={value}
						readOnly={readOnly}
						onChange={onChange}
						disabled={disabled || loading}
						className={small ? styles.smallInput : styles.input}
						min={min}
						max={max}
						placeholder={placeholder}
						style={style}
					/> : null
			}
			{
				loading ?
					<div style={{ position: 'absolute', right: 16, top: '50%', transform: 'translateY(-50%)' }}>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />
					</div> : null
			}
			{
				type === 'password' &&
					<Button
						shape='circle'
						className={styles.watchButton}
						icon={viewPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
						size='small'
						type='ghost'
						onClick={() => setViewPassword(!viewPassword)}
					/>
			}
		</div>
	)
}

export default Input
