import { Select, Spin } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './places-autocomplete.module.css'
import debounce from 'lodash/debounce'
// We use Less styles to override Ant stylings.
require('./PlacesAutocomplete.less')
import { Maps } from '../../services/maps'

const { Option } = Select

const PlacesAutocomplete = ({
	defaultValue,
	placeholder,
	value,
	onLocationSelected,
	onClear,
	className,
	country,
	style,
	initialOptions = [],
	disabled,
	loading
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [options, setOptions] = useState([])
	const fetchRef = useRef(0)

	useEffect(() => {
		if (initialOptions && initialOptions.length > 0) {
			setOptions(initialOptions)
		}
	}, [initialOptions])

	useEffect(() => {
		if (options.length === 0) {
			if (initialOptions && initialOptions.length > 0) {
				setOptions(initialOptions)
			}
		}
	}, [options])

	const debounceAutocomplete = useMemo(() => {
		const loadOptions = value => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setOptions([])
			setIsLoading(true)
			Maps.autocomplete(value, country)
				.then(predictions => {
					if (fetchId !== fetchRef.current) {
						return
					}
					const newOptions = predictions.map(prediction => {
						return {
							label: prediction.description,
							value: prediction.place_id
						}
					})
					setOptions(newOptions)
				})
				.finally(() => setIsLoading(false))
		}
		return debounce(loadOptions, 800)
	})

	const getPlaceDetails = async (option, optionData) => {
		try {
			if (optionData.location) {
				const location = optionData.location
				if (onLocationSelected) {
					onLocationSelected({
						id: location.id,
						address: location.address,
						lat: location.latitude,
						lng: location.longitude
					})
				}
			} else {
				const placeId = option.value
				const placeDetails = await Maps.getPlaceDetails(placeId)
				if (placeDetails && onLocationSelected) {
					const location = placeDetails.geometry.location
					const district = placeDetails.address_components.find(addressComponent => addressComponent.types.includes('administrative_area_level_2'))
					const division = placeDetails.address_components.find(addressComponent => addressComponent.types.includes('administrative_area_level_1'))
					onLocationSelected({
						placeId,
						address: option.label,
						lat: location.lat(),
						lng: location.lng(),
						district: district ? district.long_name : null,
						division: division ? division.long_name : null
					})
				}
			}
		} catch (e) {
			/** No-op, invalid place ID */
		}
	}

	return (
		<div className={styles.placesAutocomplete}>
			<Select
				className={className ? className : 'places-autocomplete'}
				disabled={disabled}
				style={style}
				showSearch={true}
				labelInValue
				filterOption={false}
				loading={loading}
				options={options}
				placeholder={placeholder}
				onSearch={debounceAutocomplete}
				onClear={onClear}
				notFoundContent={isLoading ? <Spin size='small' /> : null}
				defaultValue={defaultValue}
				value={value}
				onChange={(option, optionData) => getPlaceDetails(option, optionData)}
			>
				{
					options.map(option => {
						return (
							<Option
								key={option.value}
								value={option.value}
							>{option.label}
							</Option>
						)
					})
				}
			</Select>
			<img src='/img/search-2.svg' className={styles.searchIcon} />
		</div>
	)
}

export default PlacesAutocomplete
